import { Injectable } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { combineLatest, timer } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import { ModalService } from 'src/app/_common/services/modal-service/modal.service';
import { TokenExpirationCountdownComponent } from 'src/app/_common/services/modal-service/modals/injectable-components/token-expiration-countdown/token-expiration-countdown.component';
import { environment } from 'src/environments/environment';
import { AuthService } from '../../auth/auth.service';
import { UserActivityService } from '../user-activity.service';
import { IDynamicContentOptionSelectDialogData } from '../modal-service/modals/option-select-modal/dynamic-option-select-modal/dynamic-option-select-modal.component';
import { ModalConstants } from '../modal-service/modal.constants';
import { skipUntil } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class TokenExpireNotifierService {
    public readonly warningSeconds = environment?.tokenExpireWarnSeconds || 120;
    private matDialogRef: MatDialogRef<any>;

    constructor(private modalService: ModalService,
        private userActivityService: UserActivityService,
        private authService: AuthService) {
        combineLatest([
            this.authService.isAuthenticated$.pipe(distinctUntilChanged()),
            this.userActivityService.isUserActive$
        ]).pipe(
            // Skip the first ten seconds of activity so all variables can load.
            // This prevents the service from saying the user is not active while the auth guard is checking canActivate on app load.
            skipUntil(timer(10000)),
            filter(([isAuthenticatedValue, isUserActiveValue]) => !this.matDialogRef
            && isAuthenticatedValue === true
            && isUserActiveValue === false),
            map(_ => Date.now() + this.warningSeconds * 1000)
        ).subscribe(this.displayModal.bind(this));
    }

    private displayModal(expiresAt: number) {
        const dialogData: IDynamicContentOptionSelectDialogData<any> = {
            bodyContainer: {
                iconPath: ModalConstants.warning_icon,
                title: { text: 'Your session is about to expire.',  ngStyle: {
                    'text-align': 'center'}
                }
            },
            buttonContainer: {
                buttons: [
                    this.modalService.createDefaultCancelButton('Log Out'),
                    this.modalService.createDefaultPrimaryButton('Stay Logged In')
                ],  style: 'justify-content: none !important; margin-left: 30px; margin-top: 28px;'
            },
            componentType: TokenExpirationCountdownComponent,
            onComponentCreate: (component) => {
                const millisecondsUntilExpire = expiresAt - Date.now();
                component.countDownSeconds = millisecondsUntilExpire / 1000;

                setTimeout(() => {
                    this.matDialogRef?.close(false);
                }, millisecondsUntilExpire);
            }
        };

        this.matDialogRef = this.modalService.createDynamicOptionsModal(dialogData);
        this.matDialogRef.afterClosed().subscribe(this.handleDialogResult.bind(this));
    }

    private handleDialogResult(stayLoggedIn: boolean) {
        this.matDialogRef = undefined;
        if (stayLoggedIn) {
            // If the user somehow manages to click the "stay logged in" button without generating an event?
            this.userActivityService.refreshUserActive();
        } else {
            this.authService.logout();
        }
    }
}
