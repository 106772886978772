import { FormControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { CachedObservable } from '../../shared/cached-observable';
import { TypeSafeFormControl } from '../type-safe-form-control/type-safe-form-control';
import { ReactantEnabledProperty } from '../type-safe-form-group';

export abstract class AbstractTSFormControlFactory {
    abstract createTSFormControl<T>(
        name: string,
        formControl: FormControl,
        validators?: ValidatorFn[],
        validationErrors?: CachedObservable<ValidationErrors>,
        valueChanges?: (value: T) => void,
        enabledProperty?: ReactantEnabledProperty): TypeSafeFormControl<T>;
}

export class DefaultTSFormControlFactory extends AbstractTSFormControlFactory {
    createTSFormControl<T>(
        name: string,
        formControl: FormControl,
        validators?: ValidatorFn[],
        validationErrors?: CachedObservable<ValidationErrors>,
        valueChanges?: (value: T) => void,
        enabledProperty?: ReactantEnabledProperty): TypeSafeFormControl<T> {
        return new TypeSafeFormControl<T>(
            name,
            formControl,
            validators,
            validationErrors,
            valueChanges,
            enabledProperty
        );
    }
}
