import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MultiselectDropdownComponent } from '../../multiselect-dropdown/multiselect-dropdown.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NoResultsFoundComponent } from '../shared/components/no-results-found/no-results-found.component';
import { ToasterMessageComponent } from '../shared/components/toaster-message/toaster-message.component';
import { AngularMaterialModule } from './angular-material.module';
import { MatIconModule } from '@angular/material/icon';
import { PrintDirective } from 'src/app/manage-my-group/employee-roster/_common/directives/print.directive';


@NgModule({
  declarations: [
    MultiselectDropdownComponent,
    NoResultsFoundComponent,
    ToasterMessageComponent,
    PrintDirective,
  ],
  imports: [
    CommonModule,
    FormsModule,
    NgbModule,
    ReactiveFormsModule,
    AngularMaterialModule, MatIconModule,
  ],
  exports: [
    MultiselectDropdownComponent,
    NoResultsFoundComponent,
    ToasterMessageComponent,
    FormsModule,
    NgbModule,
    ReactiveFormsModule,
    PrintDirective,
  ]
})
export class SharedModule { }
