import { Component, ComponentFactoryResolver, OnInit, Type, ViewChild, ViewContainerRef } from '@angular/core';
import { Observable, of } from 'rxjs';
import { delay } from 'rxjs/operators';

@Component({
  selector: 'app-print-target',
  templateUrl: './print-target.component.html',
  styleUrls: ['./print-target.component.scss']
})
export class PrintTargetComponent {
  // I don't like doing this, but I could not find a better way to access this instance from PrintService
  private static _instance: PrintTargetComponent;
  public readonly targetId = 'global-print-target';
  public static get instance(): PrintTargetComponent { return PrintTargetComponent._instance; }
  @ViewChild('content', { read: ViewContainerRef }) private contentTemplate: ViewContainerRef;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver
  ) {
    PrintTargetComponent._instance = PrintTargetComponent._instance || this;
  }

  public createComponent<T extends Component>(data: ComponentCreationOptions<T>): Observable<string> {
    this.contentTemplate.clear();
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(data.componentType);
    const component = this.contentTemplate.createComponent(componentFactory);
    if (data.onComponentCreate) {
      data.onComponentCreate(component.instance);
    }

    // TODO: Arbitrarily waiting for 100 so that the component has time to update
    return of(this.targetId).pipe(delay(100));
  }

  public clearContent(): void {
    this.contentTemplate.clear();
  }
}

export interface ComponentCreationOptions<T extends Component> {
  componentType: Type<T>;
  onComponentCreate: (component: T) => void;
}
