import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { EMPTY, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SitecoreContentService } from 'src/app/_services/sitecore-content-service/sitecore-content.service';

@Injectable({
    providedIn: 'root'
})
export class SitecoreItemResolver implements Resolve<any> {
    constructor(private sitecoreContentService: SitecoreContentService) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
        if (route?.data?.sitecoreItem?.name) {
            return this.sitecoreContentService.getAngularComponents(route.data.sitecoreItem.name).pipe(
                map((children: any[]) => this.mapChildrenToOutletNames(children)),
                map(childrenMappedToOutlets => ({ outlets: childrenMappedToOutlets }))
            );
        }
        return EMPTY;
    }

    /*
    Example:
    {
      outlets: {
        top: [
          {
            componentName: 'collection',
            outlets: {
              top: [
                {
                  componentName: 'card',
                  outlets: {
                    top: [
                      {
                        componentName: 'headerWithSubtext'
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
    */
    mapChildrenToOutletNames(children: any[]) {
        return children.reduce((aggregate, item) => {
            const keyname = item['OutletName'] || 'top';
            const keyvalue = (aggregate[keyname] || []);
            const result = {
                ...item,
                outlets: !!item.Children ? this.mapChildrenToOutletNames(item.Children) : null
            };
            keyvalue.push(result);
            return {
                ...aggregate,
                [keyname]: keyvalue
            };
        }, () => {});
    }
}
