import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { UserInfoService } from '../user-info-service/userInfo.service';
import { ISitecorePageContentItem } from 'src/app/_models/sitecore/sitecore-page-content-item.interface';
import { tap } from 'rxjs/operators';
import { IDashboardCard } from 'src/app/landingpage/common/models/dashboard-card.interface';
import { AlertMessageModel } from 'src/app/_models/sitecore/sitecoreAlertMessage.model';
import { AppConstants } from 'src/app/_common/shared/app.constants';

@Injectable({
    providedIn: 'root',
})
export class SitecoreContentService {
    public noIdCardPageContent$ = new BehaviorSubject<Array<ISitecorePageContentItem>>(null);
    constructor(private http: HttpClient, private userInfoService: UserInfoService) {}

    public getNoIdCardSitecoreContent(): Observable<Array<ISitecorePageContentItem>> {
        const roles = this.userInfoService.getUserRole();
        return this.http
            .post<ISitecorePageContentItem[]>(`api/SitecoreItem/NoIdCard`, roles)
            .pipe(tap((data) => this.noIdCardPageContent$.next(data)));
    }

    public getAngularComponents(contentItemName: string): Observable<SitecoreAngularComponentResponse[]> {
        return this.getChildren<SitecoreAngularComponentResponse>(contentItemName);
    }

    public getChildren<T>(contentItemName: string): Observable<T[]> {
        const azbeaconUser = this.getLocalStorageInfo(AppConstants.AZBEACON_COOKIE_KEY);
        const roles = azbeaconUser !== 'true' ? this.userInfoService.getUserRole() : ['Employer_AZBeacon'];
        return this.http.get<T[]>(`api/SitecoreItem/${contentItemName}/GetChildren`, {
            params: { roles } // TODO: get roles on the API
        });
    }

    // TODO: Maybe Get rid of this ? Is not even called for dashboard cards...
    public getDashboardCards<T>(): Observable<IDashboardCard[]> {
        const roles = this.userInfoService.getUserRole();
        return this.http.post<IDashboardCard[]>(`api/SitecoreItem/DashboardCards`, roles);
    }

    public getSiteCoreToggle(toggleName: string): Observable<boolean> {
      return this.http.get<boolean>(`api/SitecoreItem/${toggleName}`);
    }

    getLocalStorageInfo(infoKey: string): any {
        const storage = localStorage.getItem(infoKey);
        if (storage) {
          return JSON.parse(storage);
        }
      }

    public getSiteCoreToggleMessage(toggleName: string): Observable<AlertMessageModel> {
      return this.http.get<AlertMessageModel>(`api/SitecoreItem/AlertMessage/${toggleName}`);
    }
}

export interface SitecoreAngularComponentResponse {
    ItemName: string;
    ComponentName: string;
    OutletName: string;
    Children: SitecoreAngularComponentResponse[];
}
