import { AfterViewInit, Directive, HostListener, Input, OnInit } from '@angular/core';
import { AbstractControl, NgControl } from '@angular/forms';
import { BaseValidation } from './base-validation';

@Directive({
    selector: '[appPhoneDirective]',
})
export class PhoneDirective extends BaseValidation implements OnInit, AfterViewInit {
    constructor() {
        super();
    }

    @Input() appInput: AbstractControl;

    ngOnInit() {
    }

    ngAfterViewInit() {
        super.setInputValue(null, this.formatPhoneNumber(this.appInput.value), this.appInput, 0);
    }

    @HostListener('keydown', ['$event'])
    public onKeyDown(event: any): void {
        super.onKeyDown(event);
    }

    public enforceValidation(event: any): void {
        this.enforceFormat(event);
    }

    @HostListener('keyup', ['$event'])
    public onKeyUp(event: any): void {
        this.formatPhone(event);
    }

    @HostListener('focus', ['$event'])
    public onFocus(event: any): void {
        this.appInput.markAsUntouched({ onlySelf: true });
    }

    private enforceFormat = (event: any) => {
        if (!this.isNumericInput(event)
            && event.keyCode !== 189 // Dash
            && event.keyCode !== 173 // Dash in firefox
            && event.keyCode !== 32 // Space
            && event.keyCode !== 57 // (
            && event.keyCode !== 58 // )
            && !this.isModifierKey(event)) {
            event.preventDefault();
            return;
        }
    }

    public formatPhone = (event: any) => {
        const input: any = event.target.value;
        if (input === undefined || input === null || input === '') {
            return;
        }

        super.setInputValue(event, this.formatPhoneNumber(input), this.appInput, 0);
    }

    public formatPhoneNumber(input: string): string {
        if (input === undefined || input === null || input === '') {
            return;
        }
        const phoneNumber = input.replace(/-|\(|\)|\W|\D/g, '');
        const length = phoneNumber.length;
        let formattedPhoneNumber = (length > 3) ? '(' + phoneNumber.substr(0, 3) + ') '
            + phoneNumber.substr(3, 3) : phoneNumber.substr(0, 3);
        formattedPhoneNumber += (length > 6) ? '-' + phoneNumber.substr(6, 4) : '';

        return formattedPhoneNumber;
    }
}
