import { Component } from '@angular/core';
import { SitecoreComponentModel } from 'src/app/_models/sitecore/sitecore-component-model';
import { CardModel } from './card.component.model';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent extends SitecoreComponentModel {
  model: CardModel;
  public setModel(model: any) {
    this.model = model;
  }
}
