import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { IEmployeeForm } from '../models/employee-form';
import { IContactInfo, IDependentInfo, IEmployeeInfo, IBenefitSelection } from '../models';
import { HttpHeaders } from '@angular/common/http';
import { SpinnerService } from 'src/app/_common/services/spinner.service';
import { finalize, map } from 'rxjs/operators';
import { throwError as observableThrowError } from 'rxjs';
import { EmployeeFormApiService } from './employee-form-api.service';
import { IOUMGeneralResponse } from 'src/app/oum/_common/models/oum-general-response.interface';
import { DebuggingService } from 'src/app/layout/debugging/debugging.service';
import { Router } from '@angular/router';
import { IEmployeeDataInfo } from '../models/submit-to-api/employee-data-info';
import { EmployeeInfoContentService } from '../../employee-enrollment/application-review/employee-info-content.service';
import { SavedApplicationsService } from 'src/app/manage-my-group/saved-applications/_common/services/saved-applications.service';
import { AppConstants } from 'src/app/_common/shared/app.constants';

@Injectable({
    providedIn: 'root'
})
export class EmployeeFormService {
    private _employeeFormValues: IEmployeeForm = {};
    employeeFormValues$ = new BehaviorSubject<IEmployeeForm>(this._employeeFormValues);
    employeeInfoValues$ = new BehaviorSubject<IEmployeeInfo>(this._employeeFormValues.employeeInfo);
    private generalResponse: IOUMGeneralResponse;
    protected headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    private idSavedEmployee: number;
    isFormSectionSaved$ = new BehaviorSubject<boolean>(false);
    public newDependentForm = false;
    employeeFormValuesCopy$ = new BehaviorSubject<IEmployeeForm>(this._employeeFormValues);
    constructor(
        public spinnerService: SpinnerService,
        private employeeFormApiService: EmployeeFormApiService,
        private debuggingService: DebuggingService,
        private router: Router,
        private employeeInfoContent: EmployeeInfoContentService,
        private savedApplicationsService: SavedApplicationsService
    ) { }

    saveContactInfo(contactInfo: IContactInfo): void {
        this._employeeFormValues = {
            ...this.employeeFormValues$.getValue(),
            contactInfo: contactInfo
        };
        this.employeeFormValues$.next(this._employeeFormValues);
        this.updateFormSection(true);
    }

    saveDependentsInfo(dependentsInfo: IDependentInfo[]): void {
        this._employeeFormValues = {
            ...this.employeeFormValues$.getValue(),
            dependentsInfo: dependentsInfo
        };
        this.employeeFormValues$.next(this._employeeFormValues);
    }

    saveEmployeeInfo(employeeInfo: IEmployeeInfo): void {
        this._employeeFormValues = {
            ...this.employeeFormValues$.getValue(),
            employeeInfo: employeeInfo
        };
        this.employeeInfoValues$.next(employeeInfo);
        this.employeeFormValues$.next(this._employeeFormValues);
        this.updateFormSection(true);
    }

    saveBenefitSelection(benefitSelection: IBenefitSelection): void {
        this._employeeFormValues = {
            ...this.employeeFormValues$.getValue(),
            benefitSelection: benefitSelection
        };
        this._employeeFormValues.benefitSelection = benefitSelection;
        this.updateFormSection(true);
        this.employeeFormValues$.next(this._employeeFormValues);
    }

    public handleError(error: any): Observable<never> {
        const errMsg = (error.message) ? error.message :
            error.status ? `${error.status} - ${error.statusText}` : 'Server error';
        return observableThrowError(errMsg);
    }

    postEmployeeForm(values: IEmployeeForm): Observable<string> {
        this.spinnerService.showProgressSpinner();
        return this.employeeFormApiService.submitEmployeeInfo(this.createEmployeeDataInfo(values))
        .pipe(
            map((response: string) => {
                return response;
            }),
            finalize(() => {
                this.spinnerService.hideProgressSpinner();
            })
        );
    }

    submit(): void { // Final Review New Standard App Submission
        if (this.debuggingService.standardApplication.submitEmployee.simulateApiCallFail) {
            this.router.navigate(['/addEmployee/standardApplication/review/submit-error']);
        } else {
            this.postEmployeeForm(this.employeeFormValues$.getValue()).subscribe(result => {
                this.generalResponse = JSON.parse(result);
                if (this.generalResponse.message === 'success') {
                    this.softDeleteSavedApplication();
                    this.newApplication();
                    this.router.navigate(['/addEmployee/standardApplication/review/submit-success']);
                } else {
                    this.newApplication();
                    this.router.navigate(['/addEmployee/standardApplication/review/submit-error']);
                }
            });
        }
    }

    saveEmployeeApplication(): void  {
        this.updateFormSection(false);
        if (!this.debuggingService.standardApplication.saveEmployeeApp.simulateApiCallFail) {
            this.idSavedEmployee = this.getIdEmployeeLocalStorage();
            if (!!this.idSavedEmployee) {
                this.updateSavedEmployeeApplication();
            } else {
                this.saveNewEmployeeApplication();
            }
        } else {
            this.router.navigate(['/addEmployee/standardApplication/review/submit-error']);
        }
    }

    saveNewEmployeeApplication(): void  {
        this.savedApplicationsService.saveEmployeeApplication(
            this.employeeFormValues$.getValue())
            .subscribe(result => {
                this.generalResponse = JSON.parse(result);
                this.generalResponse.message === 'success'
                ? this.router.navigate(['/savedApplications'])
                : this.router.navigate(['/addEmployee/standardApplication/review/submit-error']);
        });
    }

    updateSavedEmployeeApplication(): void  {
        this.savedApplicationsService.updateSavedEmployeeApplication(
            this.employeeFormValues$.getValue(),
            this.idSavedEmployee)
            .subscribe(result => {
                this.generalResponse = JSON.parse(result);
                this.generalResponse.message === 'success'
                ? this.router.navigate(['/savedApplications'])
                : this.router.navigate(['/addEmployee/standardApplication/review/submit-error']);
        });
    }

    public createEmployeeDataInfo(employeeData: IEmployeeForm): IEmployeeDataInfo {
        return this.employeeInfoContent.parseToEmployeeDataInfo(employeeData);
    }

    public softDeleteSavedApplication(): any {
        this.idSavedEmployee = this.getIdEmployeeLocalStorage();
        if (!!this.idSavedEmployee) {
            this.savedApplicationsService.deleteSelectedEmployee(this.idSavedEmployee).
            subscribe(success => success);
        }
    }

    newApplication(): void {
        this._employeeFormValues = {};
        this.employeeFormValues$.next(this._employeeFormValues);
        this.employeeInfoValues$.next(this._employeeFormValues?.employeeInfo);
        localStorage.removeItem(AppConstants.SAVED_APP_CURRENT_EMPLOYEE);
    }

    async resumeSavedEmployee(employeeForm: IEmployeeForm): Promise<boolean> {
        let savedSuccess = false;
        if (!!employeeForm) {
            this.employeeInfoValues$.next(employeeForm?.employeeInfo);
            this.employeeFormValues$.next(employeeForm);
            savedSuccess = true;
        }
        return savedSuccess;
    }

    getIdEmployeeLocalStorage() {
        return JSON.parse(localStorage.getItem(AppConstants.SAVED_APP_CURRENT_EMPLOYEE))?.id;
    }

    updateFormSection(isSaved: boolean) {
        this.isFormSectionSaved$.next(isSaved);
    }

    copyEmployee(): void {
        const employeeCopy: IEmployeeForm = {...this.employeeFormValues$.getValue()};
        this.employeeFormValuesCopy$.next(employeeCopy);
    }
}
