import { Component } from '@angular/core';
import { TreeViewNode } from 'src/app/_common/custom-controls/tree-select/tree-view.model';
import { TreeViewModel } from 'src/app/_common/custom-controls/tree-multiselect/tree-view-model';

@Component({
  selector: 'app-tree-select-modal',
  templateUrl: './tree-select-modal.component.html',
  styleUrls: ['./tree-select-modal.component.scss']
})
export class TreeSelectModalComponent {
  public id: string;
  public disabled: boolean;
  public multiselect: boolean;
  public instructions: string;
  public treeViewModel: TreeViewModel;

  // TODO: Refactor single select tree view and remove these
  public selectedItem: TreeViewNode | TreeViewNode[];
  public treeModel: TreeViewNode[] = [];

  selectedItemChange(item: TreeViewNode) {
    this.selectedItem = item;
  }

  selectedItemsChange(items: TreeViewNode[]) {
    this.selectedItem = items;
  }
}
