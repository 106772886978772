import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IToastMessage } from 'src/app/_models/toast-message.interface';

@Injectable({
  providedIn: 'root'
})
export class ToasterService {
  private readonly _toastMessage$: BehaviorSubject<IToastMessage> = new BehaviorSubject<IToastMessage>(null);
  public readonly toastMessage$ = this._toastMessage$.asObservable();

  public pushToastMessage(message: IToastMessage): void {
    this._toastMessage$.next(message);
  }

  hideToasterMessage() {
    this._toastMessage$.next(null);
  }
}
