import { AbstractControlOptions, AsyncValidatorFn, FormControl, ValidatorFn } from '@angular/forms';


export abstract class AbstractFormControlFactory {
    abstract buildFormControl(
        formState?: any,
        validatorOrOpts?: AbstractControlOptions | ValidatorFn | ValidatorFn[],
        asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[]
    ): FormControl;
}

export class DefaultFormControlFactory extends AbstractFormControlFactory {
    buildFormControl(
        formState?: any,
        validatorOrOpts?: AbstractControlOptions | ValidatorFn | ValidatorFn[],
        asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[]
    ): FormControl {
        return new FormControl(formState, validatorOrOpts, asyncValidator);
    }
}
