import { Injectable, Renderer2, RendererFactory2, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class BrowserService {
  private renderer: Renderer2;

  constructor(rendererFactory: RendererFactory2, @Inject(DOCUMENT) private document: Document) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  public loadScript(filePath: string, appendToTopOfBody: boolean = false): any {
    const script: any = this.renderer.createElement('script');
    script.type = 'text/javascript';
    script.src = filePath;
    if (appendToTopOfBody) {
      this.renderer.insertBefore(this.document.body, script, this.document.body.firstChild);
    } else {
      this.renderer.appendChild(this.document.body, script);
    }
    return script;
  }

  public loadScripts(filePaths: Array<string>, appendToTopOfBody: boolean = false): void {
    filePaths.forEach((filePath: string) => {
        this.loadScript(filePath, appendToTopOfBody);
    });
  }
}
