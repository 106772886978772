import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { TreeSelectModalComponent } from 'src/app/_common/services/modal-service/modals/injectable-components/tree-select-modal-component/tree-select-modal.component';
import { createButtonOptionStructures } from '../../services/modal-service/modal.helpers';
import { ModalService } from '../../services/modal-service/modal.service';
import { FlatTreeNode, MultiSelectTreeViewModel, SingleSelectTreeViewModel, TreeViewModel } from '../tree-multiselect/tree-view-model';
import { ISingleDropdownItem } from '../single-dropdown-select/single-dropdown-select.component';
import { TreeViewNode } from '../tree-select/tree-view.model';

@Component({
  selector: 'app-tree-modal-dropdown',
  templateUrl: './tree-modal-dropdown.component.html',
  styleUrls: ['./tree-modal-dropdown.component.scss']
})
export class TreeModalDropdownComponent {
  @Input() multiselect = false;
  @Input() id: string;
  @Input() disabled: boolean;
  @Input() defaultVerbiage: string;
  @Input() modalInstructions: string;
  @Input() modalHeaderText: string;
  @Input() selectedItem: TreeViewNode | TreeViewNode[];
  @Input() treeModel: TreeViewNode[] = [];
  @Input() oktaSubaccountTreeViewNodeArray: TreeViewNode[];
  rememberSelectedItems: FlatTreeNode[];
  dialogRef: MatDialogRef<any>;

  @Output()
  public selectedItemChange: EventEmitter<ISingleDropdownItem> = new EventEmitter();

  @Output()
  public selectedItemsChange: EventEmitter<TreeViewNode[]> = new EventEmitter();

  @Output()
  public blurEvent: EventEmitter<void> = new EventEmitter();

  private treeViewModel: TreeViewModel;

  constructor(
    public _modalService: ModalService
  ) { }

  onFocus() {
    if (!this.disabled) {
      this.openModal();
    }
  }

  openModal() {
    this.treeViewModel = this.treeViewModel
      || (this.multiselect
        ? new MultiSelectTreeViewModel(this.treeModel)
        : new SingleSelectTreeViewModel(this.treeModel));

    let modalComponent: TreeSelectModalComponent;
    this.dialogRef = this._modalService.createDynamicOptionsModal({
      showCloseButton: true,
      componentType: TreeSelectModalComponent,
      onComponentCreate: (component: TreeSelectModalComponent) => {
        component.id = `${this.id}_Modal`;
        component.instructions = this.modalInstructions;
        component.multiselect = this.multiselect;
        component.treeModel = this.treeModel;
        component.selectedItem = this.oktaSubaccountTreeViewNodeArray ?? this.selectedItem;
        this.rememberSelectedItems = this.treeViewModel.selectedItems || [];
        component.treeViewModel = this.treeViewModel;

        modalComponent = component;
      },
      bodyContainer: {
        title: {
          text: this.modalHeaderText
        }
      },
      buttonContainer: {
        buttons: createButtonOptionStructures()
          .addCancelButton('Cancel',  matDialogRef => {
            this.treeViewModel.copySelectionItemsFromTreeViewModel(this.rememberSelectedItems), matDialogRef.close(false); } )
          .addPrimaryButton('Select',
            matDialogRef => matDialogRef.close(this.multiselect ? modalComponent.treeViewModel.selectedItems : modalComponent.selectedItem))
          .build()
      }
    },
    {
      width: '950px',
      panelClass: ['tree-select-modal-container']
    });

    const subscription = this.dialogRef.afterClosed().subscribe(value => {
      if (!!value) {
        this.selectedItem = value;
        if (this.multiselect) {
          this.selectedItemsChange.emit(value);
        } else {
          this.selectedItemChange.emit(value);
        }
      } else {
        this.treeViewModel.copySelectionItemsFromTreeViewModel(this.rememberSelectedItems);
      }

      this.blurEvent?.emit();
      subscription.unsubscribe();
      this.dialogRef = null;
    });
  }
}
