import { AfterViewInit, Component, ComponentFactory, ComponentFactoryResolver, Input, ViewChild, ViewContainerRef, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { arrayify } from 'src/app/_common/type-safe-reactive-forms/type-safe-form-helpers.functions';
import { SitecoreComponentModel } from 'src/app/_models/sitecore/sitecore-component-model';
import { SitecoreAngularComponentResponse } from 'src/app/_services/sitecore-content-service/sitecore-content.service';
import { CardWithButtonComponent } from '../card-with-button/card-with-button.component';
import { CardComponent } from '../card/card.component';
import { CollectionComponent } from '../collection/collection.component';
import { HeaderWithSubTextComponent } from '../header-with-sub-text/header-with-sub-text.component';
import { IdCardSearchFormComponent } from '../id-card-search-form/id-card-search-form.component';
import { ImageWithCaptionComponent } from '../image-with-caption/image-with-caption.component';
import { RichTextComponent } from '../rich-text/rich-text.component';
import { RosterSearchFormComponent } from '../roster-search-form/roster-search-form.component';

@Component({
  selector: 'app-sitecore-outlet',
  templateUrl: './sitecore-outlet.component.html',
  styleUrls: ['./sitecore-outlet.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SitecoreOutletComponent implements AfterViewInit {
  @Input() public path: string;
  @Input() public outletName = 'top';
  @Input() public index: number = null;
  @ViewChild('container', { read: ViewContainerRef }) private container: ViewContainerRef;

  constructor(
    private activatedRoute: ActivatedRoute,
    private componentFactoryResolver: ComponentFactoryResolver
  ) { }

  clearContent(): void {
    this.container.clear();
  }

  injectComponent(componentInfo: SitecoreAngularComponentResponse, path: string): void {
    const componentType = componentMap[componentInfo.ComponentName];
    if (componentType != null) {
      const componentFactory: ComponentFactory<SitecoreComponentModel>
        = this.componentFactoryResolver.resolveComponentFactory(componentType);
      const componentRef = this.container.createComponent(componentFactory);
      componentRef.instance.path = path;
      componentRef.instance.setModel(componentInfo);
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      let level = this.activatedRoute.snapshot.data.sitecoreItem;

      // null >= 0 is true. !!0 is false. undefined === null is false. This is how I'm checking for numbers including 0. Deal with it.
      const indexPopulated = this.index > 0 || this.index === 0;
      const fullPath: string =
        (!!this.path ? this.path + '.' : '')
        + 'outlets.' + this.outletName
        + (indexPopulated ? '.' + this.index : '');

      // Only checking for undefined as null is expected, however, undefined indicates a bug may be occuring
      if (this.index === undefined) {
        console.warn(`Index is undefined for ${fullPath}, this may be due to an error with client component.`);
      }

      const segments = fullPath?.split('.');
      segments?.forEach(segment => level = level[segment]);

      arrayify(level)?.forEach((item: SitecoreAngularComponentResponse, i: number) =>
        // If index is populated, only handle the item at the end
        this.injectComponent(item, fullPath + (indexPopulated ? '' : '.' + i))
      );
    }, 0);
  }
}

export const componentMap = {
  richText: RichTextComponent,
  headerWithSubText: HeaderWithSubTextComponent,
  collection: CollectionComponent,
  cardWithButton: CardWithButtonComponent,
  card: CardComponent,
  imageWithCaption: ImageWithCaptionComponent,
  // FORMS
  rosterSearch: RosterSearchFormComponent,
  orderIdCardSearch: IdCardSearchFormComponent
};
