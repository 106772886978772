import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-token-expiration-countdown',
    templateUrl: './token-expiration-countdown.component.html',
})
export class TokenExpirationCountdownComponent implements OnInit {
    private _countDownSeconds: number;

    public get countDownSeconds(): number {
        return this._countDownSeconds;
    }

    public set countDownSeconds(value) {
        this._countDownSeconds = Math.floor(value);
    }

    ngOnInit(): void {
        const interval = setInterval(() => {
            if (this.countDownSeconds === 0) {
                clearInterval(interval);
            } else {
                this.countDownSeconds--;
            }
        }, 1000);
    }

    getCountdownText() {
        const minuteSection = Math.floor(this.countDownSeconds / 60) + ':';
        const secondsModulo = ('00' + this.countDownSeconds % 60).slice(-2);

        return minuteSection + secondsModulo;
    }
}
