import { Component, Input, Output, EventEmitter, ViewEncapsulation, ViewChild, ElementRef,
  ViewChildren, QueryList, AfterViewInit, OnDestroy, HostListener } from '@angular/core';
import { BsDropdownDirective } from 'ngx-bootstrap/dropdown';
import { Subscription } from 'rxjs';

export interface ISingleDropdownItem {
  value: string;
  text: string;
}

@Component({
  selector: 'app-single-dropdown-select',
  templateUrl: './single-dropdown-select.component.html',
  styleUrls: [],
  encapsulation: ViewEncapsulation.None
})
export class SingleDropdownSelectComponent implements AfterViewInit, OnDestroy {
  @Input()
  public dropdownItems: Array<ISingleDropdownItem>;
  @Input()
  public id: string;
  @Input()
  public disabled = false;
  @Input()
  public position: string;
  @Input()
  public defaultVerbiage: string;
  @Input()
  selectedItem: ISingleDropdownItem;

  @Output()
  public selectedItemChange: EventEmitter<ISingleDropdownItem> = new EventEmitter();
  @Output()
  public blurEvent: EventEmitter<void> = new EventEmitter();

  @ViewChild('dropdown')
  dropdown: BsDropdownDirective;
  @ViewChild('dropdownElement')
  dropdownElement: ElementRef;
  @ViewChildren('option')
  optionElements: QueryList<ElementRef<HTMLElement>>;

  subscriptions: Subscription[] = [];

  ngAfterViewInit(): void {
    this.subscriptions.push(this.optionElements.changes.subscribe(this.focusSelectedItem.bind(this)));
  }

  focusSelectedItem() {
    if (!!this.optionElements?.length) {
      const selectedItem = this.optionElements.find(el => el.nativeElement?.parentElement?.classList?.contains('selected'));
      selectedItem?.nativeElement?.focus();
    }
  }

  public onChange(value: ISingleDropdownItem): void {
    this.selectedItem = value;
    this.selectedItemChange.emit(value);
    this.dropdown.hide();
    // Hiding the dropdown removes the option elements and returns focus to the body tag.
    // Shift tabbing at that point would refocus and open the dropdown. Focus the dropdown now instead.
    this.dropdownElement.nativeElement.focus();
  }

  hoverItem(event: Event): void {
    const target = event.target as HTMLElement;
    target.focus();
  }

  public onBlur(event): void {
    // Don't hide the dropdown if we're losing focus to one of our options
    if (!this.dropdownElement.nativeElement.contains(event.relatedTarget)) {
      this.dropdown.hide();
      if (this.blurEvent) {
        this.blurEvent.emit();
      }
    }
  }

  public onFocus(event): void {
    // Don't focus an option if we are receiving focus from inside the dropdown
    if (!this.dropdownElement.nativeElement.contains(event.relatedTarget)) {
      this.openDropdown();
    }
  }

  public openDropdown(): void {
    this.dropdown.show();
    this.focusSelectedItem();
  }

  @HostListener('keydown.arrowdown', ['$event'])
  @HostListener('keydown.arrowup', ['$event'])
  preventScroll(event: KeyboardEvent): void {
    event.preventDefault();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}
