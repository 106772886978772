import { Component, OnInit } from '@angular/core';
import { BrowserService } from '../_services/browser-service/browser.service';

@Component({
  selector: 'app-tealeaf',
  templateUrl: './tealeaf.component.html',
  styleUrls: ['./tealeaf.component.scss']
})
export class TealeafComponent implements OnInit {
  private tealeafLibrarySrc = 'assets/scripts/tealeaf/tealeaf.js';
  private tealeafDependentScripts = [
    'assets/scripts/tealeaf/tealeaf.gestures.js',
    'assets/scripts/tealeaf/defaultconfiguration.js'
  ];
  private pakoSrc = 'assets/scripts/tealeaf/pako.min.js';

  constructor(private browser: BrowserService) { }

  ngOnInit(): void {
    this.loadScriptIntoBrowserService(this.pakoSrc);
    const tealeafLibrary: any = this.loadScriptIntoBrowserService(this.tealeafLibrarySrc, true);
    if (tealeafLibrary) {
      tealeafLibrary.onload = this.loadTealeafDependentScripts.bind(this);
    }
  }

  public loadScriptIntoBrowserService(filepath: string, appendToTopOfBody: boolean = false): any {
    return this.browser.loadScript(filepath, appendToTopOfBody);
  }

  public loadTealeafDependentScripts(): void {
    this.browser.loadScripts(this.tealeafDependentScripts);
  }
}
