import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { IEmployeeDataInfo } from '../models/submit-to-api/employee-data-info';

@Injectable({
  providedIn: 'root'
})
export class EmployeeFormApiService {
  protected headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  public urlEmployee: string;

  constructor(private http: HttpClient) { }


  public submitEmployeeInfo(employeeData: IEmployeeDataInfo): Observable<string> {
    this.urlEmployee = `api/AddEmployee/SubmitAddEmployee`;
    return this.http.post<string>(this.urlEmployee,
      JSON.stringify(employeeData, (key, value) => {if (value !== null) {return value; }}),
      { headers: this.headers })
    .pipe(
      map((result: string) => JSON.stringify({ isSuccessful: result, statusCode: '200', message: 'success' })
      ),
      catchError((error, caught) => {
        return of(JSON.stringify({ isSuccessful: false, statusCode: error.status, message: error.error }));
      })
    );
  }

}
