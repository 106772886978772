import { FormControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { CachedObservable } from '../../shared/cached-observable';
import { ReactantEnabledProperty } from '../type-safe-form-group';
import { AbstractFormReactant } from '../type-safe-form-reactant/abstract-form-reactant';

export class TypeSafeFormControl<T> extends AbstractFormReactant {
    private readonly subscriptions: Subscription[] = [];
    public get valueChanges$(): Observable<T> { return this.formControl.valueChanges; }
    private wasEnabled: boolean;
    private matErrors: ValidationErrors;
    constructor(
        public name: string,
        public readonly formControl: FormControl,
        public validators?: ValidatorFn[],
        private validationErrors?: CachedObservable<ValidationErrors>,
        private valueChanges?: (value: T) => void,
        enabledProperty?: ReactantEnabledProperty
    ) {
        super(enabledProperty);
        if (!!this.valueChanges) {
            this.subscriptions.push(this.formControl.valueChanges.subscribe(this.valueChanges));
        }
        if (!!this.validationErrors) {
            this.subscriptions.push(validationErrors.value$.subscribe(_ => this.getErrors()));
        }
    }

    public getErrors(): ValidationErrors {
        const nowEnabled = this.enabled();
        let result = null;
        if (nowEnabled) {
            const validationErrors = this.runValidators();
            if (this.wasEnabled) {
                this.matErrors = this.getMatErrors();
            }
            result = this.mergeErrors(validationErrors, this.matErrors);
            result = !!this.validationErrors ? this.mergeErrors(result, this.validationErrors.value) : result;
        } else if (this.wasEnabled) {
            this.matErrors = this.getMatErrors();
        }

        this.formControl.setErrors(result);
        this.wasEnabled = nowEnabled;

        return result;
    }

    public patchValue(value: T | null) {
        this.formControl.patchValue(value);
    }

    public updateValue(value: T | null) {
        this.formControl.setValue(value);
        this.markAsUpdated();
    }


    get value(): T {
        return this.formControl.value;
    }

    get hasErrors(): boolean {
        return !!(this.formControl.errors && this.formControl.touched);
    }

    public getPath(): string {
        return this.Parent?.getPath() + `.${this.name}`;
    }

    public destroy(): void {
        this.subscriptions.forEach(s => s.unsubscribe());
    }

    public markAsUpdated() {
        this.formControl.markAsTouched();
        this.formControl.markAsDirty();
    }

    /**
     * @deprecated use markAsChanged() instead
    */
    public blurEvents() {
        this.formControl.markAsTouched();
        this.formControl.markAsDirty();
    }

    private runValidators(): ValidationErrors {
        try {
            const result = this.validators?.reduce<ValidationErrors>((aggregate, validator) => {
                const errors = validator(this.formControl);
                if (errors != null) {
                    return {
                        ...aggregate,
                        ...errors
                    };
                } else {
                    return aggregate;
                }
            }, null);

            return result;
        } catch (err) {
            console.error(`Error while running validators for ${this.getPath()}`);
            throw err;
        }
    }

    private getMatErrors(): ValidationErrors {
        const result = this.formControl?.errors ? Object.entries(this.formControl.errors)
            .filter(([key]) => key.startsWith('mat'))
            .map(([key, value]) => ({ [key]: value }))
            .reduce((a, b) => ({ ...a, ...b }), {}) : null;

        return result;
    }

    private mergeErrors(first: ValidationErrors, second: ValidationErrors) {
        if (!!first && Object.keys(first).length > 0) {
            return { ...second, ...first };
        } else if (!!second && Object.keys(second).length > 0) {
            return second;
        }

        return null;
    }

    public hasError(name: string): boolean {
        return this.hasErrors && !!this.formControl.errors[name];
    }
}
