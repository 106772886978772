import { Injectable } from '@angular/core';
import { IEmployee, RosterFilters, RosterStatuses } from '../../models';

@Injectable({
  providedIn: 'root',
})
export class EmployeeRosterFilterService {
  private selectedFilters: RosterFilters = {
    searchFilterValue: '',
    accountId: [],
    subAccountNumbers: [],
    status: [],
    eligibilityDate: null
  };

  constructor() { }

  public get getSelectedRosterFilter(): RosterFilters {
    return this.selectedFilters;
  }

  public updateSelectedAccountIds(selectedAccountId: string[]): void {
    this.selectedFilters.accountId = selectedAccountId;
  }

  public updateSelectedSubAccountId(subAccts: string[]): void {
    this.selectedFilters.subAccountNumbers = subAccts;
  }

  public updateStatus(selectedStatus: string[]): void {
    this.selectedFilters.status = selectedStatus;
  }

  public updateFilterSearchValue(value: string): void {
    this.selectedFilters.searchFilterValue = value;
  }

  public updateSelectedEligibilityDate(eligibilityDate: Date): void {
    this.selectedFilters.eligibilityDate = eligibilityDate;
  }

  public clearAllSelections(): void {
    this.selectedFilters = {
      searchFilterValue: '',
      accountId: [],
      subAccountNumbers: [],
      status: [],
      eligibilityDate: null
    };
  }

  public filterByOptionsSelected(roster: IEmployee[]): IEmployee[] {
    let result = roster;
    const eligibilityDate = this.selectedFilters?.eligibilityDate;
    const status = this.getStatusList();
    if (eligibilityDate) {
      result = result.filter(
        emp => {
          return (emp.effectiveDate !== null && new Date(emp.effectiveDate) <= eligibilityDate)
            && (emp.cancelDate === null || new Date(emp.cancelDate) > eligibilityDate);
        }
      );
    }
    if (status && status.length > 0) {
      const statusList = status.map(s => s.toLowerCase());
      result = result.filter(
        emp => {
          return (emp.employeeStatus && statusList.includes(emp.employeeStatus.toLowerCase()));
        }
      );

    }

    return result;
  }

  private getStatusList(): string [] {
    const statusList: string[] = [];
    this.selectedFilters?.status.forEach((status) => {
      switch (status) {
        case RosterStatuses.CURRENT:
          statusList.push(RosterStatuses.ACTIVE);
          break;
        case RosterStatuses.PROCESSING:
          statusList.push(RosterStatuses.PENDING);
          statusList.push(RosterStatuses.REPAIR);
          statusList.push(RosterStatuses.NOTSPECIFIED);
          statusList.push(RosterStatuses.REVIEW);
          break;
        default:
          statusList.push(status);
          break;
      }
    });
    return statusList;
  }
}
