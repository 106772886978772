import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { EmployeeIDGridPipeline } from 'src/app/_common/pipeline/employee-id-card-grid.pipeline';
import { TypeSafeFormGroup, TypeSafeFormGroupBuilder } from 'src/app/_common/type-safe-reactive-forms/type-safe-form-group';
import { TypeSafeFormGroupConfig } from 'src/app/_common/type-safe-reactive-forms/type-safe-form-group/type-safe-form-group-config';
import { SitecoreComponentModel } from 'src/app/_models/sitecore/sitecore-component-model';
import { IDCardSearchFormStructure } from './id-card-search-form.structure';

@Component({
  selector: 'app-id-card-search-form',
  templateUrl: './id-card-search-form.component.html',
  styleUrls: ['./id-card-search-form.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class IdCardSearchFormComponent extends SitecoreComponentModel implements OnInit {

  private safeForm: TypeSafeFormGroup<IDCardSearchFormStructure>;
  public form: FormGroup;

  constructor(
    private safeFormBuilder: TypeSafeFormGroupBuilder<IDCardSearchFormStructure>,
    private router: Router,
    public employeeIDGridPipeline: EmployeeIDGridPipeline
  ) {
    super();
  }

  ngOnInit(): void {
    this.createForm();
  }

  createForm(): void {
    const structure: TypeSafeFormGroupConfig<IDCardSearchFormStructure> = {
      searchText: [null, {
        valueChanges: searchText => this.employeeIDGridPipeline.searchPipe.searchText = searchText
      }]
    };

    this.safeForm = this.safeFormBuilder.group(structure);
    this.form = this.safeForm.form;
  }

  searchEmployees(): void {
    const formvalues = this.safeForm.form.getRawValue() as IDCardSearchFormStructure;
    this.employeeIDGridPipeline.searchPipe.searchText = formvalues.searchText;
    this.router.navigate(['/IDCard']);
  }

  public setModel(model: any) {
    // intentionally left blank
  }

}
