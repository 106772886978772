import { Subject } from 'rxjs';
import { FilterMap, FilterConfig } from './filter-config';
import { IPipe } from '../pipe.interface';
import { Functions } from '../pipe.functions';


export class FilterPipe<T, K> implements IPipe<Array<T>> {
    private _filterMaps: FilterMap[];
    private _filterValues: K;

    constructor(
        private dirtyNotifier: Subject<boolean>,
        private matchers: FilterConfig<T, K>
    ) { }

    get filterValues() {
        const result = {
            patch: (value: Partial<K>) => this.setFilterValues({
                ...this._filterValues,
                ...value
            }),
            get: () => this._filterValues,
            set: (value: K) => this.setFilterValues(value),
            clear: () => result.set(null)
        };
        return result;
    }

    public process(input: T[]): T[] {
        if (!this._filterValues) {
            return input;
        }

        const result = input.filter(item => {
            return this._filterMaps.every(({ matcher, value }) => {
                return matcher(item, value);
            });
        });
        return result;
    }

    private setFilterValues(filterValues: K) {
        if (filterValues === null) {
            this._filterMaps = null;
            this._filterValues = null;
            this.dirtyNotifier.next(true);
            return;
        }

        Object.keys(filterValues).forEach(key =>
            (filterValues[key] === undefined || filterValues[key] === null) && delete filterValues[key]
        );
        const keys = Object.keys(filterValues);
        if (keys.length === 0) {
            this._filterMaps = null;
            filterValues = null;
        } else {
            this._filterMaps = keys.map(key => {
                return {
                    matcher: this.matchers[key as keyof K].matcher,
                    // Using parameter here as this._filterValues has not been set yet
                    value: filterValues[key]
                };
            });
        }

        this._filterValues = filterValues;
        this.dirtyNotifier.next(true);
    }
}
