import { SelectionModel } from '@angular/cdk/collections';
import { FlatTreeControl } from '@angular/cdk/tree';
import { EventEmitter } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import {
  MatTreeFlatDataSource,
  MatTreeFlattener
} from '@angular/material/tree';


export interface FlatTreeNode {
  expandable: boolean;
  text: string;
  value: any;
  treeNode: TreeViewNode;
  level: number;
}

export class TreeViewNode {
  id: string;
  text: string;
  value: any;
  children?: TreeViewNode[];
  isSelected?: boolean;
}

export abstract class TreeViewModel {
    public onlyLeaves: FlatTreeNode[] = [];
    public selectedItems: FlatTreeNode[];
    public readonly selectedItemChange: EventEmitter<FlatTreeNode[]> = new EventEmitter();

    protected readonly treeControl = new FlatTreeControl<FlatTreeNode>(
      node => node.level,
      node => node.expandable
    );

    private readonly treeFlattener = new MatTreeFlattener(
      (node: TreeViewNode, level: number) => this._transformer(node, level),
      node => node.level,
      node => node.expandable,
      node => node.children
    );

    private readonly treeFlatDataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

    constructor(
      treeModel: TreeViewNode[],
      protected readonly listSelection: SelectionModel<FlatTreeNode>
    ) {
      this.treeFlatDataSource.data = treeModel;
      this.treeControl.expandAll();
    }

    private _transformer(node: TreeViewNode, level: number): FlatTreeNode {
      const result = {
        expandable: !!node.children && node.children.length > 0,
        text: node.text,
        value: node.value,
        treeNode: node,
        level: level
      };
      if (node.isSelected) {
        this.listSelection.select(result);
      }
      if (!result.expandable) {
        this.onlyLeaves.push(result);
      }
      return result;
    }

    public abstract handleChange(item: FlatTreeNode): void;
    public abstract setSelectAll(checkboxChange: MatCheckboxChange): void;

    public hasChildren(_: number, node: FlatTreeNode): boolean { return node.expandable; }

    public isSelected(node: FlatTreeNode): boolean { return this.listSelection.isSelected(node); }

    public isAllSelected(): boolean { return this.onlyLeaves.every(node => this.isSelected(node)); }

    public childrenAllSelected(node: FlatTreeNode): boolean {
      const descendants = this.treeControl.getDescendants(node);
      const result =
        descendants.length > 0 &&
        descendants.every(child => this.isSelected(child));
      return result;
    }

    public childrenPartiallySelected(node: FlatTreeNode): boolean {
      const descendants = this.treeControl.getDescendants(node);
      const selectedCount = descendants.filter(child => this.isSelected(child)).length;
      const result = 0 < selectedCount && selectedCount < descendants.length;
      return result;
    }

    public copySelectionItemsFromTreeViewModel(other: FlatTreeNode[]) {
      if (!!other) {
        this.listSelection.clear();
        this.listSelection.select(...other);
        this.selectedItems = other;
      }
    }
}

export class SingleSelectTreeViewModel extends TreeViewModel {
  constructor(treeModel: TreeViewNode[]) {
    super(treeModel, new SelectionModel<FlatTreeNode>(false));
  }

  public handleChange(item: FlatTreeNode): void {
    this.selectedItems = [item];
    this.selectedItemChange?.emit(this.selectedItems);
  }

  public setSelectAll(checkboxChange: MatCheckboxChange): void { throw new Error('Single select tree cannot select multiple items.'); }
}

export class MultiSelectTreeViewModel extends TreeViewModel {
  constructor(treeModel: TreeViewNode[]) {
    super(treeModel, new SelectionModel<FlatTreeNode>(true));
    this.emitChange();
  }

  public setSelectAll(checkboxChange: MatCheckboxChange): void {
    this.listSelection.clear();
    if (checkboxChange.checked) {
      this.listSelection.select(...this.onlyLeaves);
    }

    this.emitChange();
  }

  handleChange(item: FlatTreeNode): void {
    const descendants = this.treeControl.getDescendants(item);

    if (!descendants?.length) {
      this.listSelection.toggle(item);
    } else if (this.childrenAllSelected(item)) {
      descendants.forEach(descendant => this.listSelection.deselect(descendant));
    } else {
      descendants.forEach(descendant => this.listSelection.select(descendant));
    }

    this.emitChange();
  }

  private emitChange() {
    this.selectedItems = this.listSelection.selected.map(o => o);
    this.selectedItemChange?.emit(this.selectedItems);
  }
}
