import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {

  private _showSpinner: boolean;
  public ShowSpinner$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor() {
    this._showSpinner = false;
  }

  get showSpinner(): boolean {
    return this._showSpinner;
  }

  set showSpinner(value: boolean) {
    this._showSpinner = value;
    this.ShowSpinner$.next(value);
  }
  showProgressSpinner() {
    this.showSpinner = true;
  }

  hideProgressSpinner() {
    this.showSpinner = false;
  }
}
