import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

/**
 * Encapsulates an Observable and stores its emitted values.
 */
export class CachedObservable<T> {
    public get value(): T { return this._value; }
    public get value$(): Observable<T> { return this._obs.pipe(tap(val => this._value = val)); }
    constructor(private _value: T, private _obs: Observable<T>) { }
}
