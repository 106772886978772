import { Component, OnInit } from '@angular/core';
import { SitecoreComponentModel } from 'src/app/_models/sitecore/sitecore-component-model';
import { ImageWithCaptionModel } from './image-with-caption.model';

@Component({
  selector: 'app-image-with-caption',
  templateUrl: './image-with-caption.component.html',
  styleUrls: ['./image-with-caption.component.scss']
})
export class ImageWithCaptionComponent extends SitecoreComponentModel {
  public model: ImageWithCaptionModel;
  public setModel(newModel: any) {
    this.model = newModel;
  }

}
