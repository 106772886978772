import { Component, AfterViewInit, OnInit, Inject, ComponentFactoryResolver, Type } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { OptionSelectModalBaseComponent, IOptionSelectModalBaseDialogData } from '../option-select-modal-base.component';
@Component({
  selector: 'app-text-content-option-select-modal',
  templateUrl: '../option-select-modal-base.component.html',
  styleUrls: ['../option-select-modal-base.component.scss']
})
export class DynamicContentOptionSelectModalComponent<T extends Component>
  extends OptionSelectModalBaseComponent implements AfterViewInit, OnInit {

  constructor(
    public dialogRef: MatDialogRef<OptionSelectModalBaseComponent>,
    private componentFactoryResolver: ComponentFactoryResolver,
    @Inject(MAT_DIALOG_DATA) public data: IDynamicContentOptionSelectDialogData<T>) {
    super(dialogRef, data);
  }
  ngAfterViewInit(): void {
    /*
      Paradox: You need a viewchild to inject a component, but viewchildren aren't initialized until ngAfterViewInit.
      BUT!!! If you update anything in ngAfterViewInit you get an error.
      Popular solution?
      setTimeout -.-
    */
    setTimeout(_ => {
      const componentFactory = this.componentFactoryResolver.resolveComponentFactory(this.data.componentType);
      const component = this.contentSpan.createComponent(componentFactory);
      if (this.data.onComponentCreate) {
          this.data.onComponentCreate(component.instance);
      }
    });
  }
}
export interface IDynamicContentOptionSelectDialogData<T> extends IOptionSelectModalBaseDialogData {
    componentType?: Type<T>;
    onComponentCreate?: (T) => void;
}
