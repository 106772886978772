import { Directive, HostListener } from '@angular/core';
import { BaseValidation } from './base-validation';

@Directive({
    selector: '[appAllowDate]'
})
export class AllowDateDirective extends BaseValidation {
    constructor() {
        super();
    }

    @HostListener('keydown', ['$event'])
    public onKeyDown(event: any): void {
        this.autocompleteInput(event);
    }

    public enforceValidation(event: any): void {
    }

    public autocompleteInput(event: any): void {
        if (!this.isNumericInput(event) && event.key !== '/' && !this.isModifierKey(event)) {
            event.preventDefault();
        }
    }
}

