import { from, Observable } from 'rxjs';

/**
 * Disambiguate a parameter which may either be a promise or observable
 * @param obj
 * @returns either the original observable, or a new observable wrapping a promise
 */
export function observify<T>(obj: Promise<T> | Observable<T>): Observable<T> {
    if (obj instanceof Promise) {
        return from(obj);
    } else {
        return obj;
    }
}

/**
 * Disambiguate a parameter which may either be a single object or an array
 * @param obj
 * @returns either the original passed in parameter or an array containing it
 * ```typescript
 * arrayify( 5 ) === arrayify( [5] ) === [5] // True
 * ```
 */
export function arrayify<T>(obj: T | T[]): (T[] | null) {
    let result = null;
    if (obj !== null && obj !== undefined) {
        if (Array.isArray(obj)) {
            result = obj;
        } else {
            result = [obj];
        }
    }

    return result;
}
