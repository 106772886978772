import { Component, ElementRef, OnInit, ViewEncapsulation } from '@angular/core';
import { SitecoreComponentModel } from 'src/app/_models/sitecore/sitecore-component-model';
import { HTML } from '../../../html.functions';
import { RichTextModel } from './rich-text.model';
@Component({
  selector: 'app-rich-text',
  templateUrl: './rich-text.component.html',
  styleUrls: ['./rich-text.component.scss']
})
export class RichTextComponent extends SitecoreComponentModel implements OnInit {
  public model: RichTextModel;
  public constructor(private element: ElementRef) {
    super();
  }
  ngOnInit(): void {
    if (this.model.Style) {
      HTML.createStyleTag(this.model.Style, this.element);
    }
  }
  public setModel(newModel: any) {
    this.model = newModel;
  }
}
