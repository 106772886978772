import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { Injectable } from '@angular/core';
import { OktaAuthService } from '@okta/okta-angular';

@Injectable()
export class MiddlewareAPIInterceptor implements HttpInterceptor {

    constructor(private oktaAuth: OktaAuthService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return from(this.handleAccess(request, next));
    }

    private async handleAccess(request: HttpRequest<any>, next: HttpHandler): Promise<HttpEvent<any>> {
        const accessToken = this.oktaAuth.getAccessToken();
        request = request.clone({
            setHeaders: { Authorization: `Bearer ${accessToken}` },
        });

        return next.handle(request).toPromise();
    }
}
