import assert from 'assert';
import { merge, Observable, Subject, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { TypeSafeFormControl } from '../../type-safe-reactive-forms/type-safe-form-control';
import { TypeSafeFormGroup } from '../../type-safe-reactive-forms/type-safe-form-group';
import { TypedControlGroup } from '../../type-safe-reactive-forms/type-safe-form-group/type-safe-form-group-config';
import { arrayify } from '../../type-safe-reactive-forms/type-safe-form-helpers.functions';
/**
 * Waits for an observable to complete, and when it does merges into an array of sources. When any one of those sources emit a value the map
 * function will be called and its return value will be emitted by the final observable.
 * @param whenComplete The observable that, once complete, will be merged into the sources
 * @param getSources An array of observables, when any one of these emit, the subscriber will be notified
 * @param mapFunction A function which will be called when any of the sources emit
 */
export function mergeWhenComplete<T, tSource, tMapOutput>(
  getSources: () => (Observable<tSource>[] | Observable<tSource>),
  mapFunction: (_: tSource) => tMapOutput
): (whenComplete: Observable<T>) => Observable<tMapOutput> {
  return (whenComplete: Observable<T>) => {
      const result = new Observable<tMapOutput>(subscriber => {
          let innerSubscription: Subscription;
          const outerSubscription = whenComplete.subscribe({
              next(_) { },
              complete() {
                  innerSubscription = merge(...arrayify(getSources())).pipe(
                      map(mapFunction)
                  ).subscribe(value => subscriber.next(value));
              },
          });
          return () => {
              outerSubscription.unsubscribe();
              innerSubscription?.unsubscribe();
          };
      });
      return result;
  };
}
export type GetFieldsReturn<tControlValue> =
  (TypeSafeFormControl<tControlValue> | TypeSafeFormControl<tControlValue>[] | TypeSafeFormControl<any>[]);
export type OnMonad<tStructure> = <tControlValue>(
  getFields: (children: TypedControlGroup<tStructure>) => GetFieldsReturn<tControlValue>,
  predicate: (_: tControlValue) => boolean
) => Observable<boolean>;
/**
 * Wrap the boilerplate of calling mergeWhenComplete() into a monad. Returns a function that can be called to bind form controls to a
 * mapping function.
 * @param buildingForm The observable which is complete when the form is done building
 * @param getSafeForm A lambda which returns a safe form
 * @returns A generic function whose type parameter is the type of control we are dependent on. This function has the following properties:
 * @param getFields A lambda which takes in the children property of the safe form and returns the fields which we should subscribe to
 * @param map A lambda which takes in the value of whichever field emitted last and returns a boolean
 */
export function createOnMonad<tStructure>(
  buildingForm: Observable<any>,
  getSafeForm: () => TypeSafeFormGroup<tStructure>
): OnMonad<tStructure> {
  return <tControlValue>(
    getFields: (children: TypedControlGroup<tStructure>) => GetFieldsReturn<tControlValue>,
    predicate: (_: tControlValue) => boolean
  ) => {
    return buildingForm.pipe(
      mergeWhenComplete(
        () => arrayify(getFields(getSafeForm()?.children)).map(field => field.valueChanges$.pipe(startWith(field.value))),
        predicate
      )
    );
  };
}
/**
 * Encapsulates the process of creating an OnMonad and resolving it.
 */
export class OnMonadContext<T> {
  private buildingForm: Subject<never> = new Subject<never>();
  private result?: TypeSafeFormGroup<T>;
  private getResult(): TypeSafeFormGroup<T> { return this.result; }
  public resolve(result: TypeSafeFormGroup<T>) {
      this.result = result;
      this.buildingForm.complete();
  }
  public createOnMonad(): OnMonad<T> {
      assert(!this.buildingForm.isStopped, 'cannot create OnMonad after resolve has been called');
      return createOnMonad(this.buildingForm, this.getResult.bind(this));
  }
}
