import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IPlanInfo } from '../models/plan-info';
import { Router } from '@angular/router';
import { ISingleDropdownItem } from 'src/app/_common/custom-controls/single-dropdown-select/single-dropdown-select.component';
import { PlansService } from './plan-service/plans.service';

@Injectable({
  providedIn: 'root'
})
export class ManageMyGroupDropdownService {
  public BenefitPlanItems$: BehaviorSubject<Array<IPlanInfo>> = new BehaviorSubject<Array<IPlanInfo>>(null);
  public DentalPlanItems$: BehaviorSubject<Array<IPlanInfo>> = new BehaviorSubject<Array<IPlanInfo>>(null);
  private plans: Array<IPlanInfo>;

  private waiveCoverageItem: IPlanInfo = {
    planName: 'Waive Coverage',
    planType: 'Waive Coverage',
    planId: 'Waive Coverage',
    accountId: '',
    accountName: '',
    planDescription: 'Waive Coverage',
    productName: 'Waive Coverage',
    planStartDate: '',
    planEndDate: ''
  };

  private terminateCovergeItem: IPlanInfo = {
    planName: 'Terminate Coverage',
    planType: 'Terminate Coverage',
    planId: 'Terminate Coverage',
    accountId: '',
    accountName: '',
    planDescription: '',
    productName: '',
    planStartDate: '',
    planEndDate: ''
  };

  private readonly medicarePartA = {
    value: 'Part A',
    text: 'Part A'
  };

  private readonly medicarePartB = {
    value: 'Part B',
    text: 'Part B'
  };

  private readonly medicareBoth = {
    value: 'Both',
    text: 'Both'
  };

  constructor(
    private plansService: PlansService,
    private router: Router) {

  }

  public static createWaiveCoverageDropdownItem(): ISingleDropdownItem {
    return {
      value: 'Waive Coverage',
      text: 'Waive Coverage'
    };
  }

  public static createTerminateCoverageDropdownItem(): ISingleDropdownItem {
    return {
      value: 'Terminate Coverage',
      text: 'Terminate Coverage'
    };
  }

  public static createEnrolledPlan(planType: string): IPlanInfo {
    return {
      planId: 'Same as subscriber',
      planName: 'Same as subscriber',
      planType,
      accountId: '',
      accountName: '',
      planDescription: '',
      planEndDate: '',
      planStartDate: '',
      productName: ''
    };
  }

  public static createTerminateCoveragePlan(planType: string): IPlanInfo {
    return {
      planId: 'Terminate Coverage',
      planName: 'Terminate Coverage',
      planType,
      accountId: '',
      accountName: '',
      planDescription: '',
      planEndDate: '',
      planStartDate: '',
      productName: ''
    };
  }

  public static createSameAsSubscriberDropdownitem(): ISingleDropdownItem {
    return {
      text: 'Same as subscriber',
      value: 'Same as subscriber'
    };
  }

  get getWaiveCoverageItem(): IPlanInfo {
    return this.waiveCoverageItem;
  }

  get getTerminateCoverageItem(): IPlanInfo {
    return this.terminateCovergeItem;
  }

  public async getHealthPlans(accountNumber: string = null, effectiveDate: Date = null): Promise<boolean> {
    const newEffectiveDate = new Date(effectiveDate);
    let isSuccessful = false;
    if (accountNumber && effectiveDate) {
      this.DentalPlanItems$.next(null);
      this.BenefitPlanItems$.next(null);
      try {
        this.plans = await this.plansService.getPlans(accountNumber, newEffectiveDate).toPromise();
        if (this.plans.length > 0) {
          this.DentalPlanItems$.next(this.getFilterPlans('dental'));
          this.BenefitPlanItems$.next(this.getFilterPlans('medical'));
          isSuccessful = true;
        }
      } catch (error) {
        if (error.status && error.status !== 404) {
          this.router.navigate(['/error']);
        }
        return isSuccessful;
      }
    }
    return isSuccessful;
  }

  getFilterPlans(planType: string): Array<IPlanInfo> {
    const plansFiltered = this.plans.filter(p => p.planType.toLowerCase().includes(planType));
    return plansFiltered;
  }

  public getWhosCovered(): Array<ISingleDropdownItem> {
    return [
      {
        value: 'Employee',
        text: 'Employee'
      },
      {
        value: 'Employee/Spouse',
        text: 'Employee/Spouse'
      },
      {
        value: 'Employee/Family',
        text: 'Employee/Family'
      },
      {
        value: 'Employe/Child',
        text: 'Employee/Child'
      },
      {
        value: 'Employee/Children',
        text: 'Employee/Children'
      }
    ] as Array<ISingleDropdownItem>;
  }

  public getMedicareTypes(): Array<ISingleDropdownItem> {
    return [
      this.medicarePartA,
      this.medicarePartB,
      this.medicareBoth
    ] as Array<ISingleDropdownItem>;
  }

  public getMedicareTypeForPartEffectiveDates(partA: string, partB: string): ISingleDropdownItem {
    const isPartA = !!partA;
    const isPartB = !!partB;

    if (isPartA && isPartB) {
      return this.medicareBoth;
    } else if (isPartA) {
      return this.medicarePartA;
    } else if (isPartB) {
      return this.medicarePartB;
    }

    return null;
  }

  public getRemovalReasons(): Array<ISingleDropdownItem> {
    return [
      {
        value: 'CobraTFNP',
        text: 'Cobra Termination for Non-payment'
      },
      {
        value: 'Deceased',
        text: 'Deceased'
      },
      {
        value: 'TOE',
        text: 'Termination of Employment'
      },
      {
        value: 'CobraE',
        text: 'Cobra Expired'
      },
      {
        value: 'MemberReq',
        text: 'Member’s Request'
      },
      {
        value: 'NeverEffective',
        text: 'Never Effective'
      }
    ] as Array<ISingleDropdownItem>;
  }

  public getMemberTerminationReasons(): Array<ISingleDropdownItem> {
    return [
      {
        value: 'Qualif Event-Member Term',
        text: 'Qualifying Event - Member Termination'
      },
      {
        value: 'Mbr No Longer Eligible',
        text: 'Member No Longer Eligible for Coverage'
      },
      {
        value: 'Death',
        text: 'Death'
      },
      {
        value: 'Termination of Benefits',
        text: 'Termination of Benefits'
      },
      {
        value: 'Voluntary Withdrawal',
        text: 'Voluntary Withdrawal'
      },
      {
        value: 'No Reason Given',
        text: 'No Reason Given'
      },
      {
        value: 'Never Effective',
        text: 'Never Effective'
      },
      {
        value: 'Subscriber newborn cancellation',
        text: 'Subscriber newborn cancellation'
      },
      {
        value: 'Fraud Cancel',
        text: 'Fraud Cancel'
      },
      {
        value: 'Fraud Term',
        text: 'Fraud Term'
      }
    ] as Array<ISingleDropdownItem>;
  }

  public getCoverageOptions() {
    return [
      {
        value: 'medicalPlanName',
        text: 'Medical'
      },
      {
        value: 'dentalPlanName',
        text: 'Dental'
      }
    ] as Array<ISingleDropdownItem>;
  }

  getAvailableTermDates(): ISingleDropdownItem[] {
    const date_today = new Date();
    const termDatesAvaliable: ISingleDropdownItem[] = [];

    for (let i = 0; i < 4; i++) {
      termDatesAvaliable.push({
        value: new Date(date_today.getFullYear(), date_today.getMonth() + i, 1).toLocaleDateString(),
        text: new Date(date_today.getFullYear(), date_today.getMonth() + i, 1).toLocaleDateString()
      });
    }

    return termDatesAvaliable;
  }
}
