import { Component, OnInit, DoCheck, TemplateRef, ViewChild, ViewContainerRef, Input, AfterViewInit } from '@angular/core';
import { OverlayRef } from '@angular/cdk/overlay';
import { ThemePalette } from '@angular/material/core';
import { ProgressSpinnerMode } from '@angular/material/progress-spinner';
import { AppOverlayService } from '../../_common/services/overlay.service';
import { SpinnerService } from 'src/app/_common/services/spinner.service';

@Component({
  selector: 'app-progress-spinner',
  templateUrl: './progress-spinner.component.html',
  styleUrls: ['./progress-spinner.component.scss']
})
export class ProgressSpinnerComponent implements OnInit, AfterViewInit {

  @Input() color?: ThemePalette = 'primary';
  @Input() diameter = 100;
  @Input() mode?: ProgressSpinnerMode = 'indeterminate';
  @Input() strokeWidth = 7;
  @Input() value = 100;
  backdropEnabled = true;
  positionGloballyCenter = true;
  @Input() displayProgressSpinner: boolean;

  @ViewChild('progressSpinnerRef')
  private progressSpinnerRef: TemplateRef<any>;
  private overlayRef: OverlayRef;

  constructor(
    private vcRef: ViewContainerRef,
    private appOverlayService: AppOverlayService,
    private spinnerService: SpinnerService
  ) {
  }

  ngOnInit() {
    // Config for Overlay Service
    const progressSpinnerOverlayConfig = {
      hasBackdrop: this.backdropEnabled
    };
    if (this.positionGloballyCenter) {
      progressSpinnerOverlayConfig[
        'positionStrategy'
      ] = this.appOverlayService.positionGloballyCenter();
    }
    // Create Overlay for progress spinner
    this.overlayRef = this.appOverlayService.createOverlay(
      progressSpinnerOverlayConfig
    );
  }

  ngAfterViewInit(): void {
    this.spinnerService.ShowSpinner$.subscribe(showSpinner => {
      if (showSpinner && this.overlayRef && !this.overlayRef.hasAttached()) {
        this.appOverlayService.attachTemplatePortal(
          this.overlayRef,
          this.progressSpinnerRef,
          this.vcRef
        );
      } else if (!showSpinner && this.overlayRef && this.overlayRef.hasAttached()) {
        this.overlayRef.detach();
      }
    });
  }
}
