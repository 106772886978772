import { NgModule } from '@angular/core';
import { AbstractTSFormControlFactory, DefaultTSFormControlFactory } from './factories/type-safe-form-control.factory';
import { DefaultFormControlFactory, AbstractFormControlFactory } from './reactive-form-wrappers/form-control.builder';
import { TypeSafeFormControlBuilder } from './type-safe-form-control';
import { TypeSafeFormGroupBuilder } from './type-safe-form-group';

@NgModule({
    exports: [
        TypeSafeFormGroupBuilder,
        TypeSafeFormControlBuilder,
        DefaultFormControlFactory
    ],
    providers: [
        TypeSafeFormGroupBuilder,
        TypeSafeFormControlBuilder,
        { provide: AbstractFormControlFactory, useClass: DefaultFormControlFactory },
        { provide: AbstractTSFormControlFactory, useClass: DefaultTSFormControlFactory }
    ]
})
export class TypeSafeReactiveFormsModule { }
