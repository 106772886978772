
export class Functions {
    public static stringifierForKey<T, P extends keyof T>(key: P): (obj: T) => string {
        return obj => {
            const result = obj[key as keyof T]?.toString().toLowerCase() || '';
            return result;
        };
    }

    public static defaultSortComparetor<T>(): (a: T, b: T) => number {
        return (v1, v2) => v1 < v2 ? -1 : v1 > v2 ? 1 : 0;
    }

    public static strictEqualityMatcher<T>(): (a: T, b: T) => boolean {
        return (a: T, b: T) => a === b;
    }

    public static stringIncludesMatcher<T>(stringifier: (obj: T) => string): (a: T, b: string) => boolean {
        return (obj: T, text: string) => stringifier(obj).includes(text);
    }

    public static getterForKey<T, P extends keyof T>(key: P): (obj: T) => T[P] {
        return obj => obj[key];
    }
}
