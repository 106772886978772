import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError, finalize, switchMap } from 'rxjs/operators';
import { SpinnerService } from '../services/spinner.service';
import { MemberProfileSelector } from 'src/app/manage-my-group/member-profile/_common/services/member-profile-selector/member-profile.selector';
import { SubscriberApiService } from 'src/app/manage-my-group/member-profile/_common/services/subscriber-api.service';
import { ToasterService } from '../services/toaster-service/toaster.service';
import { mailto } from '../shared/helper.functions';
import { SitecoreContentService } from 'src/app/_services/sitecore-content-service/sitecore-content.service';
import { ISubscriber } from 'src/app/manage-my-group/member-profile/_common/models';

@Injectable({
  providedIn: 'root'
})

export class MemberProfileDataResolver implements Resolve<any> {
  constructor(
    private router: Router,
    private formService: SubscriberApiService,
    private memberProfileSelector: MemberProfileSelector,
    private spinnerService: SpinnerService,
    private toasterService: ToasterService,
    private sitecoreContentService: SitecoreContentService
  ) { }

  resolve(route: ActivatedRouteSnapshot, routerStateSnapshot: RouterStateSnapshot): Observable<any> {
    this.spinnerService.showProgressSpinner();
    try {
      if (!this.memberProfileSelector.selectedEmployee) {
        // If user refreshes or navigates directly to URL then bounce to roster
        this.router.navigate(['/employeeRoster']);
        return EMPTY;
      }
      let getMemberData$: Observable<ISubscriber>;
      return this.sitecoreContentService.getSiteCoreToggle('MemberProfile_UseGetMemberDataWithPolicyCoverage')
        .pipe(
          switchMap((data: boolean) => {
            if (!data) {
              getMemberData$ = this.formService.getMemberData(this.memberProfileSelector.selectedEmployee.hccSubscriberId);
            } else {
              getMemberData$ = this.formService.getMemberDataV2(this.memberProfileSelector.selectedEmployee.hccSubscriberId,
                this.memberProfileSelector.selectedEmployee.dentalId ?? '');
            }
            return getMemberData$.pipe(
              catchError(err => {
                this.router.navigate(['/employeeRoster']);
                this.handleError(err);
                return EMPTY;
              }),
              finalize(() => this.spinnerService.hideProgressSpinner())
            );
          }));
    } catch (err) {
      this.router.navigate(['/employeeRoster']);
      this.handleError(err);
      this.spinnerService.hideProgressSpinner();
      return EMPTY;
    }
  }

  private handleError(error: any): Observable<never> {
    this.toasterService.pushToastMessage({
      title: 'Sorry, something went wrong.',
      message: `<br>There was an error retrieving your information. Please try again after some time. If you\'ve attempted multiple times report to
      ${mailto({
        message: {
          address: 'eSolutions@azblue.com',
          subject: 'Member profile load error',
          body: null
        },
        tag: {
          innerHtml: 'technical support'
        }
      })}.`,
      icon: 'red-x-mark',
      bodyClass: { 'card-body-error': true, 'container-margin': true },
      showCloseButton: true,
      shouldScrollToToaster: true
    });
    window.scrollTo(0, 0);
    // Return throwError so error gets logged properly
    return throwError(error);
  }
}
