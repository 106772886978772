import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/_common/modules/shared.module';
import { InputDirectivesModule } from 'src/app/_common/modules/input-directives.module';
import { AngularMaterialModule } from 'src/app/_common/modules/angular-material.module';
import { MatIconModule } from '@angular/material/icon';
import { TypeSafeReactiveFormsModule } from 'src/app/_common/type-safe-reactive-forms/type-safe-reactive-forms.module';
import { RouterModule } from '@angular/router';
import { CollectionComponent } from './collection/collection.component';
import { CardWithButtonComponent } from './card-with-button/card-with-button.component';
import { HeaderWithSubTextComponent } from './header-with-sub-text/header-with-sub-text.component';
import { SitecoreOutletComponent } from './sitecore-outlet/sitecore-outlet.component';
import { RichTextComponent } from './rich-text/rich-text.component';
import { CardComponent } from './card/card.component';
import { ImageWithCaptionComponent } from './image-with-caption/image-with-caption.component';
import { RosterSearchFormComponent } from './roster-search-form/roster-search-form.component';
import { IdCardSearchFormComponent } from './id-card-search-form/id-card-search-form.component';

@NgModule({
  declarations: [
    CardWithButtonComponent,
    HeaderWithSubTextComponent,
    SitecoreOutletComponent,
    CollectionComponent,
    RichTextComponent,
    CardComponent,
    ImageWithCaptionComponent,
    RosterSearchFormComponent,
    IdCardSearchFormComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule,
    InputDirectivesModule,
    AngularMaterialModule, MatIconModule,
    TypeSafeReactiveFormsModule
  ],
  exports: [
    CardWithButtonComponent,
    HeaderWithSubTextComponent,
    SitecoreOutletComponent
  ]
})
export class SitecoreModule { }
