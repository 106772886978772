import { NgModule } from '@angular/core';
import { CommonModule} from '@angular/common';
import { OptionSelectModalBaseComponent } from './modals/option-select-modal/option-select-modal-base.component';
import { DynamicContentOptionSelectModalComponent } from './modals/option-select-modal/dynamic-option-select-modal/dynamic-option-select-modal.component';
import { ModalBuilderTargetComponent } from './modals/injectable-components/modal-builder-target/modal-builder-target.component';
import { InjectableComponentsModule } from './modals/injectable-components/injectable-components.module';

@NgModule({
  declarations: [
    OptionSelectModalBaseComponent,
    DynamicContentOptionSelectModalComponent,
    ModalBuilderTargetComponent
  ],
  imports: [
    CommonModule,
    InjectableComponentsModule
  ],
  providers: [
  ],
  entryComponents: [
    OptionSelectModalBaseComponent
  ],
})
export class ModalServiceModule { }
