import { CommonModule } from '@angular/common';
import {NgModule} from '@angular/core';

import { TreeSelectModule } from '../../../../../custom-controls/tree-select/tree-select.module';
import { TreeSelectModalComponent } from './tree-select-modal.component';

@NgModule({
    imports: [
        CommonModule,
        TreeSelectModule
    ],
    declarations: [
        TreeSelectModalComponent
    ]
})
export class TreeSelectModalModule {}
