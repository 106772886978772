import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { AngularMaterialModule } from 'src/app/_common/modules/angular-material.module';
import { TreeMultiselectComponent } from '../tree-multiselect/tree-multiselect.component';
import { TreeSelectComponent } from './tree-select.component';

@NgModule({
    exports: [
        TreeSelectComponent,
        TreeMultiselectComponent
    ],
    imports: [
        AngularMaterialModule,
        MatIconModule
    ],
    declarations: [
        TreeSelectComponent,
        TreeMultiselectComponent
    ]
})
export class TreeSelectModule {}
