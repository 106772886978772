import { Component, Input } from '@angular/core';


@Component({
    template: `<span [ngClass]="config.ngClass" style="{{config.style}}">{{config.text}}</span>`
})
export class SpanTextElementComponent {
    @Input() public config: ITextElementConfig;
}

export interface ITextElementConfig {
    ngClass?: string | string[] | Set<string> | { [klass: string]: any; };
    text: string;
    style?: string;
}
