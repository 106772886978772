import { AbstractControlOptions, AsyncValidatorFn, ValidatorFn } from '@angular/forms';
import { Observable } from 'rxjs';
import { ISingleDropdownItem } from '../../custom-controls/single-dropdown-select/single-dropdown-select.component';
import { CachedObservable } from '../../shared/cached-observable';
import { TypeSafeFormControl } from '../type-safe-form-control';
import { TypeSafeFormGroup } from './type-safe-form-group';

/**
 * The enabled property of either a control or a group.
 */
 export type ReactantEnabledProperty = (() => boolean) | CachedObservable<boolean>;

/**
 * Used to configure a form group, containing one or more controls under it.
 * ```typescript
 * export interface exampleStructure {
 *  subGroup: {
 *      control: string;
 *  }
 * }
 * ```
 */
export type TypeSafeFormGroupConfig<T> = {
    [P in keyof T]: IsObjectNotCustomFormType<T[P]> extends true ? TypeSafeFormGroup<T[P]> : NonObjectTuple<T[P]>
};

/**
 * Used to reveal the shape of a form and the types of its controls while accessing a group's children.
 * Similar to the structure used to create a form, but used after creation to reveal functionality.
 * ``` typescript
 * this.safeForm.children.example.value
 * ```
 */
export type TypedControlGroup<T> = {
    [P in keyof T]: IsObjectNotCustomFormType<T[P]> extends true ? TypedControlGroup<T[P]> : TypeSafeFormControl<T[P]>;
};

export interface AsyncValidatorConfig {
    asyncValidator?: AsyncValidatorFn;
    /**
     * Used to define dependencies which should trigger the async validator.
     * Note: the attached control will not by default trigger the async validator on value change.
     * TODO: Is it even possible to add the control's valueChange event to its dependencies? Since configuration occurs prior to creation.
     */
    dependencies: Observable<any> | Observable<any>[];
}

/**
 * Custom type to define a form control which has no children.
 * If a field is an object with sub-fields then it is described by the group type.
 */
export type NonObjectTuple<T> = [
    T,
    (ITypeSafeControlConfig<T> | AbstractControlOptions | ValidatorFn | ValidatorFn[])?,
    (AsyncValidatorFn | (AsyncValidatorFn | AsyncValidatorConfig)[])?
];

/**
 * Parrelels (@link AbstractControlOptions) in that it provides options for validators and asyncValidators,
 * but it provides additional functionality with enabledLambda and valueChanges.
 */
export interface ITypeSafeControlConfig<T> {
    validators?: ValidatorFn | ValidatorFn[] | null;
    asyncValidators?: (AsyncValidatorFn | (AsyncValidatorFn | AsyncValidatorConfig)[]);
    /**
     * Configures if a control or group is enabled at the time of calling.
     * A control will not populate validation errors if it or one of it's parents is disabled.
     * Can be used when a control should be hidden under certain circumstances and should not invalidate the form.
     */
     enabled?: (() => boolean) | Observable<boolean>;
     /**
     * Called when the control, or a child of a group, has it's value changed
     */
    valueChanges?: (value: T) => void;
}

/**
 * Helper type used by TSRF to determine if a type should be configured as a control (NonObjectTuple)
 * or as a group (TypeSafeFormGroupConfig).
 */
type IsObjectNotCustomFormType<T> = T extends infer R ? R extends string
? false
: T extends Date
? false
: T extends Array<infer K>
? false
: T extends number
? false
: T extends boolean
? false
: T extends undefined
? false
: T extends {[P in keyof ISingleDropdownItem]: ISingleDropdownItem[P]}
? false
: T extends CustomFormType
? false
: true : false;

/**
 * Can be extended by types which should not be considered a form group.
 * As an example: dropdown item objects should not have their value and text fields turned into individual controls,
 * instead the whole object should be considered the value of the dropdown control.
 */
export class CustomFormType { private DO_NOT_REMOVE: undefined; }

// TODO: implement specialised control which has DropdownItem as its type and provides additional functionality.
export class DropdownItem<T> extends CustomFormType {
    text: string;
    value: T;
}
