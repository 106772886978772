import { IEmployee } from 'src/app/manage-my-group/employee-roster/_common/models';
import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { IAsyncPipeSource } from './pipe.interface';
import { EmployerRosterApiService } from 'src/app/manage-my-group/employee-roster/_common/services/employee-roster-service/employee-roster-api-service';


@Injectable({
    providedIn: 'root'
})
export class EmployeeAsyncSource implements IAsyncPipeSource<Array<IEmployee>>, OnDestroy {
    private subscriptions: Subscription[] = [];
    private _result$: Subject<Array<IEmployee>> = new BehaviorSubject<Array<IEmployee>>(null);
    public get result$(): Observable<IEmployee[]> { return this._result$; }

    constructor(
        private employeeRosterApiService: EmployerRosterApiService
    ) {
        this.subscriptions.push(this.employeeRosterApiService.rosterData$.subscribe(employees => {
            this._result$.next(employees);
        }));
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }
}
