import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, of, Subscription, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { DebuggingService } from 'src/app/layout/debugging/debugging.service';
import { IAccountSummary } from 'src/app/_models';
import { EmployerAccountApiService } from 'src/app/_services/employer-account-service/employer-account-api.service';
import { ISavedEmployeeApplication } from '../../models/saved-applications.interface';
import { SavedApplicationsContentService } from '../saved-applications-content.service';

@Injectable({
  providedIn: 'root'
})
export class SavedApplicationsApiService implements OnDestroy {
  private subscriptions: Subscription[] = [];
  protected headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  public savedApplicationsData$: BehaviorSubject<Array<ISavedEmployeeApplication>> =
  new BehaviorSubject<Array<ISavedEmployeeApplication>>([]);
  public savedEmployeData$:  BehaviorSubject<Array<ISavedEmployeeApplication>> =
  new BehaviorSubject<Array<ISavedEmployeeApplication>>([]);
  public accountNumber: string;

  constructor(
    private http: HttpClient,
    private debuggingService: DebuggingService,
    private employerAccountApiService: EmployerAccountApiService,
    private savedApplicationsContentService: SavedApplicationsContentService
  ) {
    this.subscriptions.push(this.employerAccountApiService.accountSummary$.subscribe(
      (accountSummary: IAccountSummary) => {
          if (accountSummary?.accountId != null) {
              this.accountNumber = accountSummary.accountId;
          }
      }
    ));
  }

  public saveEmployeeApplication(savedEmployeeData: ISavedEmployeeApplication): Observable<string> {
    const urlSaveEmployeeApp = '/api/EmployeeApplication/SaveEmployeeApplication';
    return this.http.post<string>(urlSaveEmployeeApp,
      JSON.stringify(savedEmployeeData, (key, value) => {if (value !== null) {return value; }}),
      { headers: this.headers })
    .pipe(
      map((result: string) => JSON.stringify({ isSuccessful: result, statusCode: '200', message: 'success' })
      ),
      catchError((error, caught) => {
        return of(JSON.stringify({ isSuccessful: false, statusCode: error.status, message: error.error }));
      })
    );
  }

  public getSavedEmployeeApplications(): Observable<ISavedEmployeeApplication[]> {
    const url = `/api/EmployeeApplication/GetSavedEmployeeApplications/${this.accountNumber}`;
    if (this.debuggingService.savedApplications.simulateNoSavedAppFound) {
      return of([]);
    }

    return this.http.get<ISavedEmployeeApplication[]>(url).pipe(
      tap((data: ISavedEmployeeApplication[]) => {
        this.savedApplicationsData$.next(
          this.savedApplicationsContentService.modifySaveAppObject(data)
          );
      }),
      catchError(this.handleError)
    );
  }

  softDeleteSavedApplication(id: number): Observable<boolean> {
    const url = `/api/EmployeeApplication/SoftDeleteSavedEmployeeApplication/${id}`;
    return this.http.get<boolean>(url).pipe(
      catchError(this.handleError)
    );
  }

  public getSavedEmployeeApplicationById(id: number): Observable<ISavedEmployeeApplication[]> {
    const url = `/api/EmployeeApplication/GetSavedEmployeeApplications/${this.accountNumber}/${id}`;

    return this.http.get<ISavedEmployeeApplication[]>(url).pipe(
      tap(data => this.savedEmployeData$.next(data)),
      catchError(this.handleError)
    );
  }

  public updateSavedEmployeeApplication(savedEmployeeData: ISavedEmployeeApplication): Observable<string> {
    const urlSaveEmployeeApp = '/api/EmployeeApplication/UpdateSavedEmployeeApplication';
    return this.http.post<string>(urlSaveEmployeeApp,
      JSON.stringify(savedEmployeeData, (key, value) => {if (value !== null) {return value; }}),
      { headers: this.headers })
    .pipe(
      map((result: string) => JSON.stringify({ isSuccessful: result, statusCode: '200', message: 'success' })
      ),
      catchError((error, caught) => {
        return of(JSON.stringify({ isSuccessful: false, statusCode: error.status, message: error.error }));
      })
    );
  }

  public handleError(error: any) {
    const errorMessage = (error.message)
    ? error.message
    : error.status
        ? `${error.status} - ${error.statusText}`
        : 'Server error';
    return throwError(errorMessage);
  }

  ngOnDestroy(): void {
      this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}
