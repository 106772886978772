import { Component, OnInit } from '@angular/core';
import { BaseCardComponent } from '../base-card/base-card.component';

@Component({
  selector: 'app-order-id-card',
  templateUrl: './order-id-card.component.html',
  styleUrls: ['./order-id-card.component.scss']
})
export class OrderIdCardComponent extends BaseCardComponent implements OnInit {

  constructor() {
    super();
  }

  ngOnInit(): void {
  }
}
