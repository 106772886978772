import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { BaseValidation } from './base-validation';
import { AbstractControl } from '@angular/forms';

@Directive({
    selector: '[appInputValidation]'
})
export class InputValidationDirective extends BaseValidation {
    constructor() {
        super();
    }

    @Input('appInputValidation') validatorName: string;
    @Input() appInput: AbstractControl;

    @HostListener('keydown', ['$event'])
    public onKeyDown(event: any): void {
        super.onKeyDown(event);
    }

    @HostListener('focus', ['$event'])
    public onFocus(event: any): void {
        this.appInput.markAsUntouched({ onlySelf: true });
    }

    @HostListener('paste', ['$event'])
    public onPaste(event: any): void {
        let regex: RegExp = null;
        switch (this.validatorName) {
            case 'alphaNumber':
                regex = /[^0-9A-Za-z]/g;
                break;
            case 'alphaNumberAndSpace':
                regex = /[^0-9A-Za-z ]/g;
                break;
            case 'alphaAndSpace':
                regex = /[^A-Za-z ]/g;
                break;
            case 'alphaOnly':
                regex = /[^A-Za-z]/g;
                break;
            case 'numberOnly':
                regex = /\D/g;
                break;
        }
        this.formatInput(event, regex, this.appInput);
    }

    public enforceValidation(event: any): void {

        let isPreventDefaultRequired = false;

        switch (this.validatorName) {
            case 'alphaNumber':
                isPreventDefaultRequired = (!this.isAlphaNumberInput(event) && !this.isModifierKey(event));
                break;
            case 'alphaNumberAndSpace':
                isPreventDefaultRequired = (!this.isAlphaNumberInput(event) && event.keyCode !== 32 && !this.isModifierKey(event));
                break;
            case 'alphaAndSpace':
                isPreventDefaultRequired = (!this.isAlphaInput(event) && event.keyCode !== 32 && !this.isModifierKey(event));
                break;
            case 'alphaOnly':
                isPreventDefaultRequired = (!this.isAlphaInput(event) && !this.isModifierKey(event));
                break;
            case 'numberOnly':
                isPreventDefaultRequired = (!this.isNumericInput(event) && !this.isModifierKey(event));
                break;
        }
        if (isPreventDefaultRequired) {
            event.preventDefault();
        }
    }
}
