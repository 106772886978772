import { AbstractControl } from '@angular/forms';

export abstract class BaseValidation {
    protected alphabet: Array<number> = [];

    constructor() {
        if (this.alphabet.length === 0) {
            for (let i = 65; i <= 90; i++) {
                this.alphabet.push(i);
            }
        }
    }

    public isModifierKey(event: any): boolean {
        const key: number = event.keyCode;
        return (key === 35 || key === 36) || // Allow Home, End
            (key === 8 || key === 9 || key === 13 || key === 46) || // Allow Backspace, Tab, Enter, Delete
            (key > 36 && key < 41) || // Allow left, up, right, down
            (
                // Allow Ctrl/Command + A,C,V,X,Z
                (event.ctrlKey === true || event.metaKey === true) &&
                (key === 65 || key === 67 || key === 86 || key === 88 || key === 90)
            );
    }

    public isNumericInput(event: any): boolean {
        const key: number = event.keyCode;
        return ((event.shiftKey === false && key >= 48 && key <= 57) || // Allow number line
            (key >= 96 && key <= 105) // Allow number pad
        );
    }

    public abstract enforceValidation(event: any): void;

    protected formatInput(event: any, regex: RegExp, appInput: AbstractControl = null): void {
        const target: any = event.target;
        const clipboard: any = event.clipboardData.getData('text/plain');
        let input: string = clipboard.replace(regex, '');

        const maxLength: number = target.maxLength;
        if (maxLength && maxLength > 0) {
            input = input.substring(0, maxLength);
        }
        this.setInputValue(event, input, appInput);
    }

    protected setInputValue(event: any, value: any, appInput: AbstractControl = null, delay: number = 100): void {
        setTimeout(function (): void {
            appInput.setValue(value);
        }, delay);
    }

    public onKeyDown(event: any): void {
        this.enforceValidation(event);
    }

    public isAlphaInput(event: any): boolean {
        const charCode = event.keyCode;
        return (charCode > 64 && charCode < 91);
    }

    public isAlphaNumberInput(event: any): boolean {
        return (this.isNumericInput(event) || this.isAlphaInput(event));
    }
}
