import { Component, ElementRef, OnInit } from '@angular/core';
import { SitecoreComponentModel } from 'src/app/_models/sitecore/sitecore-component-model';
import { HTML } from '../../../html.functions';
import { CollectionModel } from './collection.model';

@Component({
  selector: 'app-collection',
  templateUrl: './collection.component.html',
  styleUrls: ['./collection.component.scss']
})
export class CollectionComponent extends SitecoreComponentModel implements OnInit {
  public model: CollectionModel;

  public constructor(private element: ElementRef) {
    super();
  }

  ngOnInit(): void {
    if (this.model.Style) {
      HTML.createStyleTag(this.model.Style, this.element);
    }
  }

  public setModel(newModel: any) {
    this.model = newModel;
  }
}
