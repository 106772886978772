import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { EMPTY, forkJoin, Observable, of } from 'rxjs';
import { catchError } from 'rxjs/internal/operators/catchError';
import { filter, finalize, map, mergeMap, take } from 'rxjs/operators';
import { NavigationContentService } from 'src/app/_services/navigation-content-service/navigationContent.service';
import { UserInfoService } from 'src/app/_services/user-info-service/userInfo.service';
import { AuthService } from '../auth/auth.service';
import { SpinnerService } from '../services/spinner.service';

@Injectable({
    providedIn: 'root'
})
export class DashboardDataResolver implements Resolve<any> {
    constructor(private spinnerService: SpinnerService,
        private navigationContentService: NavigationContentService,
        private authService: AuthService,
        private router: Router,
        private userInfoService: UserInfoService) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
        this.spinnerService.showProgressSpinner();
        return forkJoin([
            this.addPipe(this.navigationContentService.updateNavigationBarContent(), false),
            this.addPipe(this.navigationContentService.getFooterContent(), false),
            this.addPipe(this.navigationContentService.getMyAccountDropdown(), false),
            this.addPipe(this.userInfoService.updateOktaUserInfo().pipe(
                mergeMap(_ => {
                    return this.authService.populateAccountSummary();
                })
            ), true)
        ]).pipe(
            mergeMap(bools => {
                if (bools.every(bool => bool)) {
                    return of(true);
                } else {
                    console.error('Error while populating dashboard data.');
                    // Navigate to log-in (or error) page to prevent user from seeing a
                    // blank screen when navigating straight to an internal url while the API is down
                    this.router?.navigate(['/log-in']);

                    // EMPTY (no value emitted before completion) observables will cause route resolution to cancel
                    return EMPTY;
                }
            }),
            finalize(() => {
                this.spinnerService.hideProgressSpinner();
            })
        );
    }

    addPipe<T>(observable: Observable<T>, fatal: boolean): Observable<boolean> {
        return observable.pipe(
            filter(data => !!data),
            take(1),
            map(_ => true),
            catchError(_ => of(!fatal))
        );
    }
}
