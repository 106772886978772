import { AfterViewInit, Component, ComponentFactoryResolver, Inject, Input, Type, ViewChild, ViewContainerRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IButtonOptions } from '../../option-select-modal/models/button-options';
import { ComponentBuilderConfig } from './component-builder.config';

@Component({
  selector: 'app-modal-builder-target',
  templateUrl: './modal-builder-target.component.html',
  styleUrls: ['./modal-builder-target.component.scss']
})
export class ModalBuilderTargetComponent implements AfterViewInit {
  @ViewChild('title', { read: ViewContainerRef }) titleTemplate: ViewContainerRef;
  @ViewChild('content', { read: ViewContainerRef }) contentTemplate: ViewContainerRef;
  constructor(
    public dialogRef: MatDialogRef<ModalBuilderTargetComponent>,
    private componentFactoryResolver: ComponentFactoryResolver,
    @Inject(MAT_DIALOG_DATA) public config: ComponentBuilderConfig
  ) {
  }

  ngAfterViewInit(): void {
    /*
      Paradox: You need a viewchild to inject a component, but viewchildren aren't initialized until ngAfterViewInit.
      BUT!!! If you update anything in ngAfterViewInit you get an error.
      Popular solution?
      setTimeout -.-
    */
    setTimeout(_ => {
      this.config.bodyContainer.title.components.forEach(creationOptions =>
        this.createComponent(this.titleTemplate, creationOptions)
      );
      this.config.bodyContainer.paragraph.components.forEach(creationOptions =>
        this.createComponent(this.contentTemplate, creationOptions)
      );
    });
  }

  onClickButton(button: IButtonOptions): void {
    if (button.onClick) {
      button.onClick(this.dialogRef);
    } else if (button.clickValue) {
      this.dialogRef.close(button.clickValue);
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }

  private createComponent(target: ViewContainerRef, creationOptions: IComponentCreationOptions<SpanTextElementComponent>) {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(creationOptions.componentType);
    const component = target.createComponent(componentFactory);
    if (creationOptions.onComponentCreate) {
      creationOptions.onComponentCreate(component.instance);
    }

    return component;
  }
}

export interface IComponentCreationOptions<T> {
  componentType: Type<T>;
  onComponentCreate: (component: T) => void;
}

@Component({
  template: `<span [ngClass]="config.ngClass" style="{{config.style}}">{{config.text}}</span>`
})
export class SpanTextElementComponent {
  @Input() public config: ITextElementConfig;
}

export interface ITextElementConfig {
  ngClass?: string | string[] | Set<string> | { [klass: string]: any; };
  text: string;
  style?: string;
}
