import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-benefit-book-documents-gridview',
  templateUrl: './benefit-book-documents-gridview.component.html',
  styleUrls: ['./benefit-book-documents-gridview.component.scss']
})
export class BenefitBookDocumentsGridviewComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
