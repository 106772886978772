import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { DebuggingService } from 'src/app/layout/debugging/debugging.service';
import { ICardOrder, IEmployeeIDCardAddress, IEmployeeIDCardImage, IRequestIdCardRequest } from '../models';

@Injectable({
  providedIn: 'root',
})
export class OrderIdCardApiService {
  public employeeIDCardImage$: BehaviorSubject<IEmployeeIDCardImage> = new BehaviorSubject<IEmployeeIDCardImage>(null);
  public employeeIDCardAddress$: BehaviorSubject<IEmployeeIDCardAddress> = new BehaviorSubject<IEmployeeIDCardAddress>(null);

  constructor(private http: HttpClient,
    protected debuggingService: DebuggingService
    ) { }

  public getImageLinks(subscriberId: string): Observable<IEmployeeIDCardImage> {
    const url = `api/EmployeeIDCard${this.debuggingService.orderIdCard.mockImageCall ? '/mock' : ''}/${subscriberId}/GetImageLinks`;
    return this.http.get<IEmployeeIDCardImage>(url).pipe(
      tap(imageResult => this.employeeIDCardImage$.next(imageResult))
    );
  }

  public getImage(cardImageRequest: GetCardImageRequest): Observable<BlobResource> {
    const url = `api/EmployeeIDCard${this.debuggingService.orderIdCard.mockImageCall ? '/mock' : ''}/Image`;
    return this.http.post<BlobResource>(url, cardImageRequest);
  }

  public getOrderIdCardByProductType(requestIdCardData: IRequestIdCardRequest): Observable<ICardOrder> {
    const url = `api/EmployeeIDCard/RequestIDCard`;
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post<ICardOrder>(url, JSON.stringify(requestIdCardData), { headers: headers });
  }
}

export class BlobResource {
  name: string;
  fileType: string;
  data: string;
}

export class GetCardImageRequest {
  cardId: string;
  page: string;
}
