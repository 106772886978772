import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { IToastMessage } from '../../../../_models/toast-message.interface';
import { scrollToElement } from '../../helper.functions';

@Component({
  selector: 'app-toaster-message',
  templateUrl: './toaster-message.component.html',
  styleUrls: ['./toaster-message.component.scss']
})
export class ToasterMessageComponent implements OnInit {
  @Input() toast$: Observable<IToastMessage> = new Observable<IToastMessage>(null);
  public toasterMessage: IToastMessage = null;

  constructor(private element: ElementRef) { }

  ngOnInit(): void {
    this.toast$.subscribe((data: IToastMessage) => {
      this.toasterMessage = data;
      if (data?.shouldScrollToToaster) {
        scrollToElement(this.element.nativeElement);
      }
    });
  }

  close(): void {
    this.toasterMessage = null as IToastMessage;
  }
}
