import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, of, Subscription, throwError } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { DebuggingService } from 'src/app/layout/debugging/debugging.service';
import { IAccountSummary } from 'src/app/_models';
import { EmployerAccountApiService } from 'src/app/_services/employer-account-service/employer-account-api.service';
import { SitecoreContentService } from 'src/app/_services/sitecore-content-service/sitecore-content.service';
import {
  IMemberProfileBenefitSelection, IMemberProfileContactInfo, IMemberProfileDependentInfo,
  IMemberProfileEmployee, IMemberProfileTerminateCoverage, ISubscriber, ISubscriberData, Subscriber
} from '../models/index';
import { IUpdateSubscriberInfoResponse } from './update-subscriber-info.model';

@Injectable({
  providedIn: 'root'
})
export class SubscriberApiService implements OnDestroy {
  private subscriberInfo = <ISubscriber>{};
  public subscriberInfo$: BehaviorSubject<ISubscriber> = new BehaviorSubject<ISubscriber>(this.subscriberInfo);
  private subscriber: Subscriber = new Subscriber();
  private subscription: Subscription = new Subscription();
  private _accountId: string;

  protected headers = new HttpHeaders({ 'Content-Type': 'application/json' });

  constructor(
    private http: HttpClient,
    private employerAccountApiService: EmployerAccountApiService,
    private sitecoreContentService: SitecoreContentService,
    private debuggingService: DebuggingService
  ) {
    this.subscription = this.employerAccountApiService.accountSummary$.subscribe(
      (accountSummary: IAccountSummary) => {
        if (accountSummary?.accountId != null) {
          this._accountId = accountSummary.accountId;
        }
      }
    );
  }

  public handleError(error: any) {
    const errorMessage = (error.message)
      ? error.message
      : error.status
        ? `${error.status} - ${error.statusText}`
        : 'Server error';
    return throwError(errorMessage);
  }

  isMemberInWorkbasket(subscriberId: string): Observable<boolean> {
    if (this._accountId) {
      const url = `api/EmployeeRoster/${subscriberId}/IsMemberInWorkbasket`;
      return this.http.get<boolean>(url).pipe(
        map(data => {
          return data;
        }),
        catchError(this.handleError)
      );
    }
  }

  getMemberDataWithVersionToggle(subscriberId: string, dentalSubscriberId: string): Observable<ISubscriber> {
    const result = this.sitecoreContentService.getSiteCoreToggle('MemberProfile_UseGetMemberDataWithPolicyCoverage')
      .pipe(
        switchMap((featureToggle: boolean) => {
          let getMemberData: Observable<ISubscriber>;
          if (featureToggle) {
            getMemberData = this.getMemberDataV2(subscriberId, dentalSubscriberId);
          } else {
            getMemberData = this.getMemberData(subscriberId);
          }

          return getMemberData;
        })
      );
    return result;
  }

  getMemberData(subscriberId: string): Observable<ISubscriber> {
    if (this._accountId) {
      const url = `api/EmployeeRoster/${this._accountId}/${subscriberId}/GetMemberData`;
      return this.http.get<ISubscriberData>(url).pipe(
        map(data => {
          return this.subscriber.parseToSubscriber(data);
        }),
        tap(subscriber => this.subscriberInfo$.next(subscriber)),
        catchError(this.handleError)
      );
    }
  }

  getMemberDataV2(subscriberId: string, dentalSubscriberId: string): Observable<ISubscriber> {
    if (this._accountId) {
      if (dentalSubscriberId === '') {
        dentalSubscriberId = 'null';
      }
      const url = `api/v2/EmployeeRoster/${this._accountId}/${subscriberId}/${dentalSubscriberId}/GetMemberData`;
      return this.http.get<ISubscriberData>(url).pipe(
        map(data => {
          return this.subscriber.parseToSubscriber(data);
        }),
        tap(subscriber => this.subscriberInfo$.next(subscriber)),
        catchError(this.handleError)
      );
    }
  }

  public updateSubscriberInfo(data): void {
    this.subscriberInfo = {
      ...this.subscriberInfo,
      ...data
    };
    this.subscriberInfo$.next(this.subscriberInfo);
  }

  postSubscriberInfo(subscriberData: ISubscriber): Observable<string> {
    const url = 'api/Subscriber/UpdateSubscriberInfo';
    return this.debuggingService.memberProfile.submitMember.simulateApiCallFail
      ? of(JSON.stringify({ isSuccessful: false, message: 'Debugger: Failed to submit member updates', statusCode: '400' }))
      : (this.http.post(url, JSON.stringify(subscriberData), { headers: this.headers })
        .pipe(
          map((result: string) => JSON.stringify({ isSuccessful: result, statusCode: '200', message: 'success' })),
          catchError((error) => {
            return of(JSON.stringify({ isSuccessful: false, statusCode: error.status, message: error.error }));
          })
        ));
  }

  postUpdateSubscriberName(employeeInfo: IMemberProfileEmployee): Observable<boolean> {
    return this.http.post<boolean>(
      '/api/MemberProfile/UpdateSubscriberName', JSON.stringify(employeeInfo), { headers: this.headers });
  }

  postUpdateSubscriberSsn(employeeInfo: IMemberProfileEmployee): Observable<boolean> {
    return this.http.post<boolean>(
      '/api/MemberProfile/UpdateSubscriberSsn', JSON.stringify(employeeInfo), { headers: this.headers });
  }

  postUpdateSubscriberDateOfBirth(employeeInfo: IMemberProfileEmployee): Observable<boolean> {
    return this.http.post<boolean>(
      '/api/MemberProfile/UpdateSubscriberDateOfBirth', JSON.stringify(employeeInfo), { headers: this.headers });
  }

  postUpdateSubscriberMaritalStatus(employeeInfo: IMemberProfileEmployee): Observable<boolean> {
    return this.http.post<boolean>(
      '/api/MemberProfile/UpdateSubscriberMaritalStatus', JSON.stringify(employeeInfo), { headers: this.headers });
  }

  postUpdateSubscriberGender(employeeInfo: IMemberProfileEmployee): Observable<boolean> {
    return this.http.post<boolean>(
      '/api/MemberProfile/UpdateSubscriberGender', JSON.stringify(employeeInfo), { headers: this.headers });
  }

  postUpdateSubscriberEmployeeLocation(employeeInfo: IMemberProfileEmployee): Observable<boolean> {
    return this.http.post<boolean>(
      '/api/MemberProfile/UpdateSubscriberEmployeeLocation', JSON.stringify(employeeInfo), { headers: this.headers });
  }

  postUpdateSubscriberEmployeeId(employeeInfo: IMemberProfileEmployee): Observable<boolean> {
    return this.http.post<boolean>(
      '/api/MemberProfile/UpdateSubscriberEmployeeId', JSON.stringify(employeeInfo), { headers: this.headers });
  }

  postEmployeeInfo(employeeInfo: IMemberProfileEmployee): Observable<IUpdateSubscriberInfoResponse> {
    const url = '/api/MemberProfile/UpdateEmployeeInfo ';
    return this.http.post(url, JSON.stringify(employeeInfo), { headers: this.headers })
      .pipe(
        map((result: string) => ({ isSuccessful: result, statusCode: '200', message: 'success' })),
        catchError((error) => {
          return of(<IUpdateSubscriberInfoResponse>{ isSuccessful: false, statusCode: error.status, message: error.error });
        }));
  }

  postUpdateSubscriberAddress(contactInfo: IMemberProfileContactInfo): Observable<boolean> {
    return this.http.post<boolean>(
      '/api/MemberProfile/UpdateSubscriberAddress', JSON.stringify(contactInfo), { headers: this.headers });
  }

  postUpdateSubscriberPhoneNumbers(contactInfo: IMemberProfileContactInfo): Observable<boolean> {
    return this.http.post<boolean>(
      '/api/MemberProfile/UpdateSubscriberPhoneNumbers', JSON.stringify(contactInfo), { headers: this.headers });
  }

  postUpdateSubscriberEmail(contactInfo: IMemberProfileContactInfo): Observable<boolean> {
    return this.http.post<boolean>(
      '/api/MemberProfile/UpdateSubscriberEmail', JSON.stringify(contactInfo), { headers: this.headers });
  }

  postContactInfo(contactInfo: IMemberProfileContactInfo): Observable<IUpdateSubscriberInfoResponse> {
    const url = '/api/MemberProfile/UpdateContactInfo ';
    return this.http.post(url, JSON.stringify(contactInfo), { headers: this.headers })
      .pipe(
        map((result: string) => ({ isSuccessful: result, statusCode: '200', message: 'success' })),
        catchError((error) => {
          return of(<IUpdateSubscriberInfoResponse>{ isSuccessful: false, statusCode: error.status, message: error.error });
        }));
  }

  postBenefitSelectionInfo(benefitInfo: IMemberProfileBenefitSelection): Observable<IUpdateSubscriberInfoResponse> {
    const url = '/api/MemberProfile/UpdateBenefitInfo ';
    return this.http.post(url, JSON.stringify(benefitInfo), { headers: this.headers })
      .pipe(
        map((result: string) => ({ isSuccessful: result, statusCode: '200', message: 'success' })),
        catchError((error) => {
          return of(<IUpdateSubscriberInfoResponse>{ isSuccessful: false, statusCode: error.status, message: error.error });
        }));
  }

  postDependentnfo(dependentInfo: IMemberProfileDependentInfo): Observable<IUpdateSubscriberInfoResponse> {
    const url = '/api/MemberProfile/UpdateDependentsInfo';
    return this.http.post(url, JSON.stringify(dependentInfo), { headers: this.headers })
      .pipe(
        map((result: string) => ({ isSuccessful: result, statusCode: '200', message: 'success' })),
        catchError((error) => {
          return of(<IUpdateSubscriberInfoResponse>{ isSuccessful: false, statusCode: error.status, message: error.error });
        }));
  }

  postTerminateCoverageInfo(terminateCoverageInfo: IMemberProfileTerminateCoverage): Observable<IUpdateSubscriberInfoResponse> {
    const url = '/api/MemberProfile/UpdateTerminateCoverage ';
    return this.http.post(url, JSON.stringify(terminateCoverageInfo), { headers: this.headers })
      .pipe(
        map((result: string) => ({ isSuccessful: result, statusCode: '200', message: 'success' })),
        catchError((error) => {
          return of(<IUpdateSubscriberInfoResponse>{ isSuccessful: false, statusCode: error.status, message: error.error });
        }));
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
