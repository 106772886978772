import { Component, Input, OnInit } from '@angular/core';
import { IDashboardCard } from '../common/models/dashboard-card.interface';

@Component({
  selector: 'app-base-card',
  template: '',
  styleUrls: ['./base-card.component.scss']
})
export class BaseCardComponent implements OnInit {

  @Input() cardSize: string;
  @Input() cardData: IDashboardCard;

  constructor() {
  }

  ngOnInit(): void {
  }

  get isLargeCard(): boolean {
    if (this.cardSize === 'L') {
      return true;
    }
    return false;
  }
}
