import { AbstractControlOptions } from '@angular/forms';
import { ITypeSafeControlConfig } from './type-safe-form-group/type-safe-form-group-config';


export class TypeGuards {
    public static isFunction(item: any): item is Function {
        return typeof (item) === 'function';
    }

    public static isObject(item: any) {
        return (item && typeof item === 'object' && !Array.isArray(item));
    }

    public static isAbstractControlOptions(obj: any): obj is AbstractControlOptions {
        return this.hasKey<AbstractControlOptions>(obj, 'validators')
            || this.hasKey<AbstractControlOptions>(obj, 'asyncValidators');
    }

    public static isTypeSafeControlConfig<T>(obj: any): obj is ITypeSafeControlConfig<T> {
        return this.hasKey<ITypeSafeControlConfig<T>>(obj, 'validators')
            || this.hasKey<ITypeSafeControlConfig<T>>(obj, 'asyncValidators')
            || this.hasKey<ITypeSafeControlConfig<T>>(obj, 'enabled')
            || this.hasKey<ITypeSafeControlConfig<T>>(obj, 'valueChanges');
    }

    public static hasKey<T>(obj: any, key: (keyof T)): boolean {
        return !!obj?.hasOwnProperty(key);
    }
}
