import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppComponent } from './app.component';
import { LandingPageModule } from './landingpage/landingpage.module';
import { AppRoutingModule } from './app-routing.module';
import { LayoutModule } from './layout/layout.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularMaterialModule } from './_common/modules/angular-material.module';
import { AuthGuard } from './_common/auth/auth.guard';
import { HomeComponent } from './layout/home/home.component';
import { OktaAuthModule } from '@okta/okta-angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import '@okta/okta-auth-js/polyfill';
import { CustomControlsModule } from './_common/modules/custom-controls.module';
import { AppOverlayService } from './_common/services/overlay.service';
import { SpinnerService } from './_common/services/spinner.service';
import { ProgressSpinnerComponent } from './layout/progress-spinner/progress-spinner.component';
import { ModalService } from './_common/services/modal-service/modal.service';
import { ModalServiceModule } from './_common/services/modal-service/modal.service.module';
import { MiddlewareAPIInterceptor } from './_common/interceptors/middlewareAPI.interceptor';
import { UserActivityService } from './_common/services/user-activity.service';
import { TokenExpireNotifierService } from './_common/services/token-expire-notifier/token-expire-notifier.service';
import { InputDirectivesModule } from './_common/modules/input-directives.module';
import { MatIconModule } from '@angular/material/icon';
import { PrintTargetComponent } from './layout/print-target/print-target.component';
import { ToasterService } from './_common/services/toaster-service/toaster.service';
import { SharedModule } from './_common/modules/shared.module';
import { TreeSelectModule } from './_common/custom-controls/tree-select/tree-select.module';
import { SitecoreModule } from './_common/shared/components/sitecore/sitecore.module';
import { TealeafComponent } from './tealeaf/tealeaf.component';
import { BenefitBookDocumentsGridviewComponent } from './benefit-documents/benefit-books/benefit-book-documents-gridview/benefit-book-documents-gridview.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ProgressSpinnerComponent,
    PrintTargetComponent,
    TealeafComponent,
    BenefitBookDocumentsGridviewComponent
  ],
  exports: [
    ProgressSpinnerComponent,
    PrintTargetComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    SitecoreModule,
    HttpClientModule,
    SharedModule,
    AppRoutingModule,
    LandingPageModule,
    LayoutModule,
    BrowserAnimationsModule,
    AngularMaterialModule,
    MatIconModule,
    FormsModule,
    ReactiveFormsModule,
    OktaAuthModule,
    CustomControlsModule,
    ModalServiceModule,
    InputDirectivesModule,
    TreeSelectModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: MiddlewareAPIInterceptor, multi: true },
    AuthGuard,
    UserActivityService,
    AppOverlayService,
    SpinnerService,
    ModalService,
    ToasterService,
    { provide: APP_INITIALIZER, useFactory: _ => __ => {}, multi: true, deps: [UserActivityService, TokenExpireNotifierService] }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
