import { NgModule } from '@angular/core';
import { AllowDateDirective, InputValidationDirective, PhoneDirective, SSNDirective } from '../input-directives';

@NgModule({
  exports: [
    SSNDirective,
    AllowDateDirective,
    InputValidationDirective,
    PhoneDirective
  ],
  declarations: [
    SSNDirective,
    AllowDateDirective,
    InputValidationDirective,
    PhoneDirective
  ],
  providers: [
    SSNDirective,
    AllowDateDirective,
    InputValidationDirective,
    PhoneDirective
  ]
})
export class InputDirectivesModule {
}
