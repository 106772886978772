import { Component, OnInit, OnDestroy } from '@angular/core';
import { NavigationContentService } from '../../_services/navigation-content-service/navigationContent.service';
import { INavigationHeaderItem } from '../../_models';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, OnDestroy {
  public footerItems: Array<INavigationHeaderItem> = [];
  public copyRightDisplayMessage: string;
  private _subscription: Subscription;

  constructor(private navigationContentService: NavigationContentService) { }

  ngOnInit() {
    this.getFooterItems();
  }

  private getFooterItems(): void {
    this._subscription = this.navigationContentService.footerContent$.subscribe(
      data => {
        this.footerItems = data;
        if (this.footerItems) {
          const copyRightMessage = this.footerItems[4]?.displayLink;
          const date = new Date().getFullYear();
          this.copyRightDisplayMessage = '© ' + date + ' ' + copyRightMessage;
          this.footerItems = this.footerItems.slice(0, 4);
        }
      });
  }

  ngOnDestroy(): void {
    if (this._subscription) {
      this._subscription.unsubscribe();
    }
  }
}
