import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { NavigationContentService } from '../../_services/navigation-content-service/navigationContent.service';
import { INavigationHeaderItem, INavigationLink } from '../../_models';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { SitecoreContentService } from 'src/app/_services/sitecore-content-service/sitecore-content.service';
import { formatDate } from '@angular/common';
import { AlertMessageModel } from 'src/app/_models/sitecore/sitecoreAlertMessage.model';

@Component({
    selector: 'app-navigation',
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class NavigationComponent implements OnInit, OnDestroy {
    public navbarItems: Array<INavigationHeaderItem> = [];
    public isDashboardActive: boolean;
    public activatedRoute: string;
    public notificationToggle = false;
    public notificationAlertMessage: AlertMessageModel;
    public todayDate: string;
    public dropdownMenu: HTMLElement;
    public clicked = false;
    private subscriptions: Subscription[] = [];

    constructor (private navigationContentService: NavigationContentService, private router: Router,
      private sitecoreContentService: SitecoreContentService
    ) {
      this.subscriptions.push(
        this.navigationContentService.navigationHeaderContent$.subscribe((data) => {
          this.activatedRoute = this.router.url.substring(1);
          this.navbarItems = data;
          this.findActiveMenu(this.activatedRoute);
        })
      );
    }

    ngOnInit(): void {
      this.todayDate = this.getFormatDate(new Date());
      this.getSitecoreTogglesData();
      this.dropdownMenu = document.querySelector('.notification-menu');
    }

    ngOnDestroy(): void {
      if (this.subscriptions) {
        this.subscriptions.forEach((s) => s.unsubscribe());
      }
    }
    public getSitecoreTogglesData() {
      this.subscriptions.push(
        this.sitecoreContentService.getSiteCoreToggle('Employer_Alert_Messages').subscribe((toggle: boolean) => {
          this.notificationToggle = toggle;
        })
      );

      this.subscriptions.push(
        this.sitecoreContentService
          .getSiteCoreToggleMessage('Employer_Alert_Messages')
          .subscribe((toggleAlert: AlertMessageModel) => {
            this.notificationAlertMessage = toggleAlert;
          })
      );
    }

    public manageNavActiveClass(navItemHeader): void {
      this.isDashboardActive = navItemHeader === 'My Dashboard';
      this.navbarItems?.forEach((item: INavigationHeaderItem) => {
        item.isActive = !this.isDashboardActive && navItemHeader === item.header;
      });
    }

    public getDashboardActive(): boolean {
      return this.isDashboardActive;
    }

    public findActiveMenu(routeUrl: string): void {
      this.isDashboardActive = routeUrl === '' || routeUrl === '/' || routeUrl.startsWith('home');

      this.navbarItems.forEach((item: INavigationHeaderItem) => {
        item.isActive =
          !this.isDashboardActive && // Set all items to false if true
          !!item.subItems?.some((subItem: INavigationLink) => subItem.componentName?.split('/')[0] === routeUrl);
      });
    }

    public hoverShowMenu() {
      if (this.notificationToggle) {
        this.dropdownMenu.classList.add('show');
      }
    }

    public mouseOutHideMenu() {
      if (this.notificationToggle) {
        this.dropdownMenu.classList.remove('show');
      }
    }

    private getFormatDate(todayDate: Date): string {
      return formatDate(todayDate, 'dd/MM/yyyy hh:mm a', 'en-US');
    }
  }
