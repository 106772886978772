import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { EmployeeRosterGridPipeline } from 'src/app/_common/pipeline/employee-roster-grid.pipeline';
import { TypeSafeFormGroup, TypeSafeFormGroupBuilder } from 'src/app/_common/type-safe-reactive-forms/type-safe-form-group';
import { TypeSafeFormGroupConfig } from 'src/app/_common/type-safe-reactive-forms/type-safe-form-group/type-safe-form-group-config';
import { SitecoreComponentModel } from 'src/app/_models/sitecore/sitecore-component-model';
import { RosterSearchFormStructure } from './roster-search-form.structure';

@Component({
  selector: 'app-roster-search-form',
  templateUrl: './roster-search-form.component.html',
  styleUrls: ['./roster-search-form.component.scss']
})
export class RosterSearchFormComponent extends SitecoreComponentModel implements OnInit {
  private safeForm: TypeSafeFormGroup<RosterSearchFormStructure>;
  public form: FormGroup;

  constructor(
    private safeFormBuilder: TypeSafeFormGroupBuilder<RosterSearchFormStructure>,
    private router: Router,
    public employeeRosterGridPipeline: EmployeeRosterGridPipeline
    ) {
    super();
  }

  ngOnInit(): void {
    this.createForm();
  }

  createForm(): void {
    const rosterSearchStructure: TypeSafeFormGroupConfig<RosterSearchFormStructure> = {
      searchText: [null, { valueChanges: searchText => this.employeeRosterGridPipeline.searchPipe.searchText = searchText }]
    };
    this.safeForm = this.safeFormBuilder.group(rosterSearchStructure);
    this.form = this.safeForm.form;
  }

  searchEmployee(): void {
    const formValues = this.safeForm.form.getRawValue() as RosterSearchFormStructure;
    this.employeeRosterGridPipeline.searchPipe.searchText = formValues.searchText;
    this.router.navigate(['/employeeRoster']);
  }

  public setModel(model: any) {
    // intentionally empty
  }
}
