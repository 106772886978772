import { Component, OnInit } from '@angular/core';
import { BaseCardComponent } from '../base-card/base-card.component';

@Component({
  selector: 'app-activity-reports-card',
  templateUrl: './activity-reports-card.component.html',
  styleUrls: ['./activity-reports-card.component.scss']
})
export class ActivityReportsCardComponent extends BaseCardComponent implements OnInit {

  constructor() {
    super();
  }

  ngOnInit(): void {
  }
}
