import { FlatTreeControl } from '@angular/cdk/tree';
import {
  MatTreeFlatDataSource,
  MatTreeFlattener
} from '@angular/material/tree';

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TreeViewNode } from './tree-view.model';
import { TreeViewModel } from '../tree-multiselect/tree-view-model';

interface FlatTreeNode {
  expandable: boolean;
  text: string;
  value: any;
  treeNode: TreeViewNode;
  level: number;
}

@Component({
  selector: 'app-tree-select',
  templateUrl: './tree-select.component.html',
  styleUrls: ['./tree-select.component.scss']
})
export class TreeSelectComponent implements OnInit {
  @Input() public id: string;
  @Input() public treeModel: TreeViewNode[] = [];
  @Input() public selectedItem: TreeViewNode;
  @Input() public treeViewModel: TreeViewModel;

  @Output() public selectedItemChange: EventEmitter<any> = new EventEmitter();

  treeControl = new FlatTreeControl<FlatTreeNode>(
    node => node.level,
    node => node.expandable
  );

  treeFlattener = new MatTreeFlattener(
    this._transformer,
    node => node.level,
    node => node.expandable,
    node => node.children
  );

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  private _transformer (node: TreeViewNode, level: number): FlatTreeNode {
    return {
      expandable: !!node.children && node.children.length > 0,
      text: node.text,
      value: node.value,
      treeNode: node,
      level: level
    };
  }

  constructor() {
  }

  hasChild (_: number, node: FlatTreeNode) { return node.expandable; }

  handleChange(item: TreeViewNode): void {
    this.selectedItem = item;
    this.selectedItemChange?.emit(item);
  }

  ngOnInit(): void {
    this.dataSource.data = this.treeModel;
    this.treeControl.expandAll();
  }
}
