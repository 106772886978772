import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { throwError as observableThrowError } from 'rxjs';
import { UserSessionInformation } from '../../_models/userSessionInformation.interface';

@Injectable({
    providedIn: 'root'
})
export class AppOktaApiService {
    protected headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    constructor(
        private http: HttpClient) {
    }

    public handleError(error: any) {
        const errMsg = (error.message) ? error.message :
            error.status ? `${error.status} - ${error.statusText}` : 'Server error';
        return observableThrowError(errMsg);
    }

    updateAccountInOkta(username: string): Observable<boolean> {
        return this.http.get(
            `api/EmployerAccount/${username}/UpdateOktaAccountIdList`,
            { headers: this.headers })
            .pipe(map((result: boolean) => result), catchError(this.handleError));
    }

    updateEmployerUserRole(username: string): Observable<boolean> {
      return this.http.get(
          `api/EmployerPermissions/${username}/UpdateEmployerUserRole`,
          { headers: this.headers })
          .pipe(map((result: boolean) => result), catchError(this.handleError));
  }

    updateGroupInOkta(username: string): Observable<boolean> {
        return this.http.get(
            `api/EmployerPermissions/${username}/UpdateOktaUserGroup`,
            { headers: this.headers })
            .pipe(map((result: boolean) => result), catchError(this.handleError));
    }

    updateOrganizationIdInOkta(accountNumber: string, username: string): Observable<boolean> {
        return this.http.get(
            `api/EmployerAccount/${accountNumber}/${username}/UpdateOrganizationIdInOkta`,
            { headers: this.headers })
            .pipe(map((result: boolean) => result), catchError(this.handleError));
    }

    getOktaSessionBySID(sessionId: string): Observable<UserSessionInformation> {
        return this.http.get(
            `api/EmployerPermissions/${sessionId}/GetOktaSessionBySID`,
            { headers: this.headers })
            .pipe(map((result: UserSessionInformation) => result), catchError(this.handleError));
      }
}
