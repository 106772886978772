import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/_common/auth/auth.service';
import { SitecoreAngularComponentResponse } from 'src/app/_services/sitecore-content-service/sitecore-content.service';
import { ToasterAlertService } from 'src/app/_common/services/toaster-alert-service/toaster-alert.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashBoardComponent implements OnInit, OnDestroy {

  public dashboardCards: Array<SitecoreAngularComponentResponse>;
  subscriptions: Subscription[] = [];
  isAuthenticated: boolean;
  userName: string;
  groups: string[];
  acccountsAsString: string;

  constructor(
    private toasterAlertService: ToasterAlertService,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private router: Router) { }

  ngOnInit() {
    this.dashboardCards = this.activatedRoute?.snapshot?.data?.sitecoreItem?.outlets?.top;

    this.authService.checkAuthenticated()
      .then((authenticated) => {
        this.isAuthenticated = authenticated;
      });
  }

  getCardSize(index: number): string {
    let i = index;
    if (index % 2 !== 0) {
      i = (index + 1);
    }
    if (((i / 2) % 2) === 0) {
      return 'L';
    }
    return 'S';
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
