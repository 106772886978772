export class AppConstants {
  public static USER_LOGIN_INFO = 'loginInfo';
  public static USER_OKTA_TRANSACTION_INFO = 'oktaTransactionInfo';
  public static USER_INFO_DETAILS = 'userInfo';
  public static USER_ACTIVITY_DETAILS = 'USER_ACTIVITY_DETAILS';
  public static USER_ACCESS_TOKEN = 'userAccessToken';
  public static USER_FIRSTNAME = 'userFirstName';
  public static USER_ACCOUNTS = 'userAccounts';
  public static SAVED_APP_CURRENT_EMPLOYEE = 'currentSavedEmployee';
  public static USER_OKTA_ASSIGNED_SUBACCOUNTS = 'userAssignedSubAccounts';
  public static AZBEACON_COOKIE_KEY = 'AZBeacon';
}
