import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize, map } from 'rxjs/operators';
import { IEmployeeForm } from 'src/app/manage-my-group/add-employee/standard-application/_common/models';
import { SpinnerService } from 'src/app/_common/services/spinner.service';
import { UserInfoService } from 'src/app/_services/user-info-service/userInfo.service';
import { ISavedEmployeeApplication } from '../models/saved-applications.interface';
import { SavedApplicationsApiService } from './savedApplicationsService/saved-applications-api.service';
import { SitecoreContentService } from 'src/app/_services/sitecore-content-service/sitecore-content.service';

@Injectable({
  providedIn: 'root'
})
export class SavedApplicationsService {
  private idSavedEmployee: number;
  private cdhpToggle: boolean;

  constructor(
    public spinnerService: SpinnerService,
    private savedApplicationsApiService: SavedApplicationsApiService,
    public userInfoService: UserInfoService,
    private sitecoreContentService: SitecoreContentService
  ) {
    this.sitecoreContentService.getSiteCoreToggle('CdhpToggle').subscribe((toggle: boolean) => {
      this.cdhpToggle = toggle;
    });
  }

  saveEmployeeApplication(employeeForm: IEmployeeForm): Observable<string> {
    this.spinnerService.showProgressSpinner();
    return this.savedApplicationsApiService.saveEmployeeApplication(
      this.parseToSavedEmployeeApplication(employeeForm))
    .pipe(
        map((response: string) => {
          return response;
        }),
        finalize(() => {
          this.spinnerService.hideProgressSpinner();
        })
    );
  }

  updateSavedEmployeeApplication(employeeForm: IEmployeeForm, id: number): Observable<string> {
    this.spinnerService.showProgressSpinner();
    this.idSavedEmployee = id;
    return this.savedApplicationsApiService.updateSavedEmployeeApplication(
      this.parseToSavedEmployeeApplication(employeeForm))
    .pipe(
        map((response: string) => {
          return response;
        }),
        finalize(() => {
          this.spinnerService.hideProgressSpinner();
        })
    );
  }


  public parseToEmployeeForm(applicationJson: string): IEmployeeForm {
    if (applicationJson) {
      return JSON.parse(applicationJson) as IEmployeeForm;
    }
  }

  public parseToSavedEmployeeApplication(
    employeeForm: IEmployeeForm
  ): ISavedEmployeeApplication {
    const employeeContent: ISavedEmployeeApplication = {
     userId: this.userInfoService.getLoggedInUserId(),
     accountNumber:  employeeForm?.employeeInfo?.accountNumber,
     subaccountNumber: employeeForm?.employeeInfo?.subAccount,
     ssn: employeeForm?.employeeInfo?.socialSecurityNumber,
     applicationType: 'Standard Application',
     employeeFirstName: employeeForm?.employeeInfo?.firstName,
     employeeLastName: employeeForm?.employeeInfo?.lastName,
     applicationJson: this.convertToApplicationJson(employeeForm),
     id: this.idSavedEmployee ?? null,
     cdhpPlan: this.cdhpToggle ? employeeForm.benefitSelection?.cdhProduct : ''
   } as ISavedEmployeeApplication;
   return employeeContent;
  }

  public convertToApplicationJson(employeeData: IEmployeeForm): string {
   return JSON.stringify(employeeData, (key, value) => {if (value !== null) {return value; }});
  }

  deleteSelectedEmployee(id: number): Observable<boolean> {
    return this.savedApplicationsApiService.softDeleteSavedApplication(id);
  }
}
