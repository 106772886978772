import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { IEmployee } from '../../../../employee-roster/_common/models';
import { SubscriberApiService } from '../subscriber-api.service';
import { ToasterService } from 'src/app/_common/services/toaster-service/toaster.service';

@Injectable({ providedIn: 'root' })
export class MemberProfileSelector {
  private _selectedMemberProfile: IEmployee;
  public get selectedEmployee() { return this._selectedMemberProfile; }
  public set selectedEmployee(employee: IEmployee) { this._selectedMemberProfile = employee; }

  constructor(
    private router: Router,
    private toasterService: ToasterService,
    private subscriberApiService: SubscriberApiService
  ) { }

  viewEmployee(employee: IEmployee): void {
    this.subscriberApiService.isMemberInWorkbasket(employee.hccSubscriberId).subscribe((inWorkbasket: boolean) => {
      if (!inWorkbasket) {
        this.selectedEmployee = employee;
        this.router.navigate(['/memberProfile', this.selectedEmployee.hccSubscriberId]);
      } else {
        this.handleWorkbasketToaster();
      }
    });
  }

  private handleWorkbasketToaster(): void {
    this.toasterService.pushToastMessage({
      title: 'Sorry, something went wrong.',
      message: `<br>This employee is currently in processing, no updates can be made at this time.`,
      icon: 'red-x-mark',
      bodyClass: { 'card-body-error': true, 'container-margin': true },
      showCloseButton: true,
      shouldScrollToToaster: true
    });
    window.scrollTo(0, 0);
  }
}
