import { Injectable, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { ManageMyGroupDropdownService } from 'src/app/manage-my-group/_common/services/manage-my-group-dropdown.service';
import { UserInfoService } from 'src/app/_services/user-info-service/userInfo.service';
import {
    IBenefitSelection,
    IContactInfo,
    IDependentInfo, IEmployeeForm, IEmployeeInfo as EmployeeDataForm, IPlanInfo, MedicareCoverage } from '../../_common/models';
import { IAddressInfo } from '../../_common/models/submit-to-api/address-info';
import { IContactDataInfo } from '../../_common/models/submit-to-api/contact-info';
import { IEmployeeDataInfo } from '../../_common/models/submit-to-api/employee-data-info';
import { IEmployeeInfo } from '../../_common/models/submit-to-api/employee-info';
import { IMedicareDataInfo } from '../../_common/models/submit-to-api/medicare-Info';
import { IPhoneNumberDataInfo } from '../../_common/models/submit-to-api/phone-number-info';
import { IProductDataInfo } from '../../_common/models/submit-to-api/products-info';
import { ISubscriberDataInfo } from '../../_common/models/submit-to-api/subscriber-info';
import { SitecoreContentService } from 'src/app/_services/sitecore-content-service/sitecore-content.service';

@Injectable({
  providedIn: 'root'
})
export class EmployeeInfoContentService implements  OnDestroy {
  private subscriptions: Subscription[] = [];
  private accountId: string;
  private today: Date = new Date();
  private effectiveDateOfCoverage: Date;
  private healthPlansFromService: IPlanInfo[] = [];
  private dentalPlansFromService: IPlanInfo[] = [];
  private healthPlanId: string;
  private dentalPlanId: string;
  private cdhpToggle: boolean;

  constructor(
    private dropdownService: ManageMyGroupDropdownService,
    public userInfoService: UserInfoService,
    private sitecoreContentService: SitecoreContentService
    ) {
      this.sitecoreContentService.getSiteCoreToggle('CdhpToggle').subscribe(toggle => {
        this.cdhpToggle = toggle;
      });
    }

  public parseToEmployeeDataInfo(employeeData: IEmployeeForm ): IEmployeeDataInfo {
      this.getPlansFromService();

      const numberOfPeople = 1;
      this.accountId = employeeData?.employeeInfo?.subAccount;
      this.effectiveDateOfCoverage = new Date(employeeData.employeeInfo.effectiveDateOfCoverage);

      const employeeContent: IEmployeeDataInfo = {
          subscriber: this.parseToSubscriber(employeeData),
          dependents: employeeData.dependentsInfo !== undefined
          ? this.parseToDependentInfo(employeeData)
          : null,
          employee: this.parseToBasicEmployeeInfo(employeeData.employeeInfo),
          contact: this.parseToContactInfo(employeeData?.contactInfo),
          receivedDate: this.today.toISOString().slice(0, 10),
          totalNumberOfPeople: employeeData.dependentsInfo !== undefined
          ? numberOfPeople + employeeData.dependentsInfo.length
          : numberOfPeople
      } as IEmployeeDataInfo;
      return employeeContent;
  }

  private getPlansFromService(): void {
      this.subscriptions.push(
          this.dropdownService.BenefitPlanItems$.
            subscribe((plans) => {
              if (plans) {
                this.healthPlansFromService = plans;
              }
            })
      );
      this.subscriptions.push(
          this.dropdownService.DentalPlanItems$.
            subscribe((plans) => {
              if (plans) {
                this.dentalPlansFromService = plans;
              }
            })
      );
  }

  private getPlanID(healthPlanName: string, dentalPlanName: string): void {
      this.healthPlanId = this.healthPlansFromService
      .filter(plan => plan.planDescription === healthPlanName)
      .map((data) => {
          return data.planId;
      })[0];

      this.dentalPlanId = this.dentalPlansFromService
      .filter(plan => plan.planDescription === dentalPlanName)
      .map((data) => {
          return data.planId;
      })[0];
  }

  public parseToContactInfo(contactInfo: IContactInfo): IContactDataInfo {
      const phoneNumbers: IPhoneNumberDataInfo[] = [];
      if (contactInfo?.primaryPhone !== undefined ) {
          phoneNumbers.push({
              phoneType: 'Primary',
              number: contactInfo?.primaryPhone
          });
      }

      if (contactInfo?.secondaryPhone !== undefined ) {
          phoneNumbers.push({
              phoneType: 'Secondary',
              number: contactInfo?.secondaryPhone
          });
      }

      return {
          addresses: [{
              addressType: 'Primary',
              address1: contactInfo?.address,
              address2: contactInfo?.addressTwo,
              city: contactInfo?.city,
              state: contactInfo?.state,
              zip : contactInfo?.zipCode,
          } as IAddressInfo],
          phoneNumbers: phoneNumbers.length > 0 ? phoneNumbers : null,
          email: contactInfo?.email
      } as IContactDataInfo;
  }

  public parseToMedicareInformationOfDependent(medicareCoverage: MedicareCoverage): IMedicareDataInfo {
      return {
          isEligible: medicareCoverage.hasMedicareCoverage,
          medicareNumber: medicareCoverage.medicareCardNumber,
          partAEffectiveDate: medicareCoverage?.partAEffectiveDate ?? '',
          partBEffectiveDate: medicareCoverage?.partBEffectiveDate ?? '',
      } as IMedicareDataInfo;
  }

  public parseToProductsOfDependent(
    subscriberBenefitSelection: IBenefitSelection,
    benefitSelection: IBenefitSelection): IProductDataInfo[] {
      this.getPlanID(subscriberBenefitSelection.healthPlan, subscriberBenefitSelection?.dentalPlan);
      const products: IProductDataInfo[] = [];
      if (!!benefitSelection?.healthPlan && benefitSelection?.healthPlan !== 'Waive Coverage' &&
      (benefitSelection?.healthPlan === 'Same as subscriber' && subscriberBenefitSelection?.healthPlan !== 'Waive Coverage')) {
          products.push({
              effectiveDate: this.effectiveDateOfCoverage.toISOString().slice(0, 10),
              contractType: 'MED',
              product: this.healthPlanId,
              accountId: this.accountId,
              requiredStartDate: this.effectiveDateOfCoverage.toISOString().slice(0, 10)
          });
      }
      if (!!benefitSelection?.dentalPlan && benefitSelection?.dentalPlan !== 'Waive Coverage' &&
          (benefitSelection?.dentalPlan === 'Same as subscriber' && subscriberBenefitSelection?.dentalPlan !== 'Waive Coverage')) {
          products.push({
              effectiveDate: this.effectiveDateOfCoverage.toISOString().slice(0, 10),
              contractType: 'DEN',
              product: this.dentalPlanId,
              accountId: this.accountId,
              requiredStartDate: this.effectiveDateOfCoverage.toISOString().slice(0, 10)
          });
      }
      return products.length > 0 ? products : null;
  }

  public parseToMedicareInformationFromForm(benefitSelection: IBenefitSelection): IMedicareDataInfo {
      return {
          isEligible: benefitSelection.medicareCoverage.hasMedicareCoverage,
          medicareNumber: benefitSelection?.medicareCoverage?.medicareCardNumber,
          partAEffectiveDate: benefitSelection?.medicareCoverage?.partAEffectiveDate !== null
          ? new Date(benefitSelection?.medicareCoverage?.partAEffectiveDate).toISOString().slice(0, 10)
          : '',
          partBEffectiveDate: benefitSelection?.medicareCoverage?.partBEffectiveDate !== null
          ? new Date(benefitSelection?.medicareCoverage?.partBEffectiveDate).toISOString().slice(0, 10)
          : '',
      } as IMedicareDataInfo;
  }

  public parseToProductsFromForm(benefitSelection: IBenefitSelection ): IProductDataInfo[] {
      this.getPlanID(benefitSelection?.healthPlan, benefitSelection?.dentalPlan);
      const products: IProductDataInfo[] = [];

      if (!!benefitSelection?.healthPlan && benefitSelection?.healthPlan !== 'Waive Coverage') {
          products.push({
              effectiveDate: this.effectiveDateOfCoverage.toISOString().slice(0, 10),
              contractType: 'MED',
              product: this.healthPlanId,
              cdhProduct: this.cdhpToggle ? benefitSelection.cdhProduct : '',
              accountId: this.accountId,
              requiredStartDate: this.effectiveDateOfCoverage.toISOString().slice(0, 10),
           });
      }
      if (!!benefitSelection?.dentalPlan && benefitSelection?.dentalPlan !== 'Waive Coverage') {
          products.push({
              effectiveDate: this.effectiveDateOfCoverage.toISOString().slice(0, 10),
              contractType: 'DEN',
              product: this.dentalPlanId,
              accountId: this.accountId,
              requiredStartDate: this.effectiveDateOfCoverage.toISOString().slice(0, 10),
          });
      }
      return products.length > 0 ? products : null;
  }

  private parseToBasicEmployeeInfo(employeeData: EmployeeDataForm): IEmployeeInfo {
      return {
          groupNumber: employeeData.accountNumber,
          sectionNumber: employeeData.subAccount,
          employeeId: employeeData.employeeID,
          employmentLocation: employeeData.employeeLocation,
          isMarried: employeeData?.maritalStatus === 'Married' ? true : false,
          marriageDate: !!employeeData?.dateOfMarriage
          ? new Date(employeeData?.dateOfMarriage).toISOString().slice(0, 10)
          : null
      } as IEmployeeInfo;
  }

  private parseToSubscriber(employeeData: IEmployeeForm): ISubscriberDataInfo {
    return {
          isDependent: false,
          lastName: employeeData.employeeInfo?.lastName,
          middleName: employeeData.employeeInfo?.middleName ?? '',
          firstName: employeeData.employeeInfo?.firstName,
          dateOfBirth: new Date(employeeData.employeeInfo.dateOfBirth).toISOString().slice(0, 10),
          ssn: employeeData.employeeInfo?.socialSecurityNumber.replace(/-/g, ''),
          gender: employeeData.employeeInfo?.gender === 'F' ? 'Female' : 'Male',
          medicareInformation: employeeData?.benefitSelection?.medicareCoverage?.hasMedicareCoverage === true
          ? this.parseToMedicareInformationFromForm(employeeData.benefitSelection)
          : null,
          products: this.parseToProductsFromForm(employeeData.benefitSelection),
          maritalStatus: employeeData?.employeeInfo?.maritalStatus ?? null,
          dependent: false,
          loggedInUserId: this.userInfoService.getLoggedInUserId(),
          accountNumber: employeeData.employeeInfo?.accountNumber,
          subaccountNumber: employeeData.employeeInfo?.subAccount
      } as ISubscriberDataInfo;
  }

  public parseToDependentInfo(employeeData: IEmployeeForm): ISubscriberDataInfo[] {
      const dependentDataInfo: ISubscriberDataInfo[] = employeeData.dependentsInfo.map( (dependent) => ({
          isDependent: true,
          dependentType: dependent.relation ?? null,
          lastName: dependent.lastName,
          middleName: dependent?.middleName ?? null,
          firstName: dependent.firstName,
          dateOfBirth: new Date(dependent?.dateOfBirth).toISOString().slice(0, 10),
          ssn: dependent?.ssn?.replace(/-/g, ''),
          gender: dependent.gender === 'F' ? 'Female' : 'Male',
          medicareInformation: dependent.benefitSelection.medicareCoverage.hasMedicareCoverage
          ? this.parseToMedicareInformationOfDependent(dependent.benefitSelection.medicareCoverage)
          : null,
          products: this.parseToProductsOfDependent(employeeData.benefitSelection, dependent.benefitSelection),
          maritalStatus: dependent?.relation === 'Spouse' ? 'Married' : 'Single',
          dependent: true
      }));
      return dependentDataInfo;
  }

  ngOnDestroy(): void {
     this.subscriptions.forEach(s => s.unsubscribe());
  }
}
