import { Injectable } from '@angular/core';
import { BehaviorSubject, EMPTY, Observable } from 'rxjs';
import { IPlanInfo } from '../../models/plan-info';
import { PlansApiService } from './plans-api.service';
import { ICdhPlan } from '../../models/cdhp-plan.model';

@Injectable({
  providedIn: 'root'
})
export class PlansService {
    public cdhpInfo$: BehaviorSubject<ICdhPlan> = new BehaviorSubject<ICdhPlan>(null);
    constructor(private plansApiService: PlansApiService) {}

    public getPlans(accountNumber: string = null, effectiveDate: Date = null): Observable<Array<IPlanInfo>> {
      if (accountNumber && effectiveDate) {
        return this.plansApiService.getPlans(accountNumber, effectiveDate);
      }
      return EMPTY;
    }
}
