import { Component, OnInit } from '@angular/core';
import { BaseCardComponent } from '../base-card/base-card.component';

@Component({
  selector: 'app-add-new-employee-card',
  templateUrl: './add-new-employee-card.component.html',
  styleUrls: ['./add-new-employee-card.component.scss']
})
export class AddNewEmployeeCardComponent extends BaseCardComponent implements OnInit {

  constructor() {
    super();
  }

  ngOnInit(): void {
  }
}
