import { Injectable } from '@angular/core';
import { AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { combineLatest, merge } from 'rxjs';
import { switchMap, map as rxJsMap } from 'rxjs/operators';
import { CachedObservable } from '../../shared/cached-observable';
import { AbstractTSFormControlFactory } from '../factories/type-safe-form-control.factory';
import { AbstractFormControlFactory } from '../reactive-form-wrappers/form-control.builder';
import { TypeGuards } from '../type-guards';
import { AsyncValidatorConfig, ITypeSafeControlConfig, NonObjectTuple } from '../type-safe-form-group';
import { arrayify } from '../type-safe-form-helpers.functions';
import { ITypeSafeControlBuildConfig } from './type-safe-control-build.config';
import { TypeSafeFormControl } from './type-safe-form-control';

@Injectable()
export class TypeSafeFormControlBuilder {
    constructor(
        private formControlFactory: AbstractFormControlFactory,
        private tsControlFactory: AbstractTSFormControlFactory
    ) { }

    public buildTSFormControl<T>(name: string, config: NonObjectTuple<T>): TypeSafeFormControl<T> {
        const resultConfig: ITypeSafeControlBuildConfig<T> = { name };
        this.populateValidators(resultConfig, config);
        this.populateFormControl(resultConfig, config);
        this.populateValidationErrors(resultConfig, config);

        const result: TypeSafeFormControl<T> = this.tsControlFactory.createTSFormControl<T>(
            resultConfig.name,
            resultConfig.formControl,
            resultConfig.validators,
            resultConfig.validationErrors,
            resultConfig.valueChanges,
            resultConfig.enabled
        );

        return result;
    }

    private populateFormControl<T>(resultConfig: ITypeSafeControlBuildConfig<T>, config: NonObjectTuple<T>) {
        this.populateUpdateOn(resultConfig, config);
        resultConfig.formControl = this.formControlFactory.buildFormControl(config[0],
            {
                validators: resultConfig.validators,
                asyncValidators: resultConfig.asyncValidators,
                updateOn: resultConfig.updateOn
            });
    }

    private populateUpdateOn<T>(resultConfig: ITypeSafeControlBuildConfig<T>, config: NonObjectTuple<T>) {
        if (config.length > 1) {
            const validatorOptions = config[1];
            if (TypeGuards.isAbstractControlOptions(validatorOptions)) {
                resultConfig.updateOn = validatorOptions.updateOn;
            }
        }
    }

    private populateValidationErrors<T>(resultConfig: ITypeSafeControlBuildConfig<T>, config: NonObjectTuple<T>) {
        // Merge the async validator configurations from the third tuple item and the control config (if present)
        const asyncValidators: (AsyncValidatorFn | AsyncValidatorConfig)[] = [];
        if (config.length > 2) {
            asyncValidators.push(...arrayify(config[2]));
        }
        if (TypeGuards.isTypeSafeControlConfig(config[1]) && !!config[1].asyncValidators) {
            asyncValidators.push(...arrayify(config[1].asyncValidators));
        }

        if (asyncValidators.length > 0) {
            resultConfig.validationErrors = new CachedObservable<ValidationErrors>(null,
                combineLatest(
                    asyncValidators.map(validator => {
                        if (TypeGuards.isFunction(validator)) {
                            return resultConfig.formControl.valueChanges.pipe(
                                switchMap(_ => validator(resultConfig.formControl))
                            );
                        } else {
                            return merge(
                                arrayify(validator.dependencies)
                            ).pipe(
                                switchMap(_ => validator.asyncValidator(resultConfig.formControl))
                            );
            }
                    })
                ).pipe(
                    rxJsMap(errs => errs
                        .filter(err => !!err)
                        .reduce<ValidationErrors>((aggregate, errors) => ({ ...aggregate, ...errors }), null)
                    )
                )
            );
        }
    }

    private populateValidators<T>(resultConfig: ITypeSafeControlBuildConfig<T>, config: NonObjectTuple<T>) {
        if (config.length > 1) {
            const validatorOptions = config[1];
            if (!validatorOptions) {
                resultConfig.validators = [];
            } else if (Array.isArray(validatorOptions)) {
                // Nested if instead of && to give type system confidence it is not an array in later checks
                if (validatorOptions.length > 0) {
                    resultConfig.validators = validatorOptions;
                }
            } else {
                if (TypeGuards.isFunction(validatorOptions)) {
                    resultConfig.validators = [validatorOptions];
                } else {
                    if (TypeGuards.isTypeSafeControlConfig(validatorOptions)) {
                        this.populateEnabledAndValueChanges(resultConfig, validatorOptions);
                    }
                    resultConfig.validators = arrayify(validatorOptions.validators);
                }
            }
        }
    }

    private populateEnabledAndValueChanges<T>(resultConfig: ITypeSafeControlBuildConfig<T>, validatorOptions: ITypeSafeControlConfig<T>) {
        resultConfig.enabled = !validatorOptions.enabled
                                    ? null
                                    : TypeGuards.isFunction(validatorOptions.enabled)
                                        ? validatorOptions.enabled
                                        : new CachedObservable(false, validatorOptions.enabled);
        resultConfig.valueChanges = validatorOptions.valueChanges;
    }
}
