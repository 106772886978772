import { ValidationErrors } from '@angular/forms';
import { BehaviorSubject, combineLatest, Observable, of, throwError } from 'rxjs';
import { map, mergeMap, startWith } from 'rxjs/operators';
import { CachedObservable } from '../../shared/cached-observable';
import { TypeGuards } from '../type-guards';
import { ReactantEnabledProperty } from '../type-safe-form-group';
import { ITypeSafeFormReactant } from './type-safe-form-reactant.interface';

export abstract class AbstractFormReactant implements ITypeSafeFormReactant {
    private _parent$: BehaviorSubject<ITypeSafeFormReactant> = new BehaviorSubject<ITypeSafeFormReactant>(null);
    public readonly enabled$: Observable<boolean>;
    public get Parent(): ITypeSafeFormReactant { return this._parent$.value; }
    public set Parent(newValue: ITypeSafeFormReactant) { this._parent$.next(newValue); }
    constructor(
        private readonly enabledProperty?: ReactantEnabledProperty
    ) {
        this.enabled$ = this.calculateEnabled$(enabledProperty);
    }
    public enabled(): boolean {
        return (!this.enabledProperty
                    || (TypeGuards.isFunction(this.enabledProperty) ? this.enabledProperty() : this.enabledProperty.value)
                )
                && (!this.Parent || this.Parent.enabled());
    }
    public abstract getPath(): string;
    public abstract getErrors(): ValidationErrors;
    public abstract destroy(): void;

    private calculateEnabled$(enabledProperty: ReactantEnabledProperty): Observable<boolean> {
        if (!enabledProperty) {
            return this._parent$.pipe(mergeMap(parent => parent?.enabled$ ?? of(true)));
        }

        // Only throw an error if someone tries to subscribe to the enabled$ of a field which is not using async validation
        if (!(enabledProperty instanceof CachedObservable)) {
            return throwError(`Error getting enabled$ for ${this.getPath()}: enabledConfig is not of type Observable`);
        }

        return combineLatest([
            this._parent$.pipe(mergeMap(parent => parent?.enabled$ ?? of(true))),
            enabledProperty.value$.pipe(
                // CachedObservable is not guaranteed to emit
                startWith(enabledProperty.value)
            )
        ]).pipe(
            map(([parentEnabled, thisEnabled]) => parentEnabled && thisEnabled)
        );
    }
}
