import { Component, OnInit } from '@angular/core';
import { EmployeeRosterService } from 'src/app/manage-my-group/employee-roster/_common/services/employee-roster-service/employee-roster-service';
import { OrderIdCardApiService } from 'src/app/manage-my-group/id-card/_common/services/order-id-card-api.service';
import { DebuggingService } from './debugging.service';

@Component({
  selector: 'app-debugging',
  templateUrl: './debugging.component.html',
  styleUrls: ['./debugging.component.scss']
})
export class DebuggingComponent implements OnInit {
  private isTenOrLessRecord = false;
  public addressChangeFailure = false;

  constructor(protected employeeRosterService: EmployeeRosterService,
    protected orderIdCardApiService: OrderIdCardApiService,
    protected debuggingService: DebuggingService) {
  }

  ngOnInit(): void {
    this.isTenOrLessRecord = this.employeeRosterService.employeeListSize$.value;
  }

  employeeListSizeChange(): void {
    if (this.isTenOrLessRecord) {
      this.employeeRosterService.mockData = '../../../../../../assets/mockData/TEN_EmployeesRandomGenerated_V2.json';
      this.employeeRosterService.pageSize = 10;
      this.employeeRosterService.employeeListSize$.next(true);
    } else {
      this.employeeRosterService.mockData = '../../../../../../assets/mockData/HUNDRED_Employees_V2.json';
      this.employeeRosterService.employeeListSize$.next(false);
    }
    this.employeeRosterService.getEmployeeRosters();
  }

  addressChangeFailureChange(event: any): void {
    this.debuggingService.orderIdCard.addressChangeFailure = event.target.checked;
  }
}
