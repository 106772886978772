import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TreeViewModel, TreeViewNode } from './tree-view-model';

@Component({
  selector: 'app-tree-multiselect',
  templateUrl: './tree-multiselect.component.html',
  styleUrls: ['./tree-multiselect.component.scss']
})
export class TreeMultiselectComponent {
  @Input() public id: string;
  @Input() public disabled: boolean;
  @Input() public treeViewModel: TreeViewModel;
  @Input() public selectedItems: TreeViewNode | TreeViewNode[];

  @Output() public selectedItemsChange: EventEmitter<any> = new EventEmitter();
}
