import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subscription, Observable, throwError } from 'rxjs';
import { IEmployee } from '../../models';
import { EmployerAccountApiService } from 'src/app/_services/employer-account-service/employer-account-api.service';
import { IAccountSummary } from 'src/app/_models';
import { catchError, tap } from 'rxjs/operators';
import { AppConstants } from 'src/app/_common/shared/app.constants';
import { RosterDataRequest } from 'src/app/manage-my-group/_common/models/roster-data-request.interface';

@Injectable({
    providedIn: 'root'
})
export class EmployerRosterApiService implements OnDestroy {
    private subscription: Subscription = new Subscription();
    private _accountId: string;
    public rosterData$: BehaviorSubject<Array<IEmployee>> = new BehaviorSubject<Array<IEmployee>>([]);

    constructor(private http: HttpClient, public employerAccountApiService: EmployerAccountApiService) {
        this.subscription = this.employerAccountApiService.accountSummary$.subscribe(
            (accountSummary: IAccountSummary) => {
                if (accountSummary?.accountId != null) {
                    this._accountId = accountSummary.accountId;
                }
            }
        );
    }

    public getEmployeeRosters(): Observable<Array<IEmployee>> {
        const url = 'api/EmployeeRoster/GetSubscriberData';
        const subAccounts = localStorage.getItem(AppConstants.USER_OKTA_ASSIGNED_SUBACCOUNTS);
        const request: RosterDataRequest = {
            accountId: this._accountId,
            subAccounts: subAccounts !== 'null' ? subAccounts : null
        };
        return this.http.post<Array<IEmployee>>(url, request).pipe(
            tap((rosterData: Array<IEmployee>) => {
                this.rosterData$.next(rosterData);
            }), catchError(this.handleError)
        );
    }

    private handleError(error: any): Observable<never> {
        const errorMessage = (error.message)
        ? error.message
        : error.status
            ? `${error.status} - ${error.statusText}`
            : 'Server error';
        return throwError(errorMessage);
    }

    ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }
}
