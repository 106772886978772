import { Injectable, OnDestroy } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ReactantEnabledProperty, TypeSafeFormGroupConfig } from './type-safe-form-group-config';
import { TypeSafeFormGroup } from './type-safe-form-group';
import { Subscription } from 'rxjs';
import { TypeSafeFormControlBuilder } from '../type-safe-form-control/type-safe-form-control.builder';
import { OnMonad, OnMonadContext } from '../../shared/observables-functions/observable.functions';
import { ITypeSafeFormReactant } from '../type-safe-form-reactant/type-safe-form-reactant.interface';

@Injectable()
export class TypeSafeFormGroupBuilder<T> implements OnDestroy {
    private monadContext: OnMonadContext<T>;
    private readonly subscriptions: Subscription[] = [];
    private readonly createdGroups: TypeSafeFormGroup<T>[] = [];
    constructor(
        public formBuilder: FormBuilder,
        private tsFormControlBuilder: TypeSafeFormControlBuilder
    ) { }
    public createOnMonad(): OnMonad<T> {
        if (!this.monadContext) {
            this.monadContext = new OnMonadContext();
        }
        return this.monadContext.createOnMonad();
    }
    public group(controlsConfig: TypeSafeFormGroupConfig<T>): TypeSafeFormGroup<T> {
        const result = this.subgroup<T>(controlsConfig);
        result.name = 'root';
        this.finalizeRoot(result);
        return result;
    }
    public subgroup<K>(typeSafeConfig: TypeSafeFormGroupConfig<K>, enabledConfig: ReactantEnabledProperty = null): TypeSafeFormGroup<K> {
        const reactiveGroupConfig: TypeSafeFormGroupConfig<K> = {} as TypeSafeFormGroupConfig<K>;
        const children: Map<keyof K, ITypeSafeFormReactant> = new Map<keyof K, ITypeSafeFormReactant>();
        (Object.keys(typeSafeConfig) as (keyof K)[])
            .map(key => ({ key, value: typeSafeConfig[key.toString()] })).forEach(({ key, value }) => {
                if (value instanceof TypeSafeFormGroup) {
                    value.name = key.toString();
                    reactiveGroupConfig[key.toString()] = value.form;
                    children.set(key, value);
                } else {
                    const tsFormControl = this.tsFormControlBuilder.buildTSFormControl(key.toString(), value);
                    children.set(key, tsFormControl);
                    reactiveGroupConfig[key.toString()] = tsFormControl.formControl;
                }
            });
        const result = new TypeSafeFormGroup<K>(
            this.formBuilder.group(reactiveGroupConfig),
            children,
            enabledConfig
        );
        return result;
    }
    public ngOnDestroy(): void {
        this.subscriptions.forEach(s => s.unsubscribe());
        this.createdGroups.forEach(g => g.destroy());
    }
    private finalizeRoot(result: TypeSafeFormGroup<T>) {
        this.subscriptions.push(result.form.valueChanges.subscribe(_ => result.getErrors()));
        this.resolveBuildingContext(result);
        this.createdGroups.push(result);
    }
    private resolveBuildingContext(result: TypeSafeFormGroup<T>): void {
        this.monadContext?.resolve(result);
        this.monadContext = null;
    }
}
