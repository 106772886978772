import { Injectable } from '@angular/core';
import { IEmployeeForm } from 'src/app/manage-my-group/add-employee/standard-application/_common/models';
import { ISavedEmployeeApplication } from '../models/saved-applications.interface';
import { SitecoreContentService } from 'src/app/_services/sitecore-content-service/sitecore-content.service';

@Injectable({
  providedIn: 'root'
})
export class SavedApplicationsContentService {
  private cdhpToggle: boolean;

  constructor(
    private sitecoreContentService: SitecoreContentService
  ) {
    this.sitecoreContentService.getSiteCoreToggle('CdhpToggle').subscribe((toggle: boolean) => {
      this.cdhpToggle = toggle;
    });
  }

  public parseToEmployeeForm(applicationJson: string): IEmployeeForm {
    if (applicationJson) {
      return JSON.parse(applicationJson) as IEmployeeForm;
    }
  }

  public modifySaveAppObject(savedAppArray: ISavedEmployeeApplication[]): ISavedEmployeeApplication[] {
    savedAppArray.forEach(item => {
      item.cdhProduct = this.cdhpToggle ? this.parseToEmployeeForm(item.applicationJson)?.benefitSelection?.cdhProduct : '';
    });
    return savedAppArray;
  }
}
