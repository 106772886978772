import { IUserInformation } from 'src/app/_models/userInformation.interface';
import { Injectable } from '@angular/core';
import { AppConstants } from '../../_common/shared/app.constants';
import { OktaAuthService } from '@okta/okta-angular';
import { AppOktaApiService } from '../app-okta-api-service/app-okta-api.service';
import { forkJoin, from, Observable, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UserInfoService {
  private userRoles: Array<string> = [];

  private userRoleList = [
    'Employer_OfficeManager',
    'Employer_BillPay',
    'Employer_ViewOnly',
    'Employer_MemberManagement',
    'Employer_GBA',
    'Broker Portal Group', // TODO: Make this better for team elevate; MLG 20230125
    'Employer_AZBeacon'
  ];

  public oumAccessRoleList = [
    'Employer_OfficeManager',
    'Employer_GBA'
  ];

  constructor(
    private oktaAuthService: OktaAuthService,
    private bcbsazOktaApiService: AppOktaApiService
  ) { }

  public async setUserInfoLocalStorage(): Promise<void> {
    const isAuthenticated = await this.oktaAuthService.session.exists();
    if (isAuthenticated) {
      await this.oktaAuthService?.token?.getUserInfo()
        .then((user) => {
          localStorage.setItem(AppConstants.USER_INFO_DETAILS, JSON.stringify(user));
        }).catch((err) => {
          console.log(err);
        });
    }
  }

  public updateOktaUserInfo(): Observable<boolean> {
    let userInfo = this.getLocalStorageInfo(AppConstants.USER_INFO_DETAILS);
    if (userInfo) {
      return forkJoin([
        this.updateOktaUserGroup(userInfo?.preferred_username),
        this.updateOktaAccount(userInfo?.preferred_username).pipe(
          mergeMap(_ => {
            return from(this.setUserInfoLocalStorage()).pipe(map(__ => true))
              .pipe(
                mergeMap(___ => {
                  userInfo = this.getLocalStorageInfo(AppConstants.USER_INFO_DETAILS);
                  return this.updateUserOrganizationIdInOkta(userInfo?.accounts, userInfo?.preferred_username);
                })
              );
          }))
      ]).pipe(
        mergeMap(bools => {
          if (bools.every(bool => bool)) {
            return from(this.setUserInfoLocalStorage()).pipe(map(_ => true));
          } else {
            return of(false);
          }
        }));
    } else {
      return of(false);
    }
  }

  updateOktaUserGroup(username: string): Observable<boolean> {
    const roles = this.getUserRole();
    if (!roles?.length) {
      if (roles.find(role => role === 'Broker Portal Group')) {
        return of(true);
      }
      return this.bcbsazOktaApiService.updateGroupInOkta(username);
    } else {
      return this.bcbsazOktaApiService.updateEmployerUserRole(username);
    }
  }

  updateOktaAccount(username: string): Observable<boolean> {
    const accounts = this.getUserAccount();
    if (!accounts || accounts === '') {
      return this.bcbsazOktaApiService.updateAccountInOkta(username);
    } else {
      return of(true);
    }
  }

  updateUserOrganizationIdInOkta(accountNumber: string, username: string): Observable<boolean> {
    const organizationId = this.getUserOrganizationId();
    if ((!organizationId || organizationId === '') && (accountNumber && username)) {
      return this.bcbsazOktaApiService.updateOrganizationIdInOkta(accountNumber, username);
    } else {
      return of(true);
    }
  }

  getUserRole(): Array<string> {
    const userInfo = this.getLocalStorageInfo(AppConstants.USER_INFO_DETAILS);
    if (userInfo) {
      this.userRoles = [];

      const filteredUserRoles = userInfo?.employerGroups?.filter((item: string) => {
        return this.userRoleList.includes(item);
      });

      if (filteredUserRoles && filteredUserRoles.length > 0) {
        filteredUserRoles.forEach(role => this.userRoles.push(role));
      }
    }
    return this.userRoles;
  }

  getUserAccount(): string {
    const userInfo = this.getLocalStorageInfo(AppConstants.USER_INFO_DETAILS);
    if (userInfo) {
      return userInfo?.accounts;
    }
    return '';
  }

  getUserOrganizationId(): string {
    const userInfo = this.getLocalStorageInfo(AppConstants.USER_INFO_DETAILS);
    if (userInfo && userInfo?.organizationId) {
      return userInfo?.organizationId;
    }
    return '';
  }

  getLoggedInUserId(): string {
    const userInfo = this.getLocalStorageInfo(AppConstants.USER_INFO_DETAILS);
    if (userInfo && userInfo?.preferred_username) {
      return userInfo?.preferred_username;
    }
    return '';
  }

  getLocalStorageInfo(infoKey: string): IUserInformation {
    const storage = localStorage.getItem(infoKey);
    if (storage) {
      return JSON.parse(storage);
    }
    return null;
  }
}
