import { SortDirection } from 'src/app/manage-my-group/employee-roster/_common/directives/sortable.directive';
import { Subject } from 'rxjs';
import { SortConfig } from './sort-config';
import { IPipe } from '../pipe.interface';

interface SortState<T> {
    column: keyof T;
    direction: SortDirection;
}

export class SortPipe<T, K> implements IPipe<Array<T>> {
    private sortState: SortState<K>;

    constructor(
        private dirtyNotifier: Subject<boolean>,
        private config: SortConfig<T, K>
    ) { }

    public setState(sortState: SortState<K>): void {
        this.sortState = !!sortState?.direction ? sortState : null;
        this.dirtyNotifier.next(true);
    }

    public clear(): void { this.setState(null); }

    public process(input: T[]): T[] {
        if (!this.sortState) {
            return input;
        }

        const fieldConfig = this.config[this.sortState.column];
        const getter = fieldConfig.getter;
        const dir = this.sortState.direction === 'asc' ? 1 : -1;
        const sortLambda = (a: T, b: T) => dir * fieldConfig.comparetor(getter(a), getter(b));

        // Sort, do not mutate source array
        return [...input].sort(sortLambda);
    }
}
