import { Subject } from 'rxjs';
import { IPipe } from '../pipe.interface';

const DEFAULT_PAGE = 1;
const DEFAULT_PAGE_SIZE = 10;
export class PaginationPipe<T> implements IPipe<Array<T>> {
    private _page = DEFAULT_PAGE;
    private _pageSize = DEFAULT_PAGE_SIZE;
    public get page() { return this._page; }
    public set page(value: number) {
        this._page = value;
        this.dirtyNotifier.next(true);
    }
    public get pageSize() { return this._pageSize; }
    public set pageSize(value: number) { this._pageSize = value; this.dirtyNotifier.next(true); }

    constructor(
        private dirtyNotifier: Subject<boolean>
    ) { }

    public clear(): void {
        this.page = DEFAULT_PAGE;
        this.pageSize = DEFAULT_PAGE_SIZE;
    }

    public process(input: T[]): T[] {
        if (input.length < this.pageSize) {
            return input;
        }

        const count = input.length;
        // Don't slice past the end of the array
        // TODO: Update page if there's not as many results as there were when page was selected?
        // But how do we guarantee there isn't an infinite loop?
        const start = (this.page - 1) * this.pageSize;
        const end = start + this.pageSize;

        const result = input.slice(start, end);
        return result;
    }
}
