import { IEmployee } from 'src/app/manage-my-group/employee-roster/_common/models';
import { combineLatest, merge, Observable, of, Subject } from 'rxjs';
import { SearchConfig } from './pipes/search-config';
import { SearchResults } from './search-results.interface';
import { Functions } from './pipe.functions';
import { EmployeeAsyncSource } from './AsyncEmployeeSource';
import { EmployeeIDCardGridSortStructure, EmployeeIDCardGridSearchStructure, EmployeeIDCardGridFilterStructure } from './employee-id-card-grid.structure';
import { Injectable } from '@angular/core';
import { SortConfig } from './pipes/sort-config';
import { FilterConfig } from './pipes/filter-config';
import { SearchPipe } from './pipes/search-pipe';
import { FilterPipe } from './pipes/filter-pipe';
import { SortPipe } from './pipes/sort-pipe';
import { PaginationPipe } from './pipes/pagination-pipe';
import { debounceTime, map } from 'rxjs/operators';
import { Employee } from 'src/app/manage-my-group/employee-roster/_common/models/employee.functions';
import { TreeViewNode } from '../custom-controls/tree-multiselect/tree-view-model';

@Injectable({
  providedIn: 'root'
})
export class EmployeeIDGridPipeline {
  private _results$: Observable<SearchResults<IEmployee>>;
  private _searchPipe: SearchPipe<IEmployee, EmployeeIDCardGridSearchStructure>;
  private _filterPipe: FilterPipe<IEmployee, EmployeeIDCardGridFilterStructure>;
  private _sortPipe: SortPipe<IEmployee, EmployeeIDCardGridSortStructure>;
  private _paginationPipe: PaginationPipe<IEmployee>;

  public get results$(): Observable<SearchResults<IEmployee>> { return this._results$; }
  public get searchPipe(): SearchPipe<IEmployee, EmployeeIDCardGridSearchStructure> { return this._searchPipe; }
  public get filterPipe(): FilterPipe<IEmployee, EmployeeIDCardGridFilterStructure> { return this._filterPipe; }
  public get sortPipe(): SortPipe<IEmployee, EmployeeIDCardGridSortStructure> { return this._sortPipe; }
  public get paginationPipe(): PaginationPipe<IEmployee> { return this._paginationPipe; }

  constructor(employeeSource: EmployeeAsyncSource) {
    const filterConfig: FilterConfig<IEmployee, EmployeeIDCardGridFilterStructure> = this.createFilterConfig();
    const sortConfig: SortConfig<IEmployee, EmployeeIDCardGridSortStructure> = this.createSortConfig();
    const searchConfig: SearchConfig<IEmployee, EmployeeIDCardGridSearchStructure> = this.createSearchConfig();

    this.buildPipeline(searchConfig, filterConfig, sortConfig, employeeSource);
  }

  private createSearchConfig(): SearchConfig<IEmployee, EmployeeIDCardGridSearchStructure> {
    return {
      name: {
        stringifier: Employee.nameStringifier,
      },
      dentalId: {
        stringifier: Functions.stringifierForKey('dentalId')
      },
      medicalId: {
        stringifier: Functions.stringifierForKey('medicalId')
      },
      hccSubscriberId: {
        stringifier: Functions.stringifierForKey('hccSubscriberId')
      },
      socialSecurityNumber: {
        stringifier: Functions.stringifierForKey('socialSecurityNumber')
      },
      completeName: {
        stringifier: Employee.completeName
      }
    };
  }

  private createSortConfig(): SortConfig<IEmployee, EmployeeIDCardGridSortStructure> {
    return {
      name: {
        getter: Employee.nameStringifier,
        comparetor: Functions.defaultSortComparetor()
      }
    };
  }

  private createFilterConfig(): FilterConfig<IEmployee, EmployeeIDCardGridFilterStructure> {
    return {
      eligibilityDate: {
        matcher: Employee.eligibilityDateMatcher
      },
      subAccounts: {
          matcher: (item: IEmployee, filterValue: TreeViewNode[]) =>
              Employee.subAccountMatcher(item, filterValue.map(treeNode => treeNode.value))
      }
    };
  }

  private buildPipeline(
    searchConfig: SearchConfig<IEmployee, EmployeeIDCardGridSearchStructure>,
    filterConfig: FilterConfig<IEmployee, EmployeeIDCardGridFilterStructure>,
    sortConfig: SortConfig<IEmployee, EmployeeIDCardGridSortStructure>,
    employeeSource: EmployeeAsyncSource) {
    const debouncedDirtyNotifier = new Subject<boolean>();
    const immediateDirtyNotifier = new Subject<boolean>();

    this._searchPipe = new SearchPipe(debouncedDirtyNotifier, searchConfig);
    this._filterPipe = new FilterPipe(debouncedDirtyNotifier, filterConfig);
    this._sortPipe = new SortPipe(debouncedDirtyNotifier, sortConfig);
    this._paginationPipe = new PaginationPipe<IEmployee>(immediateDirtyNotifier);

    this._results$ = combineLatest([
      merge(
        of(true),
        immediateDirtyNotifier,
        debouncedDirtyNotifier.pipe(debounceTime(500))
      ),
      employeeSource.result$
    ]).pipe(
      map(([_, result]) => result),
      map(input => this.searchPipe.process(input)),
      map(input => this.filterPipe.process(input)),
      map(input => this.sortPipe.process(input)),
      map(input => {
        return {
          results: this.paginationPipe.process(input),
          count: input.length,
          searchText: this.searchPipe.searchText
        };
      })
    );
  }

  clearFilters(): void {
    this.searchPipe.clear();
    this.filterPipe.filterValues.clear();
    this.sortPipe.clear();
    this.paginationPipe.clear();
  }
}
