import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-nurse-on-call',
  templateUrl: './nurse-on-call.component.html',
  styleUrls: ['./nurse-on-call.component.scss']
})
export class NurseOnCallComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
