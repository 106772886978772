import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TreeSelectModalModule } from './tree-select-modal-component/tree-select-modal.module';

@NgModule({
    imports: [
        TreeSelectModalModule
    ],
    declarations: [
    ]
})
export class InjectableComponentsModule {}
