import { Component, OnInit, OnDestroy, Inject, ViewChild, ViewContainerRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IButtonContainerOptions, IButtonOptions } from './models/button-options';
import { ITitleOptions } from '../../modal.service';

@Component({
  templateUrl: './option-select-modal-base.component.html'
 })
export class OptionSelectModalBaseComponent implements OnInit, OnDestroy {
  subscriptions: Subscription[] = [];
  public buttonContainer: IButtonContainerOptions;
  public showCloseButton: boolean;
  public bodyContainer: IBodyContainerOptions;

  @ViewChild('content', {read: ViewContainerRef}) contentSpan: ViewContainerRef;

  constructor(
    public dialogRef: MatDialogRef<OptionSelectModalBaseComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IOptionSelectModalBaseDialogData) {
    this.buttonContainer = data.buttonContainer;
    this.showCloseButton = data.showCloseButton;
    this.bodyContainer = data.bodyContainer;
  }

  onClickButton(button: IButtonOptions): void {
    if (button.onClick) {
      button.onClick(this.dialogRef);
    } else if (button.clickValue) {
      this.dialogRef.close(button.clickValue);
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}

export interface IOptionSelectModalBaseDialogData {
  bodyContainer?: IBodyContainerOptions;
  buttonContainer?: IButtonContainerOptions;
  showCloseButton?: boolean;
}

export interface IBodyContainerOptions {
  iconPath?: string;
  title?: ITitleOptions;
  style?: string;
}
