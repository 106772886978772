import { Injectable } from '@angular/core';
import { AppConstants } from '../shared/app.constants';
import { OktaAuthService } from '@okta/okta-angular';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { EmployerAccountService } from '../../_services/employer-account-service/employer-account.service';
import { SpinnerService } from '../services/spinner.service';
import { IAccountSummary } from 'src/app/_models';
import { UserActivityService } from '../services/user-activity.service';
import { UserWelcomeService } from 'src/app/landingpage/welcome/user-welcome.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public isAuthenticated$ = new BehaviorSubject<boolean>(false);

  constructor(private router: Router,
    private oktaAuthService: OktaAuthService,
    private userActivityService: UserActivityService,
    private employerAccountService: EmployerAccountService,
    private spinnerService: SpinnerService,
    private userWelcomeService: UserWelcomeService) { }

  async loginUser(username: string, password: string): Promise<void> {
    try {
      this.spinnerService.showProgressSpinner();

      const transaction = await this.oktaAuthService.signInWithCredentials({ username, password });
      if (transaction?.status === 'SUCCESS') {
        const { sessionToken } = transaction;
        const res = await this.oktaAuthService.token.getWithoutPrompt({
          responseType: 'access_token',
          sessionToken
        });

        this.oktaAuthService.tokenManager.setTokens(res.tokens);
        this.isAuthenticated$.next(true);
        this.saveInfoToLocalStorage(AppConstants.USER_OKTA_TRANSACTION_INFO, transaction);

        this.router?.navigate(['/home']);
      }
    } finally {
      this.spinnerService.hideProgressSpinner();
    }
  }

  async redirectloginUser(): Promise<void> {
    try {
      this.spinnerService.showProgressSpinner();
      const res = await this.oktaAuthService.token.getWithoutPrompt();
      this.oktaAuthService.tokenManager.setTokens(res.tokens);
      await this.checkAuthenticated().then(isAuthenticated => {
        if (isAuthenticated) {
          this.userActivityService.refreshUserActive();
          this.router?.navigate(['/home']);
        }
      });
    } finally {
      this.spinnerService.hideProgressSpinner();
    }
  }

  public getCookie(name: string): string | null {
    const nameLenPlus = (name.length + 1);
    return document.cookie
      .split(';')
      .map(c => c.trim())
      .filter(cookie => {
        return cookie.substring(0, nameLenPlus) === `${name}=`;
      })
      .map(cookie => {
        return decodeURIComponent(cookie.substring(nameLenPlus));
      })[0] || null;
  }

  async checkAuthenticated(): Promise<boolean> {
    const authenticated = await this.oktaAuthService.session.exists();
    this.isAuthenticated$.next(authenticated);
    const userInfo = this.getLocalStorageInfo(AppConstants.USER_INFO_DETAILS);
    if (userInfo) {
      this.employerAccountService.setAccountSummary(userInfo?.given_name, userInfo?.accounts);
    }
    return authenticated;
  }

  public populateAccountSummary(): Observable<IAccountSummary> {
    const userInfo = this.getLocalStorageInfo(AppConstants.USER_INFO_DETAILS);
    if (userInfo) {
      return this.employerAccountService.setAccountSummary(userInfo?.given_name, userInfo?.accounts);
    } else {
      throw new Error('User information not found.');
    }
  }

  async logout(): Promise<void> {
    try {
      const isAuthenticated = await this.oktaAuthService.isAuthenticated();
      if (isAuthenticated) {
        this.isAuthenticated$.next(false);
        await this.oktaAuthService.signOut();
      }
    } catch (error) {
      console.error('Error logging out:  ' + error);
    } finally {
      localStorage.clear();
      this.userWelcomeService.saveCount();
    }
  }

  saveInfoToLocalStorage(storageReferenceName: string, infoToSave: any): void {
    if (storageReferenceName && infoToSave) {
      localStorage.setItem(storageReferenceName, JSON.stringify(infoToSave));
    }
  }

  getLocalStorageInfo(infoKey: string): any {
    const storage = localStorage.getItem(infoKey);
    if (storage) {
      return JSON.parse(storage);
    }
  }
}
