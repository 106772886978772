import { HttpClient } from '@angular/common/http';
import { INavigationHeaderItem } from '../../_models';
import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { UserInfoService } from '../user-info-service/userInfo.service';
import { tap } from 'rxjs/operators';
import { AppConstants } from 'src/app/_common/shared/app.constants';

@Injectable({
  providedIn: 'root'
})
export class NavigationContentService {
  constructor(
    private http: HttpClient,
    private userInfoService: UserInfoService
    ) { }

  public navigationHeaderContent$ = new BehaviorSubject<Array<INavigationHeaderItem>>(null);
  public footerContent$ = new BehaviorSubject<Array<INavigationHeaderItem>>(null);
  public myAccountDropdownitems$ = new BehaviorSubject<Array<INavigationHeaderItem>>(null);

  updateNavigationBarContent(): Observable<INavigationHeaderItem[]> {
    const azbeaconUser = this.getLocalStorageInfo(AppConstants.AZBEACON_COOKIE_KEY);
    const roles = azbeaconUser !== 'true' ? this.userInfoService.getUserRole() : ['Employer_AZBeacon'];
    return this.http.post<INavigationHeaderItem[]>(`api/navigationitem/NavBar`, roles).pipe(
      tap(data => this.navigationHeaderContent$.next(data))
    );
  }

  public getMyAccountDropdown(): Observable<Array<INavigationHeaderItem>> {
    return this.http.get<Array<INavigationHeaderItem>>(`api/navigationitem/myAccountDropdown`)
    .pipe(
      tap(data => this.myAccountDropdownitems$.next(data))
    );
  }

  public getFooterContent(): Observable<Array<INavigationHeaderItem>> {
    return this.http.get<Array<INavigationHeaderItem>>(`api/navigationitem/footer`).pipe(
      tap(data => this.footerContent$.next(data))
    );
  }

  getLocalStorageInfo(infoKey: string): any {
    const storage = localStorage.getItem(infoKey);
    if (storage) {
      return JSON.parse(storage);
    }
  }
}
