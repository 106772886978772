import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, fromEvent, merge, Observable, Subscription, timer } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, skipUntil, throttleTime } from 'rxjs/operators';
import { AppConstants } from '../shared/app.constants';
import { environment } from 'src/environments/environment';
import { NavigationStart, Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class UserActivityService implements OnDestroy {
    subscriptions: Subscription[] = [];
    private readonly refreshSeconds = (environment?.userInactiveMinutes || 18) * 60;
    public readonly throttledUserActivity$: BehaviorSubject<Event> = new BehaviorSubject<Event>(null);
    public readonly isUserActive$: Observable<boolean>;
    private readonly isUserActiveNonDistinct$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(this.isUserActive);

    constructor(private router: Router) {
        this.isUserActive$ = this.isUserActiveNonDistinct$.pipe(distinctUntilChanged());
        this.subscriptions.push(merge(
            fromEvent(document, 'mousemove'),
            fromEvent(document, 'mousedown'), fromEvent(document, 'mouseup'),
            fromEvent(document, 'keydown'), fromEvent(document, 'keyup'),
            this.router.events.pipe(filter(routerEvent => routerEvent instanceof NavigationStart))
        ).pipe(
            // Skip the first second of activity.
            // This prevents the service from saying the user is active while the auth guard is checking canActivate
            skipUntil(timer(1000)),
            throttleTime(1000),
        ).subscribe(this.refreshUserActive.bind(this)));

        this.subscriptions.push(this.throttledUserActivity$.pipe(
            debounceTime(this.refreshSeconds * 1000)
        ).subscribe(_ => this.isUserActiveNonDistinct$.next(false)));
    }

    get isUserActive(): boolean {
        const localStoreObject = this.getLocalStoreObject();
        const lastActivityTime = new Date(localStoreObject.lastActivityTime).getTime();
        const timeSinceLastActivity = Date.now() - lastActivityTime;

        return timeSinceLastActivity < this.refreshSeconds * 1000;
    }

    refreshUserActive() {
        const storage = this.getLocalStoreObject();
        storage.lastActivityTime = new Date().toLocaleString();

        this.setLocalStoreObject(storage);
        this.throttledUserActivity$.next(null);
        this.isUserActiveNonDistinct$.next(true);
    }

    getLocalStoreObject(): IUserActivityLocalStore {
        return (JSON.parse(localStorage.getItem(AppConstants.USER_ACTIVITY_DETAILS)) || {}) as IUserActivityLocalStore;
    }

    setLocalStoreObject(storage: IUserActivityLocalStore) {
        localStorage.setItem(AppConstants.USER_ACTIVITY_DETAILS, JSON.stringify(storage));
    }

    ngOnDestroy(): void {
      this.subscriptions.forEach((subscr) => { subscr.unsubscribe(); });
    }
}

interface IUserActivityLocalStore {
    lastActivityTime?: string;
}
