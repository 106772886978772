import { FormGroup, ValidationErrors } from '@angular/forms';
import { TypeSafeFormControl } from '../type-safe-form-control';
import { AbstractFormReactant } from '../type-safe-form-reactant/abstract-form-reactant';
import { ITypeSafeFormReactant } from '../type-safe-form-reactant/type-safe-form-reactant.interface';
import { ReactantEnabledProperty, TypedControlGroup } from './type-safe-form-group-config';

export class TypeSafeFormGroup<T> extends AbstractFormReactant {
    public name: string;
    private _children: TypedControlGroup<T> = null;
    constructor(
        public readonly form: FormGroup,
        private readonly childMap: Map<keyof T, ITypeSafeFormReactant>,
        enabledProperty?: ReactantEnabledProperty
    ) {
        super(enabledProperty);
        this.childMap?.forEach(child => child.Parent = this);
    }

    get children(): TypedControlGroup<T> {
        // Cache children because this function will get called in the HTML
        // WARNING: we can only do this because childMap is readonly
        this._children = this._children || this.destructureChildren();
        return this._children;
    }

    getPath(): string {
        return !!this.Parent
            ? this.Parent.getPath() + `.${this.name}`
            : this.name;
    }

    public patchValue(value: Partial<T>): void {
        Object.keys(value).forEach(key => {
            const child = this.childMap.get(key as keyof T);
            if (child instanceof TypeSafeFormGroup || child instanceof TypeSafeFormControl) {
                const childPatch = value[key.toString()];
                child.patchValue(childPatch);
            }
        });
    }

    get Value(): T {
        return this.form.getRawValue() as T;
    }

    get valid(): boolean {
        return this.form.valid;
    }

    public getErrors(): ValidationErrors {
        let result: ValidationErrors = null;
        this.childMap.forEach((child, key) => {
            const errors = child.getErrors();
            if (errors != null) {
                result = {
                    ...result,
                    [key]: errors
                };
            }
        });

        // Checking this after getting children errors allows them to update their FormControls and make a similar check
        if (!this.enabled()) {
            result = null;
        }
        this.form.setErrors(result);

        return result;
    }

    public destroy(): void {
        Object.keys(this.children)
            .map(key => this.children[key])
            .forEach(child => child.destroy());
    }

    private destructureChildren(): TypedControlGroup<T> {
        const result = {};
        this.childMap.forEach((child, key) => {
            if (child instanceof TypeSafeFormGroup) {
                result[key.toString()] = child.children;
            } else if (child instanceof TypeSafeFormControl) {
                result[key.toString()] = child;
            }
        });
        return <TypedControlGroup<T>>result;
    }
}
