import { Component, Injectable, Type } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { IButtonOptions } from './modals/option-select-modal/models/button-options';
import { DynamicContentOptionSelectModalComponent, IDynamicContentOptionSelectDialogData } from './modals/option-select-modal/dynamic-option-select-modal/dynamic-option-select-modal.component';
import deepCopy from '../../shared/helper.functions';
import { ModalConstants } from './modal.constants';
import { ModalBuilderTargetComponent } from './modals/injectable-components/modal-builder-target/modal-builder-target.component';
import { ComponentBuilderConfig } from './modals/injectable-components/modal-builder-target/component-builder.config';

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  private readonly matDialogConfig: MatDialogConfig = {
    minWidth: '550px',
    minHeight: '311px',
    disableClose: true,
    panelClass: ['option-select-dialog-container']
  };

  private readonly defaultButtonContainer = {
    buttons: [],
    style: 'justify-content: flex-end; margin-top: 29px'
  };
  private readonly dialogDataDefault: IDynamicContentOptionSelectDialogData<any> = {
    bodyContainer: {
      iconPath: '',
      title: { text: '' },
      style: 'text-align: left, font-family: Roboto-Regular, font-size: 23px, line-height: 32px, color: #2a2a2a, margin: 12px 76px 20px 76px'
    },
    buttonContainer: this.defaultButtonContainer,
    componentType: ParagraphComponent,
    onComponentCreate: (component: ParagraphComponent) => {
      component.text = '';
      component.style = '';
    },
    showCloseButton: false,
  };

  // #### Component Builder defaults #### //
  defaultDialogConfig: MatDialogConfig = {
    minWidth: '550px',
    minHeight: '284px',
    disableClose: false,
    panelClass: ['modal-builder-dialog-container']
  };
  defaultBuilderConfig: ComponentBuilderConfig = {
    closeOptions: {
      showCloseButton: true
    },
    bodyContainer: {
      icon: { path: ModalConstants.warning_icon },
      title: {
        ngStyle: {
          'text-align': 'left',
          'font-family': 'Roboto-Regular',
          'font-size': '23px',
          'line-height': '27px',
          'color': '#2a2a2a',
          'margin': '12px 76px 20px 76px'
        }
      },
      paragraph: {
        ngStyle: {
          'text-align': 'center',
          'font-family': 'Roboto-Regular',
          'font-size': '16px',
          'line-height': '24px',
          'color': '#555555',
          'margin': '12px 76px 20px 76px'
        },
        components: []
      }
    },
    buttonContainer: {
      ngStyle: {
        'justify-content': 'space-around',
        'margin': '29px 76px 0px 76px'
      },
      buttons: []
    }
  };

  constructor(private matDialog: MatDialog) { }

  public createCustomModal(config: ComponentBuilderConfig, dialogConfig?: MatDialogConfig) {
    const dialogStuff = {
      ...deepCopy(this.defaultDialogConfig, dialogConfig),
      data: { ...deepCopy(this.defaultBuilderConfig, config) }
    };
    return this.matDialog.open(
      ModalBuilderTargetComponent,
      dialogStuff
    );
  }

  createDynamicConfirmationModal<T>(title: string,
    component: Type<T>,
    onComponentCreate: (c: T) => void,
    confirmButtonText: string = 'Yes',
    dismissButtonText: string = 'No') {
    const dialogData: IDynamicContentOptionSelectDialogData<any> = {
      bodyContainer: {
        title: { text: title },
      },

      componentType: component,
      onComponentCreate: onComponentCreate,
      buttonContainer: {
        ...this.defaultButtonContainer,
        buttons: [
          this.createDefaultPrimaryButton(confirmButtonText),
          this.createDefaultCancelButton(dismissButtonText)
        ]
      }
    };

    return this.createDynamicOptionsModal(dialogData);
  }

  createTextConfirmationModal(title: string, content: string, confirmButtonText: string = 'Yes', dismissButtonText: string = 'No') {
    return this.createTextOptionsModal(title, content, [
      this.createDefaultCancelButton(dismissButtonText),
      this.createDefaultPrimaryButton(confirmButtonText)
    ]);
  }

  createTextOptionsModal(title: string, content: string, buttons: IButtonOptions[]) {
    const dialogData: IDynamicContentOptionSelectDialogData<ParagraphComponent> = {
      bodyContainer: {
        title: { text: title },
      },
      componentType: ParagraphComponent,
      onComponentCreate: (component: ParagraphComponent) => {
        component.text = content;
        component.style = 'text-align: center';
      },
      buttonContainer: {
        buttons
      }
    };
    return this.createDynamicOptionsModal(dialogData);
  }

  createParagraphOptionsModal(dialogData: IDynamicContentOptionSelectDialogData<any>,
    paragraphOptions: IParagraphOptions = { text: '', style: '' },
    matDialogConfig: MatDialogConfig = this.matDialogConfig) {
    const dialogOptions = {
      ...dialogData,
      componentType: ParagraphComponent,
      onComponentCreate: (component: ParagraphComponent) => {
        component.text = paragraphOptions.text;
        component.style = paragraphOptions.style;
      }
    };
    return this.createDynamicOptionsModal(dialogOptions, matDialogConfig);
  }

  createDynamicOptionsModal(
    dialogData: IDynamicContentOptionSelectDialogData<any>,
    matDialogConfig: MatDialogConfig = null
  ) {
    const mdc = !!matDialogConfig ? deepCopy(this.defaultDialogConfig, matDialogConfig) : this.defaultDialogConfig;
    const dialogDataOptions: IDynamicContentOptionSelectDialogData<any>
    = deepCopy(this.dialogDataDefault, dialogData);
    return this.matDialog.open(DynamicContentOptionSelectModalComponent,
      {
        ...mdc,
        data: dialogDataOptions
      });
  }

  createDefaultPrimaryButton(text: string): IButtonOptions {
    return this.createPrimaryButton(text, (matDialogRef) => {
      matDialogRef.close(true);
    });
  }

  createPrimaryButton(text: string, onClickEvent: (mr: MatDialogRef<any>) => void): IButtonOptions {
    return this.createButton(text, onClickEvent, ModalConstants.primaryButtonTheme);
  }

  createDefaultCancelButton(text: string): IButtonOptions {
    return this.createCancelButton(text, (matDialogRef) => {
      matDialogRef.close(false);
    });
  }

  createCancelButton(text: string, onClickEvent: (mr: MatDialogRef<any>) => void): IButtonOptions {
    return this.createButton(text, onClickEvent, ModalConstants.cancelButtonTheme);
  }

  createCustomButton(text: string,
    ngClass: string | string[] | Set<string> | { [klass: string]: any; }): IButtonOptions {
    return this.createButton(text, (matDialogRef) => { matDialogRef.close(true); }, ngClass);
  }

  createButton(text: string,
    onClickEvent: (mr: MatDialogRef<any>) => void,
    ngClass: string | string[] | Set<string> | { [klass: string]: any; }): IButtonOptions {
    return {
      text: text,
      onClick: onClickEvent,
      ngClass: ngClass
    };
  }
}

@Component({
  template: `<p style='{{style}}'>{{text}}</p>`
})
export class ParagraphComponent {
  public text: string;
  public style: string;
}

export interface ITitleOptions {
  text: string;
  ngStyle?: { [klass: string]: any; };
}

export interface IParagraphOptions {
  text: string;
  style?: string;
}
