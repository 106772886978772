import { IContactAddress, ISubscriber } from 'src/app/manage-my-group/member-profile/_common/models';
import { IAccountSummary } from 'src/app/_models';
import { IEmployee } from '.';
import { ISavedEmployeeApplication } from 'src/app/manage-my-group/saved-applications/_common/models/saved-applications.interface';
import { BenefitBooks } from 'src/app/benefit-documents/benefit-books/_common/models/benefit-books.model';
import { DateService } from 'src/app/_common/services/date.service';

export class Employee {
    public static getAddress(employee: ISubscriber, addressType: AddressType): IContactAddress {
        return (employee?.contactInformation?.contactAddresses || []).find(address => address.addressType.toLowerCase() === addressType);
    }

    public static get nameStringifier(): (obj: IEmployee) => string {
        return emp => (emp.lastName + ' ' + emp.firstName + ' ')?.toLowerCase() || '';
    }

    public static get completeName(): (obj: IEmployee) => string {
      return emp => (emp.firstName + ' ' + emp.lastName + ' ')?.toLowerCase() || '';
    }

    public static get eligibilityDateMatcher(): (item: IEmployee, filterValue: Date) => boolean {
        return (emp, eligibilityDate) => {
            return (emp.effectiveDate !== null && new Date(emp.effectiveDate) <= eligibilityDate)
                && (emp.cancelDate === null || new Date(emp.cancelDate) > eligibilityDate);
        };
    }

    public static get statusMatcher(): (item: IEmployee, filterValue: string[]) => boolean {
        return (emp, employeeStatus) => employeeStatus.some(status => status === emp.employeeStatus);
    }

    public static get subAccountMatcher(): (item: IEmployee, filterValue: IAccountSummary[]) => boolean {
        return (item: IEmployee, filterValue: IAccountSummary[]) => {
            return !filterValue?.length || filterValue.some(subAccount => subAccount.accountId === item.subAccountNumber);
        };
    }

    public static get savedAppDateMatcher(): (item: ISavedEmployeeApplication, filterValue: Date) => boolean {
      return (emp, savedDateTime) => {
        if (!!savedDateTime) {
            return (emp.savedDateTime !== null &&
                DateService.getDateFormat(emp.savedDateTime, 'MM/dd/yyyy') === DateService.getDateFormat(savedDateTime, 'MM/dd/yyyy'));
        } else {
            return (emp.savedDateTime !== null &&
                new Date(emp.savedDateTime) <= savedDateTime);
        }
      };
    }

    public static get benefitBooksEffectiveDateMatcher(): (item: BenefitBooks, filterValue: Date) => boolean {
      return (benefit, startDateTime) => {
          return (benefit !== null &&
            benefit.benefitDocuments.some(docs =>
              DateService.getDateFormat(docs?.effectiveDate, 'MM/dd/yyyy') === DateService.getDateFormat(startDateTime, 'MM/dd/yyyy')));
      };
    }
}

export enum AddressType {
    Primary = 'primary',
    Mailing = 'mailing'
}
