import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashBoardComponent } from './dashboard/dashboard.component';
import { WelcomeComponent } from './welcome/welcome.component';
import { DashboardCardComponent } from './dashboard-card/dashboard-card.component';
import { ViewStatementCardComponent } from './view-statement-card/view-statement-card.component';
import { PayBillCardComponent } from './pay-bill-card/pay-bill-card.component';
import { OrderIdCardComponent } from './order-id-card/order-id-card.component';
import { BaseCardComponent } from './base-card/base-card.component';
import { AccessDeniedComponent } from './access-denied/access-denied.component';
import { AddNewEmployeeCardComponent } from './add-new-employee-card/add-new-employee-card.component';
import { ActivityReportsCardComponent } from './activity-reports-card/activity-reports-card.component';
import { SitecoreModule } from '../_common/shared/components/sitecore/sitecore.module';
import { WelcomeModalComponent } from './welcome/welcome-modal/welcome-modal.component';
import { AngularMaterialModule } from '../_common/modules/angular-material.module';
import { MatIconModule } from '@angular/material/icon';


@NgModule({
  declarations: [
    DashBoardComponent,
    WelcomeComponent,
    DashboardCardComponent,
    BaseCardComponent,
    AccessDeniedComponent,
    ViewStatementCardComponent,
    PayBillCardComponent,
    AddNewEmployeeCardComponent,
    ActivityReportsCardComponent,
    OrderIdCardComponent,
    WelcomeModalComponent
  ],
  imports: [
    CommonModule,
    SitecoreModule,
    AngularMaterialModule, MatIconModule,
  ]
})
export class LandingPageModule { }
