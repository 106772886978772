import { Component } from '@angular/core';
import { CardWithButtonModel } from './card-with-button.model';
import { SitecoreComponentModel } from 'src/app/_models/sitecore/sitecore-component-model';

@Component({
  selector: 'app-card-with-button',
  templateUrl: './card-with-button.component.html',
  styleUrls: ['./card-with-button.component.scss']
})
export class CardWithButtonComponent extends SitecoreComponentModel {

  public model: CardWithButtonModel;

  public setModel(newModel: any) {
    this.model = newModel;
  }

  onClickSameTabRedirect() {
   window.open(this.model?.ExternalURLSameTabRedirect, '_self');
  }
  onClickNewTabRedirect() {
    window.open(this.model?.ExternalURLNewTabRedirect, '_blank');
   }
}
