import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { AuthService } from './_common/auth/auth.service';
import { SpinnerService } from './_common/services/spinner.service';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router, RouterEvent } from '@angular/router';
import { ToasterService } from './_common/services/toaster-service/toaster.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  title = 'app';
  constructor(
    public spinnerService: SpinnerService,
    public toasterService: ToasterService,
    private router: Router,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    public authService: AuthService) {
    this.matIconRegistry.addSvgIcon(
      'bcbsaz-calendar',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/calendar.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'edit-icon',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/edit-icon.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'green-circle-check',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-circle-check.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'gray-close-x',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/gray-close.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'orange-triangle-warning',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-warning.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'red-x-mark',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/check-not-success.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'green-check-mark',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/check-success.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'eye',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/eye.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'eye-x',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/eye-x.svg')
    );

    this.router.events.subscribe((routerEvent: RouterEvent) => {

      // On NavigationStart, set showLoadingIndicator to ture
      if (routerEvent instanceof NavigationStart) {
        this.spinnerService.showProgressSpinner();
      }

      // On NavigationEnd or NavigationError or NavigationCancel
      // set showLoadingIndicator to false
      if (routerEvent instanceof NavigationEnd ||
        routerEvent instanceof NavigationError ||
        routerEvent instanceof NavigationCancel) {
        this.spinnerService.hideProgressSpinner();
        this.toasterService.hideToasterMessage();
      }

    });
  }

  ngOnInit() {
  }
}

