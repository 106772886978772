import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { TypeGuards } from '../type-safe-reactive-forms/type-guards';

export function switchMapBlobToDataURL(observable: Observable<Blob>): Observable<string> {
  return observable.pipe(
    switchMap((blob: Blob) => new Observable<string>(observer => {
      const reader = new FileReader();
      reader.onload = _ => {
        observer.next(reader.result.toString());
        observer.complete();
      };
      reader.readAsDataURL(blob);
    }))
  );
}

/**
 * Wrap a function in a try-catch block. When that function throws an exception, prepend its message and rethrow it to retain call stack.
 * @param message the message to prepend all exception messages with
 * @param func the function to wrap in a try-catch block
 */
 export function prependExceptionsWithMessage<T extends (...params: any) => any>(message: string, func: T): T {
  return <T>((params) => {
      try {
          return func(...params);
      } catch (e) {
          e.message = message + e;
          throw e;
      }
  });
}

/**
 * Merge two objects which may have sub objects that must also have their fields combined.
 * @param target
 * @param source
 * @returns
 */
export default function deepCopy(
  target: any,
  source: any
) {
  const output = Object.assign({}, target);
  if (TypeGuards.isObject(target) && TypeGuards.isObject(source)) {
    Object.keys(source).forEach(key => {
      if (TypeGuards.isObject(source[key])) {
        if (!(key in target)) {
          Object.assign(output, { [key]: source[key] });
        } else {
          output[key] = deepCopy(target[key], source[key]);
        }
      } else {
        Object.assign(output, { [key]: source[key] });
      }
    });
  }
  return output;
}

export function pluralize(noun: string, count: number, pluralForm: string = null) {
  if (!pluralForm) {
    pluralForm = noun + 's';
  }

  return count > 1 ? pluralForm : noun;
}

export function mailto(config: {
  message: {
    address: string,
    subject: string,
    body: string
  },
  tag: {
    innerHtml: string
  }
}): string {
  return `<a href="mailto:${config.message.address}?subject=${encodeURI(config.message.subject)}&body=${encodeURI(config.message.body)}">${config.tag.innerHtml}</a>`;
}

export function scrollToElement(element: Element): void {
  if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
  }
}
