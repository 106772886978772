import { formatDate } from '@angular/common';

export class DateService {
    public static getDate(value: string): Date {
        const date = new Date(value);
        const dateUTC = value ? new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate()) : null;

        return dateUTC;
    }

    public static getDateFormat(date: Date | string, format: string): string {
        const dateFormated = date ? formatDate(date, format, 'en-US') : '';
        return dateFormated;
    }
}
