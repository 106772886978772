import { Component, OnInit, OnDestroy } from '@angular/core';
import { NavigationContentService } from '../../_services/navigation-content-service/navigationContent.service';
import { INavigationHeaderItem } from '../../_models';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from 'src/app/_common/auth/auth.service';
import { EmployerAccountService } from '../../_services/employer-account-service/employer-account.service';
import { UserInfoService } from 'src/app/_services/user-info-service/userInfo.service';
import { AppConstants } from 'src/app/_common/shared/app.constants';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  public myAccountItems: Array<INavigationHeaderItem> = [];
  public accountName = '';
  public unsubscribe$ = new Subject<void>();
  private _subscription: Subscription;
  public showOUM = false;

  constructor(private navigationContentService: NavigationContentService,
    public authService: AuthService, public employerAccountService: EmployerAccountService, public userInfoService: UserInfoService) { }

  ngOnInit() {
    this.getMyAccountDropdownItemsFiltered();
    this.employerAccountService.parentAccountName$.pipe(takeUntil(this.unsubscribe$)).subscribe(
      (name: string) => {
        this.accountName = name;
      }
    );
  }

  private getMyAccountDropdownItemsFiltered(): void {
    const azbeaconUser = this.getLocalStorageInfo(AppConstants.AZBEACON_COOKIE_KEY);
    const userGroupRoles  = azbeaconUser !== 'true' ? this.userInfoService.getUserRole() : ['Employer_AZBeacon'];
    const userRole = this.userInfoService.getUserRole();
    this._subscription = this.navigationContentService.myAccountDropdownitems$.subscribe(
      data => {
        data.forEach((item) => {
          const permissionListFiltered = item.permissionFilter.split(/;/).filter((role: string) => {
            if (item.componentName === 'manageOfficeUserAccess') {
              return (this.userInfoService.oumAccessRoleList.includes(userRole[0]) && userGroupRoles.includes(role));
            }
            return userGroupRoles.includes(role);
          });
          if (permissionListFiltered.length > 0) {
            this.myAccountItems.push(item);
          }
          });
      });
  }

  getLocalStorageInfo(infoKey: string): any {
    const storage = localStorage.getItem(infoKey);
    if (storage) {
      return JSON.parse(storage);
    }
  }

  logOut() {
    this.authService.logout();
  }

  ngOnDestroy(): void {
    if (this._subscription) {
      this._subscription.unsubscribe();
    }
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
