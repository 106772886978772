import { Component, ComponentFactory, ComponentFactoryResolver, Input, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { BaseCardComponent } from '../base-card/base-card.component';
import { ViewStatementCardComponent } from '../view-statement-card/view-statement-card.component';
import { PayBillCardComponent } from '../pay-bill-card/pay-bill-card.component';
import { AddNewEmployeeCardComponent } from '../add-new-employee-card/add-new-employee-card.component';
import { IDashboardCard } from '../common/models/dashboard-card.interface';
import { ActivityReportsCardComponent } from '../activity-reports-card/activity-reports-card.component';
import { OrderIdCardComponent } from '../order-id-card/order-id-card.component';

@Component({
  selector: 'app-dashboard-card',
  templateUrl: './dashboard-card.component.html',
  styleUrls: ['./dashboard-card.component.scss']
})
export class DashboardCardComponent implements OnInit {

  private readonly cardTemplates: any = {
    viewStatement: ViewStatementCardComponent,
    payBill: PayBillCardComponent,
    addNewEmployee: AddNewEmployeeCardComponent,
    activityReports: ActivityReportsCardComponent,
    orderIdCard: OrderIdCardComponent
  };

  @ViewChild('container', { read: ViewContainerRef }) private container: ViewContainerRef;
  @Input() cardSize: string;
  @Input() cardData: IDashboardCard;
  public componentRef: any;

  constructor(private componentFactoryResolver: ComponentFactoryResolver) {
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.loadComponent();
    }, 100);
  }

  private getComponentForCardType(cardType: string): any {
    return this.cardTemplates[cardType];
  }

  loadComponent() {
    const componentType: any = this.getComponentForCardType(this.cardData.componentName);

    const componentFactory: ComponentFactory<BaseCardComponent> = this.componentFactoryResolver.resolveComponentFactory(componentType);

    const viewContainerRef: ViewContainerRef = this.container;
    viewContainerRef.clear();
    this.componentRef = viewContainerRef.createComponent<BaseCardComponent>(componentFactory);
    this.componentRef.instance.cardSize = this.cardSize;
    this.componentRef.instance.cardData = this.cardData;
  }

  get isLargeCard(): boolean {
    if (this.cardSize === 'L') {
      return true;
    }
    return false;
  }

  get cardTitle(): string {
      return this.cardData.cardTitle;
  }
}
