import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SingleDropdownSelectComponent } from '../custom-controls/single-dropdown-select/single-dropdown-select.component';
import { TreeModalDropdownComponent } from '../custom-controls/tree-modal-dropdown/tree-modal-dropdown.component';
import { NgxBootStrapModule } from './ngxbootstrap.module';

@NgModule({
  declarations: [
    SingleDropdownSelectComponent,
    TreeModalDropdownComponent
  ],
  imports: [
    CommonModule,
    NgxBootStrapModule
  ],
  exports: [
    SingleDropdownSelectComponent,
    TreeModalDropdownComponent
  ]
})
export class CustomControlsModule { }
