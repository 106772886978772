import { IBenefitSelection, IContactInfo, IContactInformation, IDependentInfo, ITerminateCoverage } from './index';

export interface ISubscriber {
    hccSubscriberId: string;
    memberId: string;
    employeeId: string;
    firstName: string;
    lastName: string;
    dateOfBirth: string;
    employeeAppStatus: string;
    location: string;
    ssn: string;
    maritalStatus: string;
    dateOfMarriage: string;
    gender: string;
    accountNumber: string;
    subAccountNumber: string;
    contractCancelDate: string;
    receivedDate: string;
    medicalId: string;
    dentalId: string;
    terminationReason: string;
    contactInformation?: IContactInfo;
    benefitSelection: IBenefitSelection;
    dependents: IDependentInfo[];
    terminateCoverage?: ITerminateCoverage;
    effectiveDateOfPolicy: string;
    loggedInUserId?: string;
    effectiveDateOfChange?: Date;
}
export interface ISubscriberData {
    hccSubscriberId: string;
    memberId: string;
    employeeId: string;
    firstName: string;
    lastName: string;
    dateOfBirth: string;
    employeeAppStatus: string;
    location: string;
    ssn: string;
    maritalStatus: string;
    dateOfMarriage: string;
    gender: string;
    accountNumber: string;
    subAccountNumber: string;
    contractCancelDate: string;
    receivedDate: string;
    medicalId: string;
    dentalId: string;
    terminationReason: string;
    contactInformation: IContactInformation;
    benefitSelection: IBenefitSelection;
    dependents: IDependentInfo[];
    terminateCoverage?: ITerminateCoverage;
    effectiveDateOfPolicy: string;
}

export class Subscriber {
    public parseToSubscriber(subscriberData): ISubscriber {
        const subscriber: ISubscriber = {...subscriberData};
        subscriber.contactInformation.contactAddresses = [{
          addressType: 'Primary',
          address1: subscriberData.contactInformation.address1,
          address2: subscriberData.contactInformation.address2,
          city: subscriberData.contactInformation.city,
          state: subscriberData.contactInformation.state,
          zip: subscriberData.contactInformation.zip
        }];
        return subscriber;
    }
}
