/***************************************************************************************************
 * Load `$localize` onto the global scope - used if i18n tags appear in Angular templates.
 */
import '@angular/localize/init';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { OKTA_CONFIG } from '@okta/okta-angular';
import { OktaConfig } from './app/_models/okta-config';

export function getBaseUrl() {
  return document.getElementsByTagName('base')[0].href;
}

if (environment.production) {
  enableProdMode();
}

fetch('./assets/okta-config.json').then(async res => {
  const oktaConfig = await res.json() as OktaConfig;
  oktaConfig.redirectUri = window.location.origin + '/implicit/callback';

  platformBrowserDynamic([
    { provide: OKTA_CONFIG, useValue: oktaConfig },
    { provide: 'BASE_URL', useFactory: getBaseUrl, deps: [] }
  ]).bootstrapModule(AppModule)
    .catch(err => console.error(err));
});
