import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';

import { HeaderComponent } from './header/header.component';
import { NavigationComponent } from './navigation/navigation.component';
import { FooterComponent } from './footer/footer.component';
import { NavigationContentService } from '../_services/navigation-content-service/navigationContent.service';
import { DebuggingComponent } from './debugging/debugging.component';
import { AngularMaterialModule } from '../_common/modules/angular-material.module';
import { MatIconModule } from '@angular/material/icon';
import { SharedModule } from '../_common/modules/shared.module';
import { TokenExpireNotifierService } from '../_common/services/token-expire-notifier/token-expire-notifier.service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';


@NgModule({
    declarations: [
    HeaderComponent,
    NavigationComponent,
    FooterComponent,
    DebuggingComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule,
    FormsModule,
    NgbModule,
    AngularMaterialModule, MatIconModule,
  ],
  exports: [
    HeaderComponent,
    NavigationComponent,
    FooterComponent
  ],
  providers: [
    NavigationContentService
  ]
})
export class LayoutModule {
  // Potential fix for token modal bug
  // constructor(private startTokenServiceOnLogin: TokenExpireNotifierService) {}
}
