
export interface RosterFilters {
    searchFilterValue: string;
    accountId: string[];
    subAccountNumbers: string[];
    status: string[];
    eligibilityDate: Date;
}

export enum RosterStatuses {
    ALL = 'ALL',
    CURRENT = 'Current',
    HEALTHWELLNESSONLY = 'Health & Wellness Only',
    TERMINATED = 'Terminated',
    PROCESSING = 'Processing',
    PENDING = 'Pending',
    REPAIR = 'Repair',
    NOTSPECIFIED = 'Not Specified',
    REVIEW = 'Review',
    ACTIVE = 'Active'
}
