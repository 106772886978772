import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, EMPTY, Observable } from 'rxjs';
import { IPlanInfo } from '../../models/plan-info';
import { ICdhPlanInfo } from '../../models/cdhp-plan-info.model';
import { catchError, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PlansApiService {
    public cdhpPlanInfo$: BehaviorSubject<Array<ICdhPlanInfo>> = new BehaviorSubject<Array<ICdhPlanInfo>>([]);
    constructor(private http: HttpClient) {}

    public getPlans(accountNumber: string = null, effectiveDate: Date = null): Observable<Array<IPlanInfo>> {
      if (accountNumber && effectiveDate) {
        const url = `api/Plans/${accountNumber}/${effectiveDate.toISOString().slice(0, 10)}/GetPlans`;
        return this.http.get<Array<IPlanInfo>>(url);
      }
      return EMPTY;
    }

    public getCdhPlans(
      accountNumber: string = null,
      effectiveDate: string = null,
      hrpId: string = null
    ): Observable<ICdhPlanInfo[]> {
      this.cdhpPlanInfo$.next([]);
      if (accountNumber && effectiveDate && hrpId) {
        const url = `/api/EmployerPlanCdhp/${accountNumber}/${effectiveDate}/${hrpId}/GetCdhpPlans`;
        return this.http.get<ICdhPlanInfo[]>(url).pipe(
          tap((cdhpPlan: ICdhPlanInfo[]) => {
            this.cdhpPlanInfo$.next(cdhpPlan);
          }),
          catchError((err) => {
            this.cdhpPlanInfo$.next([]);
            return EMPTY;
          })
        );
      }
      return EMPTY;
    }
}
