import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { forkJoin, Observable } from 'rxjs';
import { finalize, map } from 'rxjs/operators';
import { IEmployeeForm } from 'src/app/manage-my-group/add-employee/standard-application/_common/models';
import { EmployeeFormService } from 'src/app/manage-my-group/add-employee/standard-application/_common/services/employee-form.service';
import { ISavedEmployeeApplication } from 'src/app/manage-my-group/saved-applications/_common/models/saved-applications.interface';
import { SavedApplicationsApiService } from 'src/app/manage-my-group/saved-applications/_common/services/savedApplicationsService/saved-applications-api.service';
import { ManageMyGroupDropdownService } from 'src/app/manage-my-group/_common/services/manage-my-group-dropdown.service';
import { SpinnerService } from 'src/app/_common/services/spinner.service';
import { AppConstants } from '../shared/app.constants';

@Injectable({
  providedIn: 'root'
})
export class SavedEmployeeDataResolver {
  private employeeForm: IEmployeeForm = null;
  constructor(
    private savedApplicationsApiService: SavedApplicationsApiService,
    private spinnerService: SpinnerService,
    private employeeFormService: EmployeeFormService,
    public  dropdownService: ManageMyGroupDropdownService,
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    this.spinnerService.showProgressSpinner();
    try {
      const employeeData = JSON.parse(localStorage.getItem(AppConstants.SAVED_APP_CURRENT_EMPLOYEE));
      if (!!employeeData) {
        return forkJoin([
          this.savedApplicationsApiService.getSavedEmployeeApplicationById(employeeData?.id).pipe(
            map((employee: ISavedEmployeeApplication[]) => {
              if (!!employee) {
                const applicationJson = employee.shift()?.applicationJson;
                this.employeeForm = this.parseToEmployeeForm(applicationJson);
              }})
          ),
          this.dropdownService.getHealthPlans(
            employeeData?.accountNumber,
            this.employeeForm?.employeeInfo?.effectiveDateOfCoverage ?? new Date())
        ]).pipe(
            finalize(() => {
              this.employeeFormService.resumeSavedEmployee(this.employeeForm);
              this.spinnerService.hideProgressSpinner();
            })
        );
      }
    } catch {
        this.spinnerService.hideProgressSpinner();
    }
  }

  public parseToEmployeeForm(applicationJson: string): IEmployeeForm {
    if (applicationJson) {
      return JSON.parse(applicationJson) as IEmployeeForm;
    }
  }
}
