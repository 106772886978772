import { Injectable } from '@angular/core';
import { IToastMessage } from 'src/app/_models/toast-message.interface';
import { SitecoreContentService } from 'src/app/_services/sitecore-content-service/sitecore-content.service';
import { environment } from 'src/environments/environment';
import { ToasterService } from '../toaster-service/toaster.service';

@Injectable({
  providedIn: 'root'
})
export class ToasterAlertService {
  private readonly toasterTypes = {
    default: { },
    success: {
      icon: 'green-circle-check',
      bodyClass: { 'card-body-success': true }
    },
    error: {
      icon: 'orange-triangle-warning',
      bodyClass: { 'card-body-error': true }
    }
  };

  constructor(
    private sitecoreContentService: SitecoreContentService,
    toasterService: ToasterService
  ) {
    this.sitecoreContentService.getChildren<ISitecoreAlertItem>(environment.sitecoreToasterAlertsName).subscribe(alerts => {
      const toasterMessages = this.mapSitecoreItemsToToasterMessages(alerts);
      toasterMessages.forEach(message => toasterService.pushToastMessage(message));
    });
  }

  private mapSitecoreItemsToToasterMessages(alerts: ISitecoreAlertItem[]) {
    const result = alerts.map(alert => {
      const toasterType = this.toasterTypes[alert.Type || 'default'];
      const toasterMessage: IToastMessage = {
        ...toasterType,
        title: alert.AlertTitle,
        message: alert.Message,
        shouldScrollToToaster: false,
        showCloseButton: true
      };
      return toasterMessage;
    });

    return result;
  }
}

export interface ISitecoreAlertItem {
  AlertTitle?: string;
  Message?: string;
  Type?: string;
}
