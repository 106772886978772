import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UserWelcomeService {
  private readonly storageKeyName = 'UserWelcomeCount';
  public readonly maxCount = 1;
  private state: UserWelcomeState;

  constructor() {
    this.state = this.getStateFromStorage(this.storageKeyName);
  }

  public get loginCount() { return this.state.count; }

  public saveCount() {
    const json = JSON.stringify(this.state);
    localStorage.setItem(this.storageKeyName, json);
  }

  public incrementWelcomeCount() {
    this.state.count = Math.min(this.state.count + 1, this.maxCount);
    this.saveCount();
  }

  private getStateFromStorage(key: string): UserWelcomeState {
    const json = localStorage.getItem(key);
    const result = !!json
      ? JSON.parse(json) as UserWelcomeState
      : { count: 0 };

    return result;
  }
}

export interface UserWelcomeState {
  count: number;
}
