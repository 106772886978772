import { Component } from '@angular/core';
import { HeaderWithSubTextModel } from 'src/app/_models/sitecore/header-with-sub-text-model';
import { SitecoreComponentModel } from 'src/app/_models/sitecore/sitecore-component-model';

@Component({
  selector: 'app-header-with-sub-text',
  templateUrl: './header-with-sub-text.component.html',
  styleUrls: ['./header-with-sub-text.component.scss']
})
export class HeaderWithSubTextComponent extends SitecoreComponentModel {

  public model: HeaderWithSubTextModel;

  public setModel(newModel: any) {
    this.model = newModel;
  }
}
