import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  HostListener,
  ElementRef,
} from '@angular/core';

export interface IDropdownMultiselectItem {
  value: string;
  text: string;
  selected: boolean;
}

@Component({
  selector: 'app-multiselect-dropdown',
  templateUrl: './multiselect-dropdown.component.html',
  styleUrls: ['./multiselect-dropdown.component.scss'],
})
export class MultiselectDropdownComponent implements OnInit {
  @Input('dropdownItems')
  public set setDropdownItems(dropdown: Array<IDropdownMultiselectItem>) {
    this.dropdownItems = dropdown;
    this.displayedDropdownItems = this.dropdownItems;
    this.resetOptions();
    this.setLabel();
  }

  private readonly DEFAULT_SINGLE_SELECT_LABEL = 'Select One';
  private readonly DEFAULT_MULTI_SELECT_LABEL = 'Select';

  @Input()
  public id = 'DEFAULT';

  @Input()
  public disabled = false;

  @Input()
  public multiSelect = false;

  @Input()
  public enableSearch = false;

  @Output()
  public selectedItemChange: EventEmitter<IDropdownMultiselectItem> = new EventEmitter();

  @Output()
  public selectedItemsChange: EventEmitter<
    Array<IDropdownMultiselectItem>
  > = new EventEmitter();

  dropdownItems: Array<IDropdownMultiselectItem> = [];

  displayedDropdownItems: Array<IDropdownMultiselectItem> = [];

  selectedItem: IDropdownMultiselectItem;
  selectedOptions: Array<IDropdownMultiselectItem>;

  public label: string;

  public showDropdown = false;

  filterValue = '';

  constructor(private eRef: ElementRef) {}

  ngOnInit() {
    this.label = this.multiSelect
      ? this.DEFAULT_MULTI_SELECT_LABEL
      : this.DEFAULT_SINGLE_SELECT_LABEL;
  }

  public toggleDropdown() {
    this.showDropdown = !this.showDropdown;
  }

  public onChange(item: IDropdownMultiselectItem): void {
    const newState: boolean = !item.selected;
    if (this.multiSelect) {
      item.selected = newState;
      this.multiSelectOption(item);
    } else {
      this.resetOptions();
      item.selected = newState;
      this.selectOption(item);
    }
  }

  @HostListener('document:click', ['$event'])
  public blur(event) {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.showDropdown = false;
    }
  }

  private selectOption(selection: IDropdownMultiselectItem) {
    const updatedItemIndex = this.dropdownItems.findIndex(
      (item) => item.value === selection.value
    );
    // update its value.
    this.dropdownItems[updatedItemIndex].selected = selection.selected;
    this.selectedItem = selection;

    if (selection.selected) {
      this.selectedItemChange.emit(this.dropdownItems[updatedItemIndex]);
    } else {
      this.selectedItem = null;
      this.selectedItemChange.emit();
    }

    this.setLabel();
  }

  private multiSelectOption(selectedItem: IDropdownMultiselectItem) {
    // find he option in the original list
    const updatedItemIndex = this.dropdownItems.findIndex(
      (item) => item.value === selectedItem.value
    );

    // update its value.
    this.dropdownItems[updatedItemIndex].selected = selectedItem.selected;

    // selected all option togged as true.
    this.selectedOptions = this.dropdownItems.filter(
      (item) => item.selected === true
    );

    if (this.selectedOptions.length === 0) {
      this.selectedOptions = [];
    }

    // emit those options
    this.selectedItemsChange.emit(this.selectedOptions);
    this.setLabel();
  }

  private setLabel() {
    if (this.multiSelect) {
      this.label =
        this.selectedOptions && this.selectedOptions.length > 0
          ? this.selectedOptions.map((item) => item.text).join(',')
          : this.DEFAULT_MULTI_SELECT_LABEL;
    } else {
      this.label = this.selectedItem
        ? this.selectedItem.text
        : this.DEFAULT_SINGLE_SELECT_LABEL;
    }
  }

  public resetOptions() {
    this.selectedOptions = [];
    this.selectedItem = null;
    this.dropdownItems.forEach((item) => {
      item.selected = false;
    });
    this.displayedDropdownItems.forEach((item) => {
      item.selected = false;
    });
  }

  public filterOptions() {
    if (this.filterValue.length > 0) {
      this.displayedDropdownItems = this.dropdownItems.filter(
        (dropdownItem: IDropdownMultiselectItem) =>
          dropdownItem.text
            .toLowerCase()
            .includes(this.filterValue.toLowerCase())
      );
    } else {
      this.displayedDropdownItems = this.dropdownItems;
    }
  }

  public clearOptions() {
    this.resetOptions();
    this.setLabel();
  }
}
