import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { SortDirection } from '../../directives/sortable.directive';
import { debounceTime, map, switchMap } from 'rxjs/operators';
import { IEmployee, RosterFilters } from '../../models';
import { EmployeeRosterFilterService } from '../employee-roster-filter-service/employee-roster-filter.service';
import { EmployerRosterApiService } from './employee-roster-api-service';

@Injectable({
  providedIn: 'root'
})
export class EmployeeRosterService implements OnDestroy {
  public mockData = '../../../../../../assets/mockData/HUNDRED_Employees_V2.json';
  private _searchKeys: (keyof IEmployee)[] = ['firstName', 'lastName', 'medicalId',
    'dentalId', 'hccSubscriberId'];
  private employeeRosters: Array<IEmployee> = [];
  private employeeRosterContent$ = new BehaviorSubject<Array<IEmployee>>(this.employeeRosters);
  private _search$ = new Subject<void>();
  private _total$ = new BehaviorSubject<number>(0);
  private _state: State = {
    page: 1,
    pageSize: 10,
    searchTerm: '',
    sortColumn: '',
    sortDirection: ''
  };
  private subscriptions: Subscription[] = [];
  public noEmployeeFound = false;
  public noEmployeeFound$ = new BehaviorSubject<boolean>(this.noEmployeeFound);
  public isTenOrLessRecord = false;
  public employeeListSize$ = new BehaviorSubject<boolean>(this.isTenOrLessRecord);
  public isPagination = false;

  constructor(private rosterFilterService: EmployeeRosterFilterService,
    private employeeRosterApiService: EmployerRosterApiService) {

    this._search$.pipe(
      debounceTime(800),
      switchMap(() => this._search())
    ).subscribe(result => {
      this.employeeRosterContent$.next(result.employeeRosters);
      this._total$.next(result.total);
    });
    this.getEmployeeRosters();
  }

  get employeeRosters$() { return this.employeeRosterContent$.asObservable(); }
  get total$() { return this._total$.asObservable(); }
  get page() { return this._state.page; }
  get pageSize() { return this._state.pageSize; }
  get searchTerm() { return this._state.searchTerm; }
  get searchKeys() { return this._searchKeys; }

  set page(page: number) { this._set({ page }); }
  set pageSize(pageSize: number) { this._set({ pageSize }); }
  set searchTerm(searchTerm: string) { this._set({ searchTerm }); }
  set sortColumn(sortColumn: string) { this._set({ sortColumn }); }
  set sortDirection(sortDirection: SortDirection) { this._set({ sortDirection }); }
  set searchKeys(keys: (keyof IEmployee)[]) { this._searchKeys = keys; }

  private _set(patch: Partial<State>) {
    Object.assign(this._state, patch);
    this._search$.next();
  }

  private _search(): Observable<SearchResult> {
    return new Observable<SearchResult>(subscriber => {
      const { sortColumn, sortDirection, pageSize, page } = this._state;

      // 1. filter based on search field
      let employeeRosters = this.employeeRosters.filter(emp =>
        matches(emp, this.rosterFilterService.getSelectedRosterFilter, this.searchKeys)
      );

      // 2. filter based on filter options if applicable
      employeeRosters = this.rosterFilterService.filterByOptionsSelected(employeeRosters);
      const total = employeeRosters.length;

      // 3. sort
      employeeRosters = sort(employeeRosters, sortColumn, sortDirection);

      // 4. paginate
      employeeRosters = employeeRosters.slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize);

      subscriber.next({ employeeRosters, total });
    });
  }

  public getEmployeeRosters(): Observable<Array<IEmployee>> {
    this.subscriptions.push(this.employeeRosterApiService.rosterData$
      .pipe(
        map(employees => employees.map(employee => {
          return {
            ...employee,
            firstName: employee.firstName.toLowerCase(),
            lastName: employee.lastName.toLowerCase()
          };
        })
        ))
      .subscribe(
        (data: Array<IEmployee>) => {
          this.employeeRosters = data;
          this.isPagination = data.length > 10;
          this._search$.next();

          if (!this.employeeRosters?.length) {
            this.noEmployeeFound$.next(false);
          }
        })
    );
    return this.employeeRosterContent$;
  }

  public sort(): void {
    const employeeRosters = sort(this.employeeRosters, this._state.sortColumn, this._state.sortDirection);
    this.employeeRosterContent$.next(employeeRosters);
    this._total$.next(employeeRosters.length);
  }

  public updateFilterSearchValue(searchOptionValue: string): void {
    this.rosterFilterService.updateFilterSearchValue(searchOptionValue);
  }

  public updateAccountInfoFilterOption(data: string[], filterName: string): void {
    if (filterName && filterName.toLowerCase() === 'account') {
      this.rosterFilterService.updateSelectedAccountIds(data);
    } else {
      this.rosterFilterService.updateSelectedSubAccountId(data);
    }
  }

  public updateStatusOption(data: string[]): void {
    this.rosterFilterService.updateStatus(data);
  }

  public updateEligibilityDateFilterOption(eligibilityDate: Date): void {
    this.rosterFilterService.updateSelectedEligibilityDate(eligibilityDate);
  }

  public clearAllSelections(): void {
    this.rosterFilterService.clearAllSelections();
  }

  public filter(): void {
    this._search$.next();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => {
      s.unsubscribe();
    });
  }
}

function matches(emp: IEmployee, rosterFilters: RosterFilters, keys: (keyof IEmployee)[]) {
  return !rosterFilters.searchFilterValue
    || keys.some(key => emp[key]?.toString()?.toLowerCase()?.includes(rosterFilters.searchFilterValue.toLowerCase()));
}

function sort(employeeRosters: IEmployee[], column: string, direction: string): IEmployee[] {
  if (direction === '') {
    return employeeRosters;
  } else {
    return [...employeeRosters].sort((a, b) => {
      const res = compare(keyValue(a, column), keyValue(b, column));
      return direction === 'asc' ? res : -res;
    });
  }
}

function keyValue(obj: IEmployee, key: string) {
  const sections = key.split('+');
  const result = sections.map(section => obj[section]).join();
  return result;
}

function compare(v1, v2) {
  return v1 < v2 ? -1 : v1 > v2 ? 1 : 0;
}

export interface State {
  page: number;
  pageSize: number;
  searchTerm: string;
  sortColumn: string;
  sortDirection: SortDirection;
}

interface SearchResult {
  employeeRosters: IEmployee[];
  total: number;
}



















