import { MatDialogRef } from '@angular/material/dialog';
import { IComponentCreationOptions } from './component-creation-options.interface';
import { SpanTextElementComponent, ITextElementConfig } from './span-text-element.component';
import { ModalConstants } from './modal.constants';
import { IButtonOptions } from './modals/option-select-modal/models/button-options';


export function createTextComponentStructures() {
    const components: IComponentCreationOptions<SpanTextElementComponent>[] = [];
    const textBuilder = {
        addText: (text: string) => { components.push(createTextElementConfig({ text })); return textBuilder; },
        addBold: (text: string) => { components.push(createTextElementConfig({ text, style: 'font-weight: bold;' })); return textBuilder; },
        build: () => components
    };
    return textBuilder;
}

function createTextElementConfig(config: ITextElementConfig): IComponentCreationOptions<SpanTextElementComponent> {
    return {
        componentType: SpanTextElementComponent,
        onComponentCreate: (component: SpanTextElementComponent) => {
            component.config = config;
        }
    };
}

export function createButtonOptionStructures() {
    const result: IButtonOptions[] = [];
    const buttonBuilder = {
        addPrimaryButton: (text: string, onClick: (MatDialogRef: any) => void = null) => {
            result.push({
                text,
                ngClass: ModalConstants.primaryButtonTheme,
                onClick: onClick || ((md: MatDialogRef<any>) => md.close(true))
            });
            return buttonBuilder;
        },
        addCancelButton: (text: string = 'Cancel', onClick: (MatDialogRef: any) => void = null) => {
            result.push({
                text,
                ngClass: ModalConstants.cancelButtonTheme,
                onClick: onClick || ((md: MatDialogRef<any>) => md.close(false))
            });
            return buttonBuilder;
        },
        addCustomButton: (buttonOptions: IButtonOptions) => {
            result.push(buttonOptions);
            return buttonBuilder;
        },
        build: () => result
    };

    return buttonBuilder;
}
