import { Directive, HostListener, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { BaseValidation } from './base-validation';

@Directive({
    selector: '[appSSNDirective]',
})
export class SSNDirective extends BaseValidation {
    constructor() {
        super();
    }

    @Input() appInput: AbstractControl;

    @HostListener('keydown', ['$event'])
    public onKeyDown(event: any): void {
        super.onKeyDown(event);
    }

    @HostListener('keyup', ['$event'])
    public onPaste(event: any): void {
        return this.formatSSN(event);
    }

    @HostListener('focus', ['$event'])
    public onFocus(event: any): void {
        this.appInput.markAsUntouched({ onlySelf: true });
    }

    public enforceValidation(event: any): void {
        this.enforceFormat(event);
    }

    private enforceFormat = (event: any) => {
        if (!this.isNumericInput(event) && event.keyCode !== 189 && !this.isModifierKey(event)) {
            event.preventDefault();
            return;
        }
    }

    public formatSSN = (event: any) => {
        const input: any = event.target.value;
        if (input === undefined || input === null || input === '') {
            return;
        }
        const ssn = input.replace(/-|\D/g, '');
        const length = ssn.length;
        let output = ssn.substr(0, 3);
        output += (length > 3) ? '-' + ssn.substr(3, 2) : '';
        output += (length > 5) ? '-' + ssn.substr(5, 4) : '';
        super.setInputValue(event, output, this.appInput, 0);
    }
}
