import {Directive, EventEmitter, HostListener, Output, ViewContainerRef} from '@angular/core';

@Directive({
  selector: '[appPrint]',
})
export class PrintDirective {
  @Output() printEvent: EventEmitter<any> = new EventEmitter();

  constructor(public viewContainerRef: ViewContainerRef) {
  }

  @HostListener('window:keydown.control.p', ['$event'])
  showPinned(event: KeyboardEvent) {
    this.printEvent.emit(null);
    event.preventDefault();
  }
}

