import { Subject } from 'rxjs';
import { SearchConfig } from './search-config';
import { IPipe } from '../pipe.interface';


export class SearchPipe<T, K> implements IPipe<Array<T>> {
    private _searchText = '';
    private stringifiers: ((obj: T) => string)[];
    get searchText() { return this._searchText; }
    set searchText(searchText: string) { this._searchText = !!searchText ? searchText : ''; this.dirtyNotifier.next(true); }

    constructor(
        private readonly dirtyNotifier: Subject<boolean>,
        private readonly searchConfig: SearchConfig<T, K>
    ) {
        this.stringifiers = Object.keys(this.searchConfig).map(key => this.searchConfig[key as keyof K].stringifier);
    }

    public process(input: T[]): T[] {
        if (!this.searchText) {
            return input;
        }

        const lowerSearchText = this.searchText.toLowerCase();
        const result = input.filter(item => {
            return this.stringifiers.some(stringifier => {
                return stringifier(item).includes(lowerSearchText);
            });
        });
        return result;
    }

    public clear(): void { this.searchText = null; }
}
